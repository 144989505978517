import { COOKIE_CONSENT_NAME } from '../utils/cookieConsent';
import { countryCheck, languageSwitcher } from './common';

const account = {
	title: 'Ustawienia konta użytkownika',
	subtitle: 'Jeśli któreś z\u00a0tych danych uległy zmianie, możesz je zaktualizować bezpośrednio na tej stronie. '
		+ 'Jeśli zmienisz swoje nazwisko, będziemy potrzebować nowego ważnego dokumentu z\u00a0nowym nazwiskiem. Jeśli '
		+ 'chcesz zaktualizować swój e-mail (np. z\u00a0powodu zmiany nazwiska), napisz do nas na adres <0>info@fondee.pl</0>.',
	tradingDay: 'Najbliższy dzień handlowy',
	faqLink: 'Jak i\u00a0kiedy odbywa się inwestowanie',
	bankAccountsControl: {
		title: 'Rachunki bankowe',
		withdrawal: 'Rachunek dla wypłat',
		withdrawalTooltip: 'Na wybrany rachunek będziemy wysyłali środki zlecone do\u00a0wypłaty.',
		unconfirmedTooltip: 'Konto w oczekiwaniu na weryfikację. Aby sprawdzić, czy rachunek bankowy rzeczywiście należy do Ciebie, '
			+ 'musisz z\u00a0niego najpierw wysłać wpłatę, wystarczy 1\u00a0zł. Otwórz okno \u201eChcę wpłacić pieniądze\u201c '
			+ 'a\u00a0wyświetlone zostaną polecenia płatnicze.',
		confirmChange: {
			title: 'Czy na pewno chcesz zmienić rachunek wypłat?',
			text: 'Jeżeli wcześniej wprowadzona wypłata jest już przetwarzana, zmiana przejawi się dopiero podczas następnej wypłaty.',
			submit: 'Tak, zmienić',
			cancel: 'Nie, zostawić',
		},
		addAccount: {
			button: 'Dodaj kolejny rachunek',
			buttonTooltip: [
				'Możesz dodać kolejny rachunek bankowy, z\u00a0którego będziesz wysyłać wpłaty i\u00a0na który możesz '
				+ 'otrzymywać środki. Rachunek powinien być prowadzony na Twoje nazwisko. Jeżeli chcesz dodać rachunek '
				+ 'zagraniczny, należy go wprowadzić za pomocą IBAN.',
				'$t(account.bankAccountsControl.addAccount.text.1)',
			],
			title: 'Dodać kolejny rachunek',
			text: [
				'Wypełnij prefiks konta (o ile rachunek go ma), numer rachunku i\u00a0kod banku. Rachunek powinien być prowadzony na '
				+ 'Twoje nazwisko. Jeżeli chcesz dodać rachunek zagraniczny, należy go wprowadzić za pomocą IBAN.',
				'Rachunek nie jest automatycznie aktywny (np. aby zlecić wypłatę), wymaga weryfikacji poprzez weryfikacyjną '
				+ 'płatność. Możesz wysłać całą wpłatę lub tylko parę złotych. Aby wprowadzić płatność, otwórz okno '
				+ '\u201eChcę wpłacić pieniądze\u201c, gdzie zobaczysz polecenia dotyczące płatności.',
			],
			submit: 'Dodać rachunek',
			cancel: 'Anulować',
		},
	},
	cashControl: {
		title: 'Udział gotówki w portfelu',
		titleSet: 'Udział gotówki ustawiony na {{ amount }}.',
		titlePlanned: 'Udział gotówki jest obecnie ustawiony na {{ current }}. Przy najbliższym rebalansowaniu '
			+ 'portfel zostanie ustawiony tak, aby udział gotówki był {{ planned }}.',
		titleTooltip: 'Standardowo zostają minimalnie 2\u00a0% od\u00a0wartości Twojego portfela w\u00a0gotówce '
			+ '(w przypadku\u00a0mniejszych portfeli ten udział może być wyższy). Tutaj możesz wprowadzić polecenie, abyśmy podczas każdego '
			+ 'rebalansowania pozostawili większą część Twojego portfela w\u00a0gotówce (minimalnie\u00a02\u00a0%). Ten '
			+ 'udział będzie utrzymywany przy każdym rebalansowaniu, dopóki nie wydasz polecenia, by go zmienić. Gotówka trzymana jest '
			+ 'w\u00a0EUR i\u00a0tak samo jak Twój portfel jest narażona na ryzyko walutowe. Opłata miesięczna jest obliczana '
			+ 'od\u00a0wartości całkowitej portfela włącznie z\u00a0posiadaną gotówką.',
		button: 'Skoryguj udział gotówki',
		buttonTooltip: 'Udział gotówki zostanie skorygowany w następnym dniu\u00a0handlowym.',
		closedTooltip: 'W\u00a0tej chwili udział gotówki nie może zostać zmieniony, ponieważ właśnie odbywa się dopasowanie alokacji portfela.',
		modal: {
			title: 'Ustawić nowy udział',
			text: 'Standardowo pozostają minimalnie 2\u00a0% od\u00a0wartości Twojego portfela w\u00a0gotówce (w przypadku\u00a0mniejszych '
				+ 'portfeli stosunek ten może być większy). Tutaj możesz wprowadzić polecenie, abyśmy podczas każdego rebalansowania pozostawili '
				+ 'większą część Twojego portfela w\u00a0gotówce (minimalnie 2\u00a0%). Udział będzie utrzymywany przy każdym '
				+ 'rebalansowaniu, dopóki nie wydasz polecenia, by go zmienić. Gotówka trzymana jest w\u00a0EUR i\u00a0tak samo jak Twój portfel '
				+ 'jest narażona na ryzyko walutowe. Opłata miesięczna jest obliczana od\u00a0wartości całkowitej portfela włącznie z\u00a0posiadaną gotówką.',
			maxTooltip: 'W tym przypadku nie będziesz mieć żadnych ETF i\u00a0wszystko zostanie w gotówce.',
			submit: 'Ustawić nowy udział',
			cancel: 'Nie zmieniać',
		},
	},
	currencySelect: {
		title: 'Preferowany sposób wyświetlania waluty dla\u00a0całego konta',
		subtitle: 'Wyświetlanie w\u00a0Zestawieniu',
	},
	depositDefaultPortfolio: {
		title: 'Portfel podstawowy dla\u00a0wpłat',
		subtitle: 'Wybierz portfel, do\u00a0którego możemy przypisać wpłatę, jeśli wiadomość dla odbiorcy nie będzie '
			+ 'zawierać poprawnego numeru portfela.',
		label: 'Wybrany portfel',
		noOptionsMessage: 'Żadnych portfeli',
	},
	deviceTokens: {
		button: 'Zalogowane urządzenia mobilne',
		modal: {
			title: 'Zalogowane urządzenia mobilne',
			text: 'Urządzenia mobilne z\u00a0włączonym logowaniem biometrycznym. Logowanie biometryczne jest ważne przez 3\u00a0miesiące. '
				+ 'Odłączając urządzenie, wymusisz wprowadzenie hasła przy kolejnym logowaniu.',
			disconnect: 'Odłączyć',
			table: {
				device: 'Nazwa urządzenia',
				active: 'Ostatnio aktywne',
			},
		},
	},
	investorProfileControl: {
		title: 'Aktualny profil inwestycyjny:',
		titlePlanned: 'Planowany profil inwestycyjny:',
		titleTooltip: 'Profil inwestycyjny, według którego obecnie alokowany jest Twój portfel. Jeżeli chcesz alokować '
			+ 'portfel według innego profilu inwestycyjnego, kliknij w przycisk Zmienić.',
		titlePlannedTooltip: 'Twój portfel zostanie ponownie alokowany zgodnie z\u00a0tym profilem podczas najbliższego rebalansowania. '
			+ 'Jeżeli chcesz zmienić wybór nowego profilu, kliknij w przycisk Zmienić.',
		button: 'Zmień',
		closedTooltip: 'W\u00a0tej chwili udział gotówki nie może zostać zmieniony, ponieważ właśnie odbywa się dopasowanie alokacji portfela.',
		modal: {
			title: 'Zmień wybrany profil',
			submit: 'Zmień profil',
			cancel: 'Zachowaj istniejący profil',
			cancelAllProfilesCheck: 'Zachowaj polecany profil',
		},
		changeWarning: {
			title: 'Zmiana profilu inwestycyjnego',
			subtitle: 'Zmiana profilu inwestycyjnego może skutkować obowiązkiem podatkowym. Czy naprawdę chcesz dokonać takiej zmiany?',
		},
	},
	passwordChange: {
		button: 'Chcę zmienić hasło do konta',
		title: 'Zmiana hasła',
		success: 'Zmiana hasła przebiegła pomyślnie!',
	},
	personalData: {
		button: 'Zapisz zmiany',
		modalInfo: {
			title: 'Kontrola danych osobowych',
			text: '<0>Posiadamy następujące dane osobowe:</0>'
				+ '<1><0>{{ firstName }} {{ lastName }}</0>'
				+ '<0>{{ streetName }} {{ streetNumber }}, {{ city }} {{ postalCode }}</0>'
				+ '<0>{{ phonePrefix }} {{ phoneNumber }}</0></1>'
				+ '<0>Czy te informacje są poprawne?</0>',
			confirm: 'Tak, to jest poprawne',
			edit: 'Nie, chcę je zaktualizować',
		},
		modalNameChange: {
			title: 'Zmiana imienia',
			text: 'Ponieważ wystąpiłeś o\u00a0zmianę nazwiska, musimy poprosić Cię o\u00a0kopię Twojego '
				+ 'aktualnego dokumentu tożsamości.',
			button: 'Załaduj dokument',
		},
		phoneChange: {
			title: 'Weryfikacja nowego numeru',
			codeSendError: 'Kod potwierdzający został wysłany. Jeśli potrzebujesz nowego, proszę poczekaj przynajmniej jedną\u00a0minutę.',
			codeSendSuccess: 'Kod potwierdzający został ponownie wysłany.',
			confirmText: 'Wysłaliśmy kod potwierdzający na nowy numer telefonu, <0>{{ phoneNumber }}</0>. Aby kontynuować, '
				+ 'skopiuj kod z\u00a0SMS-a i\u00a0potwierdź przyciskiem poniżej.',
			cancelText: 'Jeżeli wpisałeś niepoprawny numer telefonu, możesz <0>cancel</0> zmianę.'
				+ 'Now kod zostanie wysłany po 1\u00a0minucie.',
			confirm: 'Potwierdź.',
			sendAgain: 'Wyślij kod ponownie',
		},
	},
	profilePreference: {
		title: 'Ustawienie wiadomości i\u00a0ankiet',
		subtitle: 'E-mailowe wyciągi chcesz otrzymywać:',
	},
	investingQuestionaire: {
		title: 'Kwestionariusz inwestycyjny',
		lastFill: 'Data ostatniego wypełnienia: ',
		possibleFill: 'Data dostępu do kolejnego wypełnienia: ',
		tooltip: 'Kwestionariusz inwestycyjny można wypełnić maksymalnie raz na 6 miesięcy. '
			+ 'Jeśli Twoja sytuacja uległa zmianie i chcesz wypełnić kwestionariusz wcześniej'
			+ 'prosimy o kontakt pod adresem\u00a0info@fondee.pl.',
		now: 'OD RAZU',
		fill: 'Uruchom kwestionariusz inwestycyjny',
		modal: {
			title: 'Wypełnij kwestionariusz inwestycyjny',
			text: 'Wynik kwestionariusza inwestycyjnego ma wpływ na konfigurację Twojego portfela. '
			+ 'W zależności od Twoich odpowiedzi i wyniku kwestionariusza inwestycyjnego możemy zaoferować Ci bardziej ryzykowne profile niż ten, który posiadasz,'
			+ 'może również dojść do obniżenia ryzyka Twojego profilu lub pozostanie on bez zmian.',
			confirm: 'Rozumiem, wypełnić kwestionariusz',
		},
	},
	twoFactorAuthenticationControl: {
		title: 'Uwierzytelnianie dwuskładnikowe',
		active: 'W chwili obecnej nie masz aktywnego uwierzytelniania dwuskładnikowego.',
		disable: 'Dezaktywuj',
		disabled: 'Dezaktywowano pomyślnie',
		info: {
			title: 'Procedura:',
			line1: 'Pobierz generator OTP, na przykład Google Authenticator (<0>App Store</0>, <1>Google Play</1>)',
			line2: 'Zeskanuj QR\u00a0kod',
			line3: 'Wprowadź jednorazowy kod z\u00a0aplikacji',
			line4: 'Kliknij w przycisk \u201eAktywuj\u201c',
		},
		code: {
			label: 'Kod jednorazowy',
			error: 'Nieprawidłowy kod jednorazowy',
		},
		activate: 'Aktywuj',
		activated: 'Aktywowano pomyślnie',
	},
	withdrawals: {
		closureTitle: 'Anulowanie konta',
		activeTitle: 'Wprowadzone polecenia',
		withdrawals: {
			title: 'Wypłata gotówki z\u00a0rachunku inwestycyjnego',
			disabledMessage: 'Może zlecić tylko właściciel określonego konta bankowego.',
			part: {
				title: 'Wypłata gotówki',
				text: 'Wypłata pieniędzy oznacza, że przekażemy brokerowi zlecenie sprzedaż części Twojego portfela, pieniądze przeliczamy '
					+ 'na\u00a0wymaganą walutę i\u00a0wysyłamy na\u00a0Twój rachunek bankowy wskazany jako główny. ETFy zostaną '
					+ 'sprzedane proporcjonalnie do ich udziału w\u00a0portfelu, tak aby po sprzedaży Twój portfel był ponownie '
					+ 'optymalnie alokowany. ETF są notowane w\u00a0euro i\u00a0do wymiany na\u00a0korony zostanie użyty '
					+ 'aktualny kurs w\u00a0czasie sprzedaży. Jeżeli wprowadzona kwota przekroczy bieżącą wartość ETF, zostanie '
					+ 'sprzedany cały portfel i\u00a0gotówka (po odliczeniu opłat miesięcznych) zostanie wysłana na\u00a0Twój\u00a0rachunek.',
				overError: 'Wprowadzona kwota jest wyższa niż aktualna wartość portfela.',
				activeText: 'Wprowadzono polecenie\u00a0wypłaty {{ sum }}.',
				activeTextTransfer: 'Wprowadzono polecenie\u00a0przelewu {{ sumTransfer }} do\u00a0portfela {{ portfolio }}.',
				activeTextTransferMultiple: 'Wprowadzono zlecenia\u00a0przelewu z\u00a0tego portfela:',
				activeTextTransferMultiplePart: '{{ sumTransfer }} do\u00a0portfela {{ portfolio }}',
			},
			all: {
				title: 'Wypłacić wszystko',
				text: 'Wypłata wszystkich dostępnych środków oznacza, że w\u00a0najbliższy dzień handlowy przekażemy brokerowi zlecenie '
					+ '\u00a0sprzedaży wszystkich posiadanych przez Ciebie ETF (jeżeli jakieś masz). Następnie dokonamy wymiany wszystkich wolnych środków '
					+ 'i\u00a0wyślemy je na Twój rachunek bankowy. ETF są notowane w\u00a0euro i\u00a0do wymiany na\u00a0korony '
					+ 'zostanie użyty aktualny kurs w\u00a0czasie sprzedaży. Wybierając tę możliwość wypłacisz ze swojego rachunku dostępne '
					+ 'środki, ale sam rachunek w\u00a0Fondee nie zostanie anulowany.',
				activeText: 'Wprowadzono zlecenie\u00a0sprzedaży wszystkich ETF i\u00a0wypłaty wszystkich środków z\u00a0portfela.',
			},
			confirm: 'Wprowadź',
			success: 'Wypłata została pomyślnie wprowadzona.',
			cancel: 'Anuluj zlecenie',
			cancelOnlyParent: 'Některé příkazy může zrušit pouze zákonný zástupce.',
			currencyWarning: 'Wprowadzona waluta nie jest zgodna z\u00a0walutą portfelu.',
			childPortfolioWarning: 'Jeśli przedwcześnie anulujesz portfel swojego dziecka, nie spełnisz warunków '
				+ 'uprawniających do zniżki. W\u00a0związku z\u00a0tym z\u00a0wypłaconej kwoty pobierzemy różnicę między opłatą '
				+ 'uiszczoną do tej pory a\u00a0naszą standardową opłatą.',
			confirmPopup: {
				title: 'Potwierdzenie wypłaty',
				text: 'Prosimy potwierdź, że naprawdę chcesz zrealizować wprowadzoną wypłatę.',
				button: 'Tak, wypłacić pieniądze',
				twoFactorAuthentication: 'Wprowadź Twój kod jednorazowy',
			},
			allPopup: {
				title: 'Wypłata gotówki z\u00a0rachunku inwestycyjnego',
				text: 'Wprowadzona kwota jest bardzo zbliżona do całkowitej wartości Twojego portfela. Czy chcesz wypłacić wszystkie swoje środki?',
				button: 'Tak, wypłacić wszystko',
				secondaryButton: 'Nie, wypłacić tylko wymaganą kwotę',
			},
			error: {
				title: 'Ostrzeżenie',
				text: 'Wypłaty nie można wprowadzić, ponieważ złożyłeś już polecenie\u00a0wypłaty wszystkich środków, zamknięcia rachunku lub przeniesienia '
					+ 'części portfela. Aby polecenie\u00a0wypłaty można było wprowadzić, musisz najpierw anulować poprzednie polecenia',
				button: 'Zamknij',
			},
			childWarning: '<0>Możesz wypłacić maksymalnie 10% rocznie całkowitej wartości wszystkich portfeli dzieci.</0>'
				+ '<0>Może zlecić tylko właściciel określonego konta bankowego.</0>',
			childRatioError: 'Możesz wypłacić maksymalnie 10% rocznie całkowitej wartości wszystkich portfeli dzieci.',
			pensionWarningTitle: 'Portfel emerytalny',
			pensionWarning: '<0>Warunki wypłaty środków z\u00a0konta emerytalnego nie zostały spełnione. Aby złożyć zlecenie '
				+ 'wypłaty, należy mieć ukończone 60\u00a0lat i\u00a0musi upłynąć co najmniej 10\u00a0lat (tj. 120\u00a0miesięcy) od '
				+ 'daty założenia portfela emerytalnego.</0>'
				+ '<0>Jedyną dostępną opcją jest całkowite anulowanie konta, należy jednak pamiętać, że w\u00a0takim przypadku '
				+ 'opłaty zostaną dostosowane do standardowej stawki 0,9%.</0>',
		},
		cancel: {
			portfolio: {
				title: 'Anulowanie portfela',
				disabledMessage: 'Tylko opiekun prawny może anulować portfel',
				text: 'Jeżeli chcesz anulować ten portfel, przekażemy brokerowi zlecenie sprzedaży wszystkich ETF z\u00a0tego portfela. '
					+ 'Gotówka zostanie następnie (po odliczeniu opłat miesięcznych) przeliczona na\u00a0walutę, którą wybrałeś podczas wprowadzania wypłaty, '
					+ 'i\u00a0wysłana na\u00a0rachunek bankowy wskazany jako rachunek do wypłat. Do\u00a0dsprzedaży ETF dojdzie '
					+ 'w\u00a0najbliższy dzień handlowy po wprowadzeniu zlecenia. Pieniądze wyślemy Ci następnie na\u00a0rachunek. Cały proces '
					+ 'może potrwać nawet tydzień.',
				subtext: 'Nawet\u00a0w\u00a0przypadku, gdy Twój portfel jest pusty, do\u00a0anulowania dojdzie dopiero następnego dnia obrotu.',
				note: '* Zalecamy wybranie opcji "Wypłać wszystkie środki" zamiast całkowitego anulowania portfela. W ten sposób uzyskasz całą '
					+ 'wartość portfela, ale jeśli kiedykolwiek w przyszłości będziesz chciał/a ponownie inwestować z\u00a0Fondeei, nie musisz '
					+ 'otwierać nowego portfela.',
				confirm: 'Anuluj portfel',
				success: 'Polecenie anulowania portfela zostało pomyślnie złożone.',
				activeText: 'Wprowadzono polecenie\u00a0wypłaty wszystkich środków i\u00a0i\u00a0anulowania portfela.',
				account: {
					title: 'Wprowadzono anulowanie rachunku',
					closure: 'Wprowadzono zlecenie\u00a0sprzedaży ETF ze wszystkich portfeli, wypłaty wszystkich środków i\u00a0anulowania rachunku. '
						+ 'Zlecenie możesz anulować na stronie <0>Moje konto</0>.',
					child: {
						closure: 'Zlecono sprzedaż ETF-ów ze wszystkich portfeli, wypłatę wszystkich środków i\u00a0anulowanie konta. '
							+ 'Tylko rodzic może anulować zlecenie na stronie <0>Moje konto</0>.',
					},
				},
				cancel: 'Anuluj zlecenie',
				popup: {
					title: 'Anulowanie portfela',
					text: 'Prosimy o potwierdzenie, że naprawdę chcesz anulować swój portfel w\u00a0Fondee.',
					button: 'Tak, wypłacić pieniądze i\u00a0anulować portfel',
					twoFactorAuthentication: 'Wprowadź Twój kod jednorazowy',
				},
				error: {
					title: 'Ostrzeżenie',
					text: 'Anulowania portfela nie można wprowadzić, ponieważ złożyłeś już polecenie\u00a0wypłaty wszystkich środków, zamknięcia rachunku lub przeniesienia '
						+ 'całego portfela. Aby polecenie\u00a0wypłaty można było wprowadzić, musisz najpierw anulować poprzednie polecenia',
					button: 'Zamknij',
				},
				transferWarning: {
					title: 'Ostrzeżenie',
					text: 'Wprowadzono polecenie\u00a0przeniesienia gotówki lub ETF do\u00a0tego portfela. Anuluj to zlecenie, albo '
						+ 'wprowadź polecenie\u00a0anulowania portfela dopiero po wykonaniu przeniesienia.',
					button: 'Rozumiem',
				},
				defaultPortfolioSelect: {
					title: 'Ostrzeżenie',
					text: 'Zgłosiłaś/eś anulowanie portfela, który masz ustawiony jako główny portfel dla wpłat. '
						+ 'Wybierz nowy portfel główny do alokacji depozytów.',
					textChild: 'Wprowadzono anulowanie portfela, który jest ustawiony jako główny portfel Twojego dziecka. '
						+ 'Wybierz nowy portfel główny do alokacji depozytów. Możesz wybierać spośród aktywnych portfeli tego użytkownika.',
					textBoth: 'Wprowadzono anulowanie portfela, który Ty i Twoje dziecko macie ustawiony jako główny '
						+ 'portfel dla depozytów. Wybierz nowy portfel dla każdego użytkownika.',
					button: 'Ustaw',
					label: 'Wybierz swój nowy główny portfel dla depozytów',
					labelChild: 'Wybierz nowy główny portfel dla swojego dziecka',
				},
			},
			title: 'Wypłata wszystkich portfeli i\u00a0anulowanie konta',
			text: 'Jeśli chcesz zakończyć inwestowanie, poinstruujemy brokera, aby sprzedał wszystkie fundusze ETF w\u00a0Twoich '
				+ 'portfelach. Jeśli masz również aktywne portfele dla dzieci, te portfele również zostaną zamknięte. Pieniądze, '
				+ 'pomniejszone o\u00a0miesięczne opłaty, zostaną następnie przelane na Twój główny rachunek bankowy. Cały proces '
				+ 'może potrwać nawet tydzień. Jeśli chcesz wypłacić pieniądze w\u00a0PLN, saldo zostanie '
				+ 'przelane po aktualnym kursie wymiany. Możesz złożyć zlecenie sprzedaży w\u00a0dowolnym momencie, a\u00a0my '
				+ 'sprzedamy inwestycję następnego dnia handlowego.',
			textChild: 'Tylko rodzic może zlecić anulowanie konta inwestycyjnego. Po osiągnięciu pełnoletności wszystkie prawa '
				+ 'przechodzą na Ciebie, więc będziesz mieć pełną kontrolę nad kontem, w\u00a0tym nad jego anulowaniem.',
			note: 'Zalecamy wycofanie wszystkich środków z\u00a0portfela zamiast całkowitego zamykania konta. Po prostu wprowadź '
				+ 'zlecenie w\u00a0szczegółach odpowiedniego <0>portfela</0>. W\u00a0ten sposób otrzymasz pełną wartość portfela, '
				+ 'ale jeśli w\u00a0przyszłości zechcesz ponownie inwestować w\u00a0Fondee, nie musisz zakładać nowego konta.',
			confirm: 'Potwierdzić',
			success: 'Polecenie anulowania portfolio zostało pomyślnie wprowadzone.',
			activeTitle: 'Wprowadzono anulowanie rachunku',
			activeText: 'Wprowadzono zlecenie\u00a0sprzedaży ETF ze\u00a0wszystkich portfeli, wypłaty wszystkich środków i\u00a0anulowania rachunku.',
			activeTextChild: 'Zlecono sprzedaż ETF-ów ze wszystkich portfeli, wypłatę wszystkich środków i\u00a0anulowanie konta. '
				+ 'Tylko rodzic może anulować zlecenie na stronie Moje konto.',
			cancel: 'Anuluj zlecenie',
			popup: {
				title: 'Anulowanie rachunku',
				text: 'Prosimy o potwierdzenie, że naprawdę chcesz anulować swój rachunek inwestycyjny w\u00a0Fondee.',
				button: 'Tak, wypłacić pieniądze i\u00a0anulować rachunek',
				twoFactorAuthentication: 'Wprowadź Twój kod jednorazowy',
			},
		},
		transfer: {
			title: 'Przeniesienie między portfelami',
			portfolioLabel: 'Przesunąć do\u00a0portfela:',
			direct: {
				title: 'Sprzedaż i\u00a0przeniesienie gotówki',
				text: 'Tutaj możesz wprowadzić kwotę w\u00a0euro, którą chcesz przenieść do\u00a0innego swego portfela. To oznacza, '
					+ 'że w najbliższy dzień handlowy przekażemy brokerowi zlecenie\u00a0sprzedaży odpowiedniej części ETF i\u00a0wprowadzoną kwotę '
					+ 'w\u00a0euro przeniesiemy do\u00a0innego, wybranego przez Ciebie portfela. ETF zostaną sprzedane zgodnie z\u00a0ich proporcjonalnym udziałem '
					+ 'w\u00a0portfelu tak, aby po sprzedaży Twój portfel był znowu optymalnie alokowany. Kwotę przelejemy w\u00a0euro, '
					+ 'aby niepotrzebnie nie dochodziło do wymiany na\u00a0korony czeskie i\u00a0z powrotem. Jeżeli wprowadzona kwota przekroczy aktualną '
					+ 'wartość ETF, zostanie sprzedany cały portfel i\u00a0gotówka przelana do\u00a0wybranego portfela.',
				transferWarning: 'Wprowadzono zlecenie\u00a0sprzedaży i\u00a0przelania gotówki. Nie można już wprowadzić polecenia\u00a0przeniesienia '
					+ 'części portfela bez sprzedaży ETF. Aby wprowadzić polecenie przeniesienia bez sprzedaży ETF, anuluj istniejące polecenie.',
			},
			all: {
				title: 'Sprzedaż wszystkiego i\u00a0przelanie gotówki',
				text: 'Sprzedaż wszystkiego i\u00a0przelanie gotówki oznacza, że w najbliższy dzień handlowy przekażemy brokerowi zlecenie '
					+ '\u00a0sprzedaży wszystkich ETF w\u00a0tym portfelu i\u00a0gotówkę w\u00a0euro przelejemy do\u00a0innego Twojego '
					+ 'wybranego portfela. Kwotę przelejemy w\u00a0euro, aby niepotrzebnie nie dochodziło do\u00a0wymiany na\u00a0czeskie '
					+ 'korony i\u00a0z powrotem.',
				allPopup: {
					title: 'Przeniesienie gotówki między portfelami',
					text: 'Wprowadzona kwota jest bardzo zbliżona do całkowitej wartości Twojego portfela. Czy chcesz przenieść wszystkie swoje środki?',
					button: 'Tak, przenieść wszystko',
					secondaryButton: 'Nie, przenieść tylko wymaganą kwotę',
				},
				activeText: 'Wprowadzono zlecenie\u00a0sprzedaży wszystkich ETF w\u00a0portfelu i\u00a0przeniesienie gotówki do portfela {{ portfolio }}',
			},
			part: {
				title: 'Przeniesienie części portfela bez sprzedaży ETF',
				text: 'Przeniesienie części portfela bez sprzedaży ETF oznacza, że w najbliższym dniu handlowym obliczymy dla każdego ETF, '
					+ 'które posiadasz w\u00a0portfelu, liczbę sztuk odpowiadającą wprowadzonemu udziałowi (zaokrąglenie w dół). Następnie obliczymy udział '
					+ 'gotówki w\u00a0portfelu (dla każdej waluty) odpowiadający wprowadzonemu udziałowi (zaokrąglone do\u00a0setnych w górę). '
					+ 'Obliczone udziały ETF i\u00a0gotówki przeniesiemy do\u00a0wybranego portfela. Nie dojdzie zatem do\u00a0sprzedaży ETF.',
				activeText: 'Wprowadzono polecenie\u00a0przeniesienia {{ sum }} ETF i\u00a0gotówki z\u00a0tego portfela do portfela {{ portfolio }}.',
				error: {
					title: 'Ostrzeżenie',
					text: 'Wprowadzony udział w portfelu je zbyt wysoki. Wprowadź niższy udział lub anuluj wcześniej wprowadzone polecenia '
						+ '\u00a0przeniesienia części portfela.',
					button: 'Zamknij',
				},
				transferWarning: 'Wprowadzono polecenie\u00a0przeniesienia części portfela bez sprzedaży ETF. Nie można już wprowadzić zlecenia '
					+ '\u00a0sprzedaży i\u00a0przeniesienia gotówki. Aby wprowadzić przeniesienie gotówki, anuluj istniejące polecenie.',
			},
			activeTitle: 'Przeniesienie środków do\u00a0innego portfela',
			cancel: 'Anuluj zlecenie',
			confirm: 'Wprowadź',
			childWarning: 'Możesz przenosić środki tylko między portfelami dziecka. Nie możesz wypłacać środków z\u00a0portfela '
				+ 'dziecka na swoje konto osobiste.',
			popup: {
				title: 'Ostrzeżenie',
				text: 'Prosimy o potwierdzenie, że naprawdę chcesz zrealizować wprowadzone przeniesienie.',
				textChild: 'Potwierdź, że naprawdę chcesz wykonać wskazany przelew. Należy pamiętać, że środki przeniesione do '
					+ 'portfela dziecka nie mogą zostać odesłane do portfela rodzica. Wypłaty z\u00a0portfeli dziecięcych na rachunek '
					+ 'bankowy można dokonać do 10% wartości portfela w\u00a0skali roku. Jeśli chcesz wypłacić całą kwotę, musisz '
					+ 'anulować portfel podrzędny.',
				button: 'Tak, zlecić przeniesienie',
				twoFactorAuthentication: 'Wprowadź Twój kod jednorazowy',
			},
			error: {
				title: 'Ostrzeżenie',
				text: 'Przeniesienia nie można wprowadzić, ponieważ złożyłeś już polecenie\u00a0wypłaty wszystkich środków, anulowania rachunku lub przeniesienia '
					+ 'całego portfela. Aby polecenie\u00a0przeniesienia można było wprowadzić, musisz najpierw anulować poprzednie polecenia.',
				button: 'Zamknij',
			},
		},
	},
	childList: {
		title: 'Moje dzieci',
		text: 'Tu możesz zarządzać kontami dzieci, dokończyć rozpoczętą rejestrację lub w\u00a0razie potrzeby ją anulować.',
		finishOnboarding: 'Zakończyć rejestrację',
		personalInformation: {
			title: 'Dane kontaktowe dziecka',
			confirm: 'Zapisz zmiany',
			cancelAccount: 'Anuluj konto dziecka',
		},
	},
	childClosure: {
		cancel: {
			title: 'Anulowanie rachunku',
			text: '<0>Potwierdź, czy naprawdę chcesz zlikwidować konto inwestycyjne dla dziecka w\u00a0Fondee. Wcześniejsza '
				+ 'rezygnacja nie uprawnia Cię do zniżki. W\u00a0związku z\u00a0tym, zgodnie z\u00a0Warunkami umowy, od kwoty do '
				+ 'wypłaty odliczymy różnicę między opłatą uiszczoną do tej pory a\u00a0naszą standardową opłatą.</0>'
				+ '<0>Po sprzedaży ETF gotówka zostanie przelana na Twój główny rachunek bankowy. Cały proces '
				+ 'może potrwać nawet tydzień. Jeśli chcesz wypłacić środki w\u00a0PLN, saldo zostanie przeliczone po aktualnym kursie '
				+ 'wymiany. Anulowanie konta możesz zlecić w\u00a0dowolnym momencie, a\u00a0my sprzedamy aktywa następnego dnia '
				+ 'handlowego. Zalecamy wycofanie wszystkich środków z\u00a0portfela (portfeli) zamiast całkowitego zamykania konta. '
				+ 'Po prostu wprowadź zlecenie w\u00a0szczegółach konkretnego portfela. W\u00a0ten sposób otrzymasz pełną wartość '
				+ 'portfela, ale jeśli kiedykolwiek w\u00a0przyszłości zechcesz ponownie inwestować w\u00a0Fondee, nie musisz otwierać '
				+ 'nowego konta.</0>',
			confirm: 'Rozumiem, anulować konto dziecka',
			warning: 'Bylo zažádáno o\u00a0zrušení účtu',
		},
	},
	onboardingClosure: {
		title: 'Anuluj rejestrację',
		text: 'Potwierdź, czy naprawdę chcesz anulować rejestrację dziecka.',
		cancel: 'Usuń konto dziecka',
		confirm: 'Rozumiem, anulować',
	},
};

const ageCheckBlock = {
	title: 'Ostrzeżenie',
	text: 'Przykro nam, ale nie spełniasz limitu wieku do inwestowania z\u00a0Fondee. Zapraszamy serdecznie ponownie '
		+ 'po osiągnięciu wieku osiemnastu\u00a0lat. Jeżeli chcesz na razie anulować swoją rejestrację, możesz to '
		+ 'zrobić za pośrednictwem linka\u00a0niżej.',
	button: 'Anulować rachunek',
	aborted: 'Twój rachunek został anulowany.',
};

const allPortfoliosCheck = {
	legend: {
		negative: 'Aktualny profil',
		positive: 'Planowana zmiana na najbliższej sesji giełdowej',
	},
	table: {
		title: 'Nazwa portfela',
	},
	recommend: 'Na podstawie wypełnionego przez Ciebie kwestionariusza inwestycyjnego oceniliśmy profile w\u00a0<0>zielonym</0> przedziale '
			+ 'jako odpowiednie dla Ciebie.',
	recommendWarning: 'Według oceny zawartej w kwestionariuszu inwestycyjnym profile w\u00a0szarym przedziale nie są dla '
			+ 'Ciebie odpowiednie.',
	confirm: 'Potwierdzić',
	reset: 'Chcę sprawdzić odpowiedzi zawarte w\u00a0kwestionariuszu inwestycyjnym',
};

const anchors = {
	about: 'co-to-jest-fondee',
	accountClosure: 'anulowanie-konta',
	chart: 'wykres',
	comparison: 'porownanie',
	faq: 'najczestsze-pytania',
	contactForm: 'skontaktuj-sie-z-nami',
	inflation: 'inflacja',
	ourApproachTeam: 'kogo-wybralbys',
	portfolio: 'portfel',
	portfolioSettings: 'ustawienia-portfela',
	portfolioWithdrawals: 'wprowadzone-polecenia',
	products: 'produkty',
	rebalancing: 'rebalansowanie',
};

const appBanner = {
	title: 'Fondee',
	author: 'Fondee a.s.',
	downloadGoogle: 'BEZPŁATNIE - na\u00a0Google Play',
	downloadIOS: 'BEZPŁATNIE - w\u00a0App Store',
	visit: 'Wyświetl',
};

const blog = {
	meta: {
		title: 'Fondee blog',
		description: 'Wszystko, co kiedykolwiek chciałaś/eś wiedzieć o\u00a0inwestowaniu, ale bałaś/eś się zapytać.',
	},
	back: '\u2190\u00a0Wstecz',
	comingSoon: 'Wróć tu wkrótce, nowe artykuły będą tu na Ciebie czekać.',
	title: 'Fondee blog',
	text: 'Wszystko, co kiedykolwiek chciałaś/eś wiedzieć o\u00a0inwestowaniu, ale bałaś/eś się zapytać.',
	menu: {
		title: 'Filtrowanie artykułów',
		search: {
			label: 'Wyszukaj artykuł',
			empty: 'Nie znaleziono artykułu',
		},
		tags: {
			title: 'Filtruj według tagów',
		},
		topRead: {
			title: 'Najczęściej czytane artykuły',
			button: 'Pokaż najczęściej czytane',
		},
	},
};

const blogPost = {
	meta: {
		titleSuffix: ' - Fondee blog',
	},
	updated: 'Zaktualizowano',
	share: {
		title: 'Podobał Ci się artykuł?',
		description: 'Podziel się nim ze swoimi przyjaciółmi',
		button: 'Udostępnij na ',
	},
	list: {
		button: 'Pokaż inne artykuły',
	},
	recommended: {
		title: 'Inne powiązane artykuły',
	},
	references: {
		title: 'Źródła:',
	},
	content: {
		title: 'Treść artykułu',
	},
};

const childAgeCheck = {
	modal: {
		title: 'Twoje dziecko zbliża się do pełnoletności',
		text: 'Kiedy dziecko osiągnie pełnoletność, portfel zostanie przekazany pod jego własny zarząd. W\u00a0tym celu należy '
			+ 'uzupełnić poniższe dane osobowe. Jeśli nie uzupełnisz tych danych, portfel pozostanie pod Twoim pełnym zarządem.',
		checkbox: 'Potwierdzam, że dane kontaktowe mojego dziecka są prawidłowe i\u00a0aktualne.',
		button: 'Potwierdź dane',
	},
};

const clientCheck = {
	description: '<0>Na tej stronie znajdziesz nie tylko regularne kontrole dotyczące Twojego konta, ale również '
		+ 'kontrole dotyczące kont Twoich dzieci, które od czasu do czasu trzeba przeprowadzić w\u00a0celu aktualizacji '
		+ 'danych.</0><0> Jeśli kontrola pozostanie niedokończona, ograniczymy dostęp do strefy klienta (również dla '
		+ 'dziecka, jeśli ma własny dostęp). W\u00a0przypadku jakichkolwiek pytań, prosimy o\u00a0kontakt pod adresem <0>info@fondee.cz</0>.</0>',
	childDescription: 'Aby przywrócić dostęp do strefy klienta, należy dokończyć kontrolę konta dziecka. Kontrolę musi '
		+ 'przeprowadzić rodzic w\u00a0swojej strefie klienta w\u00a0zakładce Regularne kontrole.',
	questionnaire: {
		aml: {
			title: 'Przeciwdziałanie praniu pieniędzy',
			text: 'W\u00a0celu ciągłej ochrony aktywów Twoich i\u00a0innych klientów, musimy od czasu do czasu sprawdzić, '
				+ 'czy wszystkie Twoje dane są aktualne. Więc zróbmy to, proces zajmie tylko kilka minut.',
			start: 'Wypełnij ankietę',
			closeAccount: {
				title: 'Anulowanie konta',
				text: 'Wybierz\u00a0walutę',
				button: 'Anuluj konto',
			},
		},
		investment: {
			title: 'Przegląd ankiety inwestycyjnej',
			text: 'Od czasu do czasu musimy sprawdzić, czy zmieniły się Twoje warunki inwestycyjne. Jeśli tak, może to mieć wpływ na wybór '
				+ 'Twojego idealnego profilu inwestycyjnego. Pamiętaj, że na podstawie nowych odpowiedzi możesz otrzymać inny profil niż ten, '
				+ 'który aktualnie wybrałeś. Możesz zmienić swoje odpowiedzi w dowolnym momencie przed zakończeniem.',
			warning: 'Aby pomyślnie zakończyć kontrolę, konieczne jest potwierdzenie wprowadzonych zmian. '
				+ 'W\u00a0przeciwnym razie Twoja strefa klienta zostanie zablokowana po 30\u00a0dniach od rozpoczęcia kontroli. '
				+ 'Jeśli popełniłaś/eś błąd w\u00a0kwestionariuszu, możesz do niego powrócić, jednak możesz poprawić swoje odpowiedzi tylko dwa razy.',
			start: 'Wypełnij ankietę',
		},
	},
	documents: {
		error: 'Przepraszamy, wystąpił\u00a0błąd podczas ładowania dokumentu.',
		title: 'Załaduj prawidłowy dokument',
		text: 'Prosimy o\u00a0przesłanie aktualnego dowodu osobistego. Po kliknięciu przycisku przekierujemy Państwa '
			+ 'do naszego serwisu partnerskiego, który obsługuje za nas proces przetwarzania dokumentów. Cały proces jest '
			+ 'szyfrowany, aby zapewnić bezpieczeństwo Państwa danych osobowych.',
	},
	personalInformation: {
		title: 'Kontrola danych osobowych',
	},
	checkAt: 'Ostatnio sprawdzane: <0>{{ date }}</0>',
	checkNext: 'Kolejne sprawdzane: <0>{{ date }}</0>',
	profileSelect: {
		prev: 'Poprzednie',
		next: 'Następne',
		myAccount: 'Moje konto',
	},
};

const clientHeader = {
	faqLink: 'Jak i\u00a0kiedy odbywa się inwestowanie',
	nextTradingDay: 'Najbliższy dzień handlowy',
	titles: {
		deposit: 'Wpłata',
		depositAndWithdrawal: 'Wpłata i\u00a0wypłata',
		trading: 'Obrót',
		withdrawal: 'Wypłata',
	},
};

const contact = {
	title: 'Zapytaj',
	text: 'Przeczytaj najczęstsze pytania lub napisz do nas za pomocą poniższego formularza.',
	contactFormAnchor: 'Przejdź do\u00a0formularza',
	faqAnchor: 'Przejść do\u00a0najczęściej zadawanych pytań',
	faqTitle: 'Najczęściej zadawane pytania',
};

const contactForm = {
	button: 'Zostaw nam swój kontakt,<0></0>a\u00a0my oddzwonimy',
	modal: {
		title: 'Chcesz o coś zapytać?',
		subtitle: 'Zostaw nam numer do siebie, odezwiemy się do\u00a0Ciebie',
		submit: 'Zadzwońcie do mnie',
		success: 'Dziękujemy, wkrótce odezwiemy się\u00a0do Ciebie!',
	},
};

const contactUs = {
	title: 'Nie znalazłaś/eś odpowiedzi na swoje\u00a0pytania?',
	text: {
		label: 'Twoje pytanie',
		error: 'Twoje pytanie jest wymagane',
	},
	name: {
		label: 'Imię i\u00a0nazwisko',
		error: 'Prosimy podaj swoje imię i\u00a0nazwisko.',
	},
	email: {
		label: 'E-mail',
		error: 'Prosimy podaj swój adres e-mail.',
	},
	conditions: {
		label: 'Wyrażam zgodę na <0>przetwarzanie danych osobowych</0> i\u00a0<1>regulamin strony internetowej</1>',
		error: 'Bez tej zgody nie będzie to możliwe.',
	},
	submit: 'Wyślij zapytanie',
	success: 'Dziękujemy za Twoje zapytanie. Wkrótce odezwiemy się do Ciebie!',
};

const cookieConsent = {
	message: '<0>Fondee\u00a0a.s. używa plików cookies, aby lepiej dostosować swoje strony internetowe '
		+ 'klientom i\u00a0ułatwić korzystanie z\u00a0nich. Niezbędne pliki cookies przetwarzamy zawsze również\u00a0bez Twojej '
		+ 'zgody. W ten sposób zapewniamy prawidłowe funkcjonowanie strony internetowej.</0><0>Jeżeli klikniesz w przycisk '
		+ '„Akceptuj wszystko“, udzielasz tym samym spółce Fondee\u00a0a.s. zgody na\u00a0zapisywanie analitycznych i\u00a0marketingowych '
		+ 'plików cookies. Jeżeli klikniesz w przycisk „Akceptuj tylko niezbędne“, udzielisz tym samym spółce Fondee\u00a0a.s. '
		+ 'zgody na\u00a0zapisywanie tylko funkcjonalnych plików cookies. Jeżeli klikniesz w przycisk „Ustawienia szczegółowe“, '
		+ 'możesz wybrać, na które cele przetwarzania plików cookies chcesz zezwolić.</0><0>Za pomocą plików cookies Fondee oraz '
		+ 'nasi partnerzy mogą gromadzić informacje o\u00a0Tobie i\u00a0w ten sposób śledzić '
		+ 'Twoje zachowanie podczas przeglądania naszych i\u00a0ewentualnie innych stron internetowych. Na\u00a0podstawie tych informacji '
		+ 'możemy dostosować nasze strony internetowe, sposób komunikacji i\u00a0reklamy dostosować do Twoich zainteresowań i\u00a0profilu. '
		+ 'Ponadto te pliki cookies umożliwiają udostępnianie informacji w\u00a0mediach społecznościowych.</0><0>'
		+ 'Więcej informacji można znaleźć w\u00a0naszych <0>Informacjach na temat\u00a0przetwarzania danych osobowych</0> '
		+ 'i\u00a0w\u00a0<1>Zasadach korzystania z\u00a0plików cookies</1>.</0>',
	controls: {
		details: 'Ustawienia szczegółowe',
		necessary: 'Zaakceptuj niezbędne',
		selected: 'Zaakceptuj tylko wybrane',
		all: 'Zaakceptuj wszystkie',
	},
	categories: {
		functionality: {
			title: 'Funkcjonalne pliki cookies',
			text: 'Wykorzystujemy je przede wszystkim do zapewnienia prawidłowego funkcjonowania naszej strony internetowej, '
				+ 'aby dowiedzieć się, jakie są Twoje preferencje i\u00a0aby strona pamiętała, że jesteś '
				+ 'zalogowany, oraz\u00a0do śledzenia użytkowników w celu ich rejestracji. Niezbędne pliki cookies '
				+ 'są ponadto używane do\u00a0optymalizacji naszej strony internetowej w rzeczywistym czasie w zależności od tego, w jaki sposób ze stron '
				+ 'korzystasz i\u00a0do\u00a0równomiernego wczytywania stron internetowych. Bez tych plików strona '
				+ 'internetowa nie może prawidłowo działać.',
		},
		analytics: {
			title: 'Analityczne pliki cookies',
			text: 'Służą do\u00a0analizy korzystania ze strony internetowej,\u00a0liczenia osób odwiedzających, monitorowania czasu '
				+ 'odwiedzin i\u00a0stwierdzenia, w jaki sposób można stronę internetową ulepszyć. Statystyki korzystania ze strony internetowej '
				+ 'i\u00a0inne wiadomości nie łączymy z\u00a0konkretnymi ludźmi.',
		},
		marketing: {
			title: 'Cookies do profilowania i\u00a0remarketing',
			text: 'Służą do gromadzenia danych osobowych o\u00a0użytkownikach z\u00a0marketingowego punktu widzenia. Np. gromadzą '
				+ 'informacje w celu dostosowania oferowanej reklamy do zainteresowań klienta, powiązania z\u00a0mediami społecznościowymi, przekazywania '
				+ 'informacji między podmiotami stowarzyszonymi\u00a0itd.',
		},
	},
};

const cookies = {
	title: 'Polityka dotycząca korzystania z\u00a0plików cookies',
	intro: '<0>Są to zasady korzystania z\u00a0plików cookies (zwane dalej „<0>Zasadami</0>“) na\u00a0stronie internetowej '
		+ 'spółki <0>Fondee\u00a0a.s.</0>, z\u00a0siedzibą: Rumunská\u00a022/28, Vinohrady, 120\u00a000 Praha\u00a02, Republika Czeska'
		+ 'Regon:\u00a006691862, wpisanej w\u00a0Rejestrze Handlowym prowadzonym przez Sąd Miejski w\u00a0Pradze pod '
		+ 'sygn.\u00a0akt\u00a0B\u00a027322 (zwana dalej „<0>Spółką</0>“ lub „<0>Administratorem</0>“) i\u00a0v\u00a0w aplikacji '
		+ 'Administratora o nazwie „Fondee aplikacja mobilna“ (zwana dalej „<0>Aplikacją</0>“) i\u00a0mają zastosowanie do '
		+ 'wszystkich użytkowników strony internetowej obsługiwanej przez Spółkę (zwana dalej „<0>Stroną internetową</0>“) '
		+ 'i\u00a0w Aplikacji tam, gdzie jest to istotne.</0>'
		+ '<0>Celem niniejszych Zasad jest rzetelnie informować użytkownika Strony internetowej i/lub Aplikacji o\u00a0korzystaniu z\u00a0plików cookies '
		+ 'i\u00a0o\u00a0możliwości wyłączenia korzystania z\u00a0nich, ich celem jest również\u00a0dostarczenie informacji, w jaki '
		+ 'sposób są przetwarzane i\u00a0używane dane użytkowników strony internetowej i/lub Aplikacji oraz\u00a0jak za pomocą plików '
		+ 'cookies Spółka przetwarza informacje o\u00a0użytkownikach strony internetowej.</0>'
		+ '<0>Spólka nie używa plików cookies, aby stwierdziła dane osobowe użytkowników, niemniej jednak dane osobowe, które Spółka '
		+ 'uzyska za ich pośrednictwem, może ewentualnie powiązać z\u00a0innymi danymi osobowymi użytkowników, które użytkownicy '
		+ 'przekazali Spółce lub Spółka je uzyskała w inny sposób. Spółka przetwarza dane osobowe użytkowników, '
		+ 'w tym dane osobowe uzyskane dzięki korzystaniu z\u00a0plików cookies, zgodnie\u00a0z\u00a0Rozporządzeniem Parlamentu Europejskiego '
		+ 'i\u00a0Rady\u00a0(UE) nr\u00a02016/679 z\u00a0dnia 27\u00a0kwietnia\u00a02016, o\u00a0ochronie osób fizycznych '
		+ 'w\u00a0związku z\u00a0przetwarzaniem danych osobowych i\u00a0o\u00a0swobodnym przepływie tych danych, i\u00a0powiązanymi '
		+ 'przepisami prawa o\u00a0ochronie danych osobowych i\u00a0korzystaniu z\u00a0plików\u00a0cookies.</0>',
	about: {
		title: 'Co to są pliki cookies',
		text: '<0>Pliki cookies to krótkie pliki tekstowe, które zazwyczaj zawierają unikalny identyfikator '
			+ 'i\u00a0które przesyłane są do przeglądarki Twojego urządzenia końcowego i\u00a0zostają umieszczone w\u00a0repozytorium Twojego '
			+ 'urządzenia końcowego. Plik ten zawiera pewne anonimowe informacje, takie jak identyfikator, nazwa strony '
			+ 'internetowej, ewentualnie czas trwania (moment wygaśnięcia ważności). Dany plik może być tymczasowy i\u00a0istnieje w\u00a0czasie Twojego '
			+ 'zalogowania na naszej stronie internetowej, lub może mieć charakter trwały i\u00a0zostaje w\u00a0repozytorium Twojego urządzenia '
			+ 'końcowego przez ustalony okres ważności lub jeżeli nie został usunięty przez użytkownika.</0>',
		app: {
			title: 'Korzystanie z\u00a0plików cookies w\u00a0Aplikacji',
			text: '<0>Aplikacja używa funkcjonalnych plików cookies. Funkcjonalne pliki cookies są niezbędne do\u00a0tego, aby Aplikacja poprawnie '
				+ 'działała i\u00a0jednocześnie, aby pamiętała Twoje zalogowanie. Właśnie dzięki funkcjonalnym plikom cookies możemy monitorować '
				+ 'bezbłędną pracę aplikacji i\u00a0przygotowywać nowe wersje aplikacji itp. Dane uzyskane dzięki funkcjonalnym plikom cookies przetwarzamy '
				+ 'zawsze i\u00a0wyłącznie w\u00a0zagregowanej postaci, czyli bez identyfikacji konkretnego użytkownika. Dane przetwarzane dzięki '
				+ 'funkcjonalnym plikom cookies nie zostaną wykorzystane do\u00a0celów marketingowych.</0>'
				+ '<0>W\u00a0Aplikacji korzystamy z\u00a0następujących podstawowych narzędzi analitycznych. W Twoim urządzeniu końcowym, nie są jednak zapisywane '
				+ 'żadne informacje, jak w przypadku\u00a0analitycznych plików cookies na Stronie internetowej. Informacje te dotyczą wyłącznie '
				+ 'wyświetlania poszczególnych stron w\u00a0Aplikacji i\u00a0są w pełni zanonimizowane i\u00a0zagregowane. Żadne informacje '
				+ 'o\u00a0konkretnym użytkowniku nie są gromadzone ani zapisywane.</0>'
				+ '<0>Poprzez zainstalowanie i\u00a0korzystanie z\u00a0Aplikacji wyrażasz zgodę na przetwarzanie funkcjonalnych plików cookies w\u00a0zakresie opisanym wyżej.</0>',
		},
	},
	types: {
		title: 'Podział plików cookies',
		texts: [
			{
				text: '<0>W zależności od źródła i\u00a0kontroli rozróżniamy pliki cookies:</0>',
				list: [
					'<0>Cookies pierwszej strony</0>, które są tworzone przez strony internetowe. Podczas odwiedzania stron internetowych '
					+ 'te pliki cookies są kontrolowane i\u00a0administrowane przez Spółkę.',
					'<0>Pliki cookies stron trzecich</0>, tworzone przez inne strony internetowe, które na\u00a0Stronie internetowej są właścicielami'
					+ 'określonych treści i\u00a0te pliki cookies nie są pod kontrolą Spółki.',
				],
			},
			{
				text: '<0>W zależności od czasu przechowywania w Twoim urządzeniu końcowym pliki cookies dzielimy\u00a0na:</0>',
				list: [
					'<0>Tymczasowe</0>, które są usuwane po zakończeniu Twojej wizyty na stronie internetowej.',
					'<0>Trwałe</0>, które są przechowywane również\u00a0po zakończeniu Twojej wizyty na stronie internetowej.',
				],
			},
			{
				text: '<0>W\u00a0zależności od\u00a0funkcji i\u00a0celu, dla którego używamy plików cookies, dzielimy je'
					+ '\u00a0następująco:</0>',
				list: [
					'<0>Funkcjonalne pliki cookies</0>, których używamy przede wszystkim w celu zapewnienia, aby nasza strona internetowa '
					+ 'i\u00a0Aplikacja działały poprawnie, aby dowiedzieć się, jakie są Twoje preferencje i\u00a0aby Strona internetowa '
					+ 'i\u00a0Aplikacja pamiętała, że jesteś zalogowany, oraz\u00a0do śledzenia użytkowników w celu ich rejestracji. '
					+ 'Niezbędne pliki cookies są również wykorzystywane do\u00a0optymalizacji naszej strony internetowej i\u00a0Aplikacji w\u00a0czasie rzeczywistym '
					+ 'w zależności od sposobu korzystania ze Strony internetowej i\u00a0do\u00a0równomiernego wczytywania Strony internetowej i\u00a0Aplikacji. '
					+ 'Bez tych plików Strona internetowa i\u00a0Aplikacja nie mogą działać poprawnie.',
					'<0>Analityczne pliki cookies</0>, które służą do\u00a0analizy korzystania ze Strony internetowej, do\u00a0liczenia '
					+ 'odwiedzających, do\u00a0śledzenia czasu odwiedzin i\u00a0do\u00a0stwierdzania, w jaki sposób można Stronę internetową ulepszyć. '
					+ 'Statystyki korzystania ze Strony internetowej i\u00a0inne wiadomości nie łączymy z\u00a0konkretnymi osobami.',
					'<0>Marketingowe pliki cookies</0>, które służą do zbierania danych osobowych o\u00a0użytkownikach z\u00a0marketingowego '
					+ 'punktu widzenia. Te pliki cookies gromadzą na przykład informacje w celu dostosowania oferowanej reklamy do zainteresowań klienta, połączenia '
					+ 'z sieciami społecznościowymi, przekazywania informacji między podmiotami stowarzyszonymi\u00a0itd.',
				],
			},
		],
	},
	table: {
		title: 'Przegląd konkretnych plików cookies używanych na\u00a0Stronie internetowej',
		head: [
			'Cookies',
			'Domena lub\u00a0narzędzie',
			'Pierwsze\u00a0strony /\u00a0Trzecie\u00a0strony',
			'Cel',
			'Kategoria',
			'Okres',
		],
		rows: [
			{
				name: '_ga',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Zlicza i\u00a0przechowuje liczbę wyświetleń stron.',
				category: 'Analityczne',
				period: '2\u00a0lata',
			},
			{
				name: '_gid',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Zlicza i\u00a0przechowuje liczbę wyświetleń stron.',
				category: 'Analityczne',
				period: '1\u00a0den',
			},
			{
				name: '_gat_UA-*',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Ogranicza ilość danych przechowywanych przez spółkę Google na\u00a0stronach o\u00a0dużym natężeniu ruchu.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: 'YSC',
				domain: 'youtube.com',
				party: 'Trzeciej strony',
				purpose: 'Monitoruje i\u00a0a\u00a0zbiera informacje o\u00a0działaniach użytkownika podczas\u00a0filmów z\u00a0YouTube.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: 'PHPSESSID',
				domain: 'api.fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Zapewnia podstawową funkcjonalność strony internetowej.',
				category: 'Funkcjonalne',
				period: '14\u00a0dni',
			},
			{
				name: '_nss',
				domain: 'api.fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Sprawdza, co spowodowało załadowanie strony.',
				category: 'Funkcjonalne',
				period: 'Session',
			},
			{
				name: '_nss',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Sprawdza, co spowodowało załadowanie strony.',
				category: 'Funkcjonalne',
				period: 'Session',
			},
			{
				name: 'LaUserDetails',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przechowuje dane w celu identyfikacji użytkowników.',
				category: 'Funkcjonalne',
				period: 'Session',
			},
			{
				name: 'LaSID',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'identyfikator sesji odwiedzającego.',
				category: 'Funkcjonalne',
				period: 'Session',
			},
			{
				name: 'LaVisitorId_*',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Identyfikator odwiedzającego na\u00a0każdej stronie, na której zintegrowany jest widżet czatu.',
				category: 'Funkcjonalne',
				period: 'Session',
			},
			{
				name: 'LaVisitorNew',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Informacja, czy odwiedzający jest\u00a0nowym czy powracającym odwiedzającym.',
				category: 'Funkcjonalne',
				period: '1\u00a0den',
			},
			{
				name: COOKIE_CONSENT_NAME,
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przechowuje zgodę na\u00a0przetwarzanie plików cookies.',
				category: 'Funkcjonalne',
				period: '1\u00a0rok',
			},
			{
				name: 'PHPSESSID',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Zapewnia podstawową funkcjonalność strony internetowej.',
				category: 'Funkcjonalne',
				period: '30\u00a0dni',
			},
			{
				name: 'VISITOR_INFO1_LIVE',
				domain: 'youtube.com',
				party: 'Trzeciej strony',
				purpose: 'Umożliwia oszacowanie prędkości Internetu użytkownika do odtwarzania filmów z\u00a0Youtube.',
				category: 'Funkcjonalne',
				period: '6\u00a0miesięcy',
			},
			{
				name: 'fondee_infobox',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Śledzenie, czy użytkownik kliknął w powiadomienie na\u00a0Stronie internetowej.',
				category: 'Funkcjonalne',
				period: '1\u00a0rok',
			},
			{
				name: 'seen_find_out_profile_popup',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Wykorzystywane do śledzenia użytkowników w celach rejestracji.',
				category: 'Funkcjonalne',
				period: 'Stałe',
			},
			{
				name: '_fbp',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Śledzi i\u00a0przechowuje odwiedziny na różnych stronach.',
				category: 'Marketingowe',
				period: '3\u00a0miesiące',
			},
			{
				name: '_gcl_au',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Śledzi i\u00a0przechowuje konwersje użytkowników.',
				category: 'Marketingowe',
				period: 'Stałe',
			},
			{
				name: 'IDE',
				domain: 'doubleclick.net',
				party: 'Trzeciej strony',
				purpose: 'Śledzenie użytkownika w celu wyświetlania reklam.',
				category: 'Marketingowe',
				period: '13\u00a0miesiące',
			},
			{
				name: 'test_cookie',
				domain: 'doubleclick.net',
				party: 'Trzeciej strony',
				purpose: 'Śledzenie użytkownika w celu wyświetlania reklam.',
				category: 'Marketingowe',
				period: '15\u00a0minut',
			},
			{
				name: 'sid',
				domain: 'seznam.cz',
				party: 'Trzeciej strony',
				purpose: 'Śledzenie użytkownika w celu wyświetlania reklam.',
				category: 'Marketingowe',
				period: '30\u00a0dni',
			},
			{
				name: 'ehubSession_*',
				domain: 'ehub',
				party: 'Trzeciej strony',
				purpose: 'Śledzenie rejestracji z\u00a0programów partnerskich.',
				category: 'Marketingowe',
				period: '30\u00a0dni',
			},
			{
				name: 'ehubVisitorId',
				domain: 'ehub',
				party: 'Trzeciej strony',
				purpose: 'Śledzenie rejestracji z\u00a0programów partnerskich.',
				category: 'Marketingowe',
				period: '30\u00a0dni',
			},
			{
				name: '_hjSessionUser{site_id}',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydziela niepowtarzalny identyfikator każdemu odwiedzającemu stronę.',
				category: 'Analityczne',
				period: '365\u00a0dni',
			},
			{
				name: '_hjSession{site_id}',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przechowuje bieżące dane sesji.',
				category: 'Analityczne',
				period: '30\u00a0minut',
			},
			{
				name: '_hjClosedSurveyInvites',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydzielany jest w chwili, gdy użytkownik wykona działanie z\u00a0oknem kwestionariusza.',
				category: 'Analityczne',
				period: '365\u00a0dni',
			},
			{
				name: '_hjDonePolls',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydzielany jest w chwili, gdy użytkownik dokończy wypełnianie kwestionariusza za pośrednictwem widżetu na Stronie internetowej.',
				category: 'Analityczne',
				period: '365\u00a0dni',
			},
			{
				name: '_hjMinimizedPolls',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydzielany jest w chwili, gdy użytkownik minimalizuje widżet na Stronie internetowej.',
				category: 'Analityczne',
				period: '365\u00a0dni',
			},
			{
				name: '_hjShownFeedbackMessage',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydzielany jest w chwili, gdy użytkownik minimalizuje lub zakończy informację zwrotną.',
				category: 'Analityczne',
				period: '365\u00a0dni',
			},
			{
				name: '_hjSessionTooLarge',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Uruchamiany w chwili, gdy wolumen danych w\u00a0Sesji jest zbyt duży.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjSessionRejected',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydzielany jest w chwili, gdy serwer zewnętrzny odmówił komunikacji z\u00a0powodu przeciążenia.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjSessionResumed',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydzielany jest w chwili, gdy połączenie z\u00a0serwerem zostało przywrócone po awarii.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjid',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przydziela niepowtarzalny identyfikator każdemu odwiedzającemu stronę.',
				category: 'Analityczne',
				period: '365\u00a0dni',
			},
			{
				name: '_hjRecordingLastActivity',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Używany do śledzenia działań użytkowników.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjTLDTest',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Używany w\u00a0przypadku, gdy pliki cookies muszą być współdzielone między subdomenami.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjUserAttributesHash',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Zaszyfrowane atrybuty odwiedzających wysyłane za pośrednictwem Hotjar API.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjCachedUserAttributes',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przechowywane zaszyfrowane atrybuty odwiedzających.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjLocalStorageTest',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Służy do określenia, czy Hotjar Tracking Script może korzystać z\u00a0lokalnego repozytorium.',
				category: 'Analityczne',
				period: 'Mniej niż 100\u00a0ms',
			},
			{
				name: '_hjIncludedInPageviewSample',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Służy do\u00a0określania, czy użytkownik jest częścią próbki śledzenia na podstawie wyświetleń strony.',
				category: 'Analityczne',
				period: '30\u00a0minut',
			},
			{
				name: '_hjIncludedInSessionSample',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Służy do\u00a0określenia, czy użytkownik jest częścią próbki śledzenia zgodnie z\u00a0limitem długości sesji.',
				category: 'Analityczne',
				period: '30\u00a0minut',
			},
			{
				name: '_hjAbsoluteSessionInProgress',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Identyfikuje pierwszą sesję danego użytkownika na podstawie wyświetleń strony.',
				category: 'Analityczne',
				period: '30\u00a0minut',
			},
			{
				name: '_hjFirstSeen',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Identyfikuje pierwszą sesję danego użytkownika.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjViewportId',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Przechowuje informacje o\u00a0viewporcie użytkownika, takie jak rozdzielczość i\u00a0wielkość.',
				category: 'Analityczne',
				period: 'Session',
			},
			{
				name: '_hjRecordingEnabled',
				domain: 'fondee.pl',
				party: 'Pierwsze strony',
				purpose: 'Używany do kontroli, czy odwiedzający jest już częścią jakiegokolwiek śledzenia, czy nie.',
				category: 'Analityczne',
				period: 'Session',
			},
		],
	},
	options: {
		title: 'Twoje możliwości zarządzania plikami cookies',
		text: '<0>Podczas odwiedzania Strony internetowej wyświetli Ci się tzw.\u00a0cookies banner z\u00a0możliwością ustawienia '
			+ 'opcjonalnych plików cookies i\u00a0s\u00a0możliwością ich akceptacji lub odrzucenia. W\u00a0ramach cookies bannera masz '
			+ 'możliwość wyboru własnych preferencji i\u00a0potwierdzić swój wybór, który możesz zmienić w dowolnym momencie poniżej na\u00a0tej stronie.</0>'
			+ '<0>Możesz również wyłączyć pliki cookies, zmieniając ustawienia swojej przeglądarki, które pozwalają odmówić korzystania ze wszystkich lub '
			+ 'tylko wybranych cookies. Zwracamy jednak uwagę, że jeżeli ustawisz przeglądarkę tak, aby wyłączyła wszystkie pliki cookies '
			+ 'włącznie z\u00a0niezbędnymi, możesz nie być w stanie uzyskać dostępu do\u00a0Strony internetowej i/lub jej funkcjonalność może być ograniczona.</0>'
			+ '<0>Wyłączenie plików cookies nie usunie cookies z\u00a0przeglądarki,\u00a0należy je usunąć samodzielnie w\u00a0przeglądarce. '
			+ 'Możesz również usunąć istniejące pliki cookies, które już znajdują się w\u00a0repozytorium Twojego urządzenia końcowego.</0>'
			+ '<0>W ustawieniach swojej przeglądarki możesz zezwolić na wszystkie pliki cookies, zablokować wszystkie pliki cookies '
			+ 'lub tylko pliki cookies stron trzecich,\u00a0możesz również poprosić o powiadomienia dotyczące przechowywanych plików cookies. '
			+ 'Bardziej szczegółowe informacje na temat\u00a0ustawień plików cookies dostępne są bezpośrednio w\u00a0ustawieniach Twojej przeglądarki, ewentualnie '
			+ 'możesz zajrzeć do pomocy swojej przeglądarki.</0>',
	},
	management: {
		title: 'Administracja plików cookies stron trzecich',
		text: '<0>Aby uzyskać bardziej szczegółowe informacje o\u00a0tym, w jaki sposób są administrowane i\u00a0ustawione pliki cookies stron trzecich, '
			+ 'zalecamy przeczytanie stosownych zasad korzystania z\u00a0plików cookies:</0>',
		list: [
			{
				title: 'Youtube',
				link: 'https://www.youtube.com/account_privacy',
			},
			{
				title: 'Hotjar',
				link: 'https://www.hotjar.com/legal/policies/privacy/',
			},
			{
				title: 'Google Analytics',
				link: 'https://www.cookiepolicygenerator.com/cookies-policy-google-analytics/',
			},
			{
				title: 'Live Agent',
				link: 'https://support.liveagent.com/709382-Cookies-used-by-LiveAgent',
			},
			{
				title: 'Facebook',
				link: 'https://www.facebook.com/policy/cookies/',
			},
			{
				title: 'LinkedIn',
				link: 'https://cz.linkedin.com/legal/cookie-policy',
			},
			{
				title: 'Google',
				link: 'https://policies.google.com/technologies/cookies?hl=cs',
			},
			{
				title: 'Seznam',
				link: 'https://www.seznam.cz/reklama/cz/obsahovy-web/pravidla-reklamy/gdpr/#data-ktera-jsou-potrebna',
			},
		],
	},
	final: {
		title: 'Postanowienia końcowe',
		text: '<0>Informacje dotyczące\u00a0przetwarzania danych osobowych są\u00a0dostępne <0>tutaj</0>.</0>'
			+ '<0>Ze\u00a0względu na fakt, że świat technologii informatycznych wciąż się rozwija, zastrzegamy sobie prawo do zmiany kiedykolwiek tych '
			+ 'Zasad.</0>'
			+ '<0>Jeżeli masz jakiekolwiek pytania w\u00a0związku z\u00a0plikami cookies, skontaktuj się z\u00a0nami pod adresem\u00a0<1>info@fondee.pl</1>.</0>',
	},
	edit: 'Ustawienia cookies',
};

const dashboard = {
	filter: {
		title: 'Aprecjacja za',
		options: {
			day: 'dzień',
			week: 'tydzień',
			month: 'miesiąc',
			threemonths: '3 miesiące',
			sixmonths: '6 miesięcy',
			year: 'rok',
			fromstart: 'od początku',
		},
	},
	chart: {
		loadmore: 'Wczytaj kolejne',
		loadall: 'Wczytaj wszystko',
		toggle: {
			value: 'Wartość',
			evaluation: 'Aprecjacja',
		},
		tooltip: {
			value: 'Wartość',
			evaluation: 'Zmiany wartości portfela',
			nonGrowthPlus: 'Wpłaty/bonusy',
			nonGrowthMinus: 'Wypłaty/przeniesienia między portfelami',
			growthPlus: 'Dywidendy/zwroty',
			growthMinus: 'Opłaty',
		},
	},
};

const documents = {
	title: 'Dokumenty',
	subtitle: 'Umowy i\u00a0inne dokumenty',
	secondTitle: 'Informacje podlegające obowiązkowemu udostępnieniu',
	reportsTitle: 'Raporty roczne',
	esgTitle: 'Informacje związane ze\u00a0zrównoważonym rozwojem',
	otherTitle: 'Inne dokumenty',
	contract: 'Podpisana umowa z\u00a0Fondee',
	conditions: 'Warunki umowy',
	terms: 'Zasady korzystania ze strony internetowej',
	personalInfoAgreement: 'Ochrona danych osobowych',
	informationSheet: 'Informacje dla klientów',
	agreementTemplate: {
		standard: 'Wzór umowy',
		child: 'Wzór umowy - nieletni',
		pension: 'Wzór umowy',
	},
	agreementTemplateCzech: {
		standard: 'Wzór umowy po czesku',
		child: 'Wzór umowy po czesku - nieletni',
		pension: 'Wzór umowy po czesku',
	},
	complaintsProcedure: 'Regulamin reklamacyjny',
	annualReport2021: '2021 Raport roczny',
	annualReportTemplates2021: '2021 Szablony do publikowania informacji domu maklerskiego',
	annualReport2022: '2022 Raport roczny',
	annualReportTemplates2022: '2022 Szablony do publikowania informacji domu maklerskiego',
	annualReport2023: '2023 Raport roczny',
	annualReportTemplates2023: '2023 Szablony do publikowania informacji domu maklerskiego',
	esgInfolist: 'ESG infolist',
	esgSustainability: 'Oświadczenie w\u00a0zakresie zarządzania zrównoważonym rozwojem i\u00a0o\u00a0nieuwzględnianiu niekorzystnych skutków decyzji inwestycyjnych',
	legalRepresentativeDeclaration: 'Oświadczenie przedstawiciela ustawowego',
	pensionConfirmation: 'Potwierdzenie dla pracodawcy',
	tradingVenues: 'System obrotu',
	ordersExecution: 'Podsumowanie i\u00a0wnioski analizy jakości realizacji zleceń za\u00a0rok 2023',
};

const documentUrls = {
	conditions: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_warunki_umowy.pdf`,
	terms: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_warunki_korzystania_ze_strony_internetowej.pdf`,
	personalInfoAgreement: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_GDPR.pdf`,
	informationSheet: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_karta_informacyjna.pdf`,
	agreementTemplate: {
		standard: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_wzor_umowy.pdf`,
		child: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_umowa_nieletni.pdf`,
		pension: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_wzor_umowy.pdf`,
	},
	agreementTemplateCzech: {
		standard: `${process.env.PUBLIC_URL}/files/Fondee_Smlouva-vzor.pdf`,
		child: `${process.env.PUBLIC_URL}/files/Fondee_smlouva_nezletili.pdf`,
		pension: `${process.env.PUBLIC_URL}/files/Fondee_Smlouva-vzor.pdf`,
	},
	complaintsProcedure: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_procedura_reklamacyjna.pdf`,
	annualReport2021: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2021.pdf`,
	annualReportTemplates2021: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2021.xlsx`,
	annualReport2022: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2022.pdf`,
	annualReportTemplates2022: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2022.xlsx`,
	annualReport2023: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2023.pdf`,
	annualReportTemplates2023: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2023.xlsx`,
	esgInfolist: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_ESG_infolist.pdf`,
	esgSustainability: `${process.env.PUBLIC_URL}/files/pl/PL_Fondee_Oswiadczenie_o_nieuwzglednianiu.pdf`,
	feesIllustratedUrl: `${process.env.PUBLIC_URL}/files/pl/Fondee_Przykladowy_wplyw_oplat.pdf`,
	tradingVenues: `${process.env.PUBLIC_URL}/files/Seznam_prevodnich_mist.pdf`,
	ordersExecution: `${process.env.PUBLIC_URL}/files/Fondee_zavery_analyzy_provadeni_pokynu_2023.pdf`,
};

const email = {
	address: 'info@fondee.pl',
};

const emailUs = {
	title: 'Napisz na',
};

const emailVerification = {
	default: {
		title: 'Potwierdź adres e-mail',
		text: 'Dlaczego? Aby mieć pewność, że e-mail jest naprawdę Twój (i abyśmy nie pisali do kogoś innego :-)). Wystarczy w nim kliknąć w przycisk "zweryfikuj".',
		listTitle: 'Wiadomość e-mail nie dotarła, co mogę zrobić?',
		list: '<0>zajrzyj do spamu</0>'
			+ '<0>może wkradła się literówka?</0>'
			+ '<0>spróbuj zarejestrować się ponownie</0>'
			+ '<0>nic nie pomogło? Napisz do nas na <0>info@fondee.pl</0> lub skontaktuj się na czacie.</0>',
	},
	response: {
		button: 'Kontynuuj rejestrację',
		title: {
			error: 'Adres e-mail nie został zweryfikowany.',
			success: 'Adres e-mail został zweryfikowany pomyślnie.',
		},
		content: {
			error: 'Nie udało się zweryfikować adresu e-mail. Spróbuj zaktualizować stronę i\u00a0v\u00a0jeżeli nadal występują problemy, skontaktuj się z\u00a0nami na <0>$t(email.address)</0>.',
			success: 'Dziękujemy za weryfikację. Teraz możesz kontynuować\u00a0rejestrację.',
		},
	},
};

const error = {
	title: 'Coś poszło nie tak',
	subtitle: 'Przepraszamy, wystąpił błąd na stronie.',
	link: 'Powrót do strony głównej',
};

const error404 = {
	title: '404',
	subtitle: 'Przepraszamy, ale wymagana strona nie została odnaleziona.',
	link: 'Powrót do strony głównej',
};

const faq = {
	title: 'Jak i\u00a0kiedy odbywa się inwestowanie',
	search: 'Znajdź pytanie…',
};

const feesChart = {
	comparisonAnchor: 'Porównanie opłat',
	title: 'Jak wypadamy<0></0>w porównaniu z\u00a0funduszem inwestycyjnym?',
	subtitle: 'Fondee zaoszczędzi Ci tysiące',
	info: '<0>Przykład.</0>'
		+ '<0>Zakładamy średnią roczną opłatę w\u00a0wysokości <0>1,05%</0> w\u00a0przypadku Fondee i\u00a0<0>2,7%</0> '
		+ 'w\u00a0przypadku funduszu udziałowego.</0>'
		+ '<0>Przewidujemy średnią roczną stopę zwrotu dla portfela akcyjnego na poziomie <0>7%</0> w\u00a0obu przypadkach. '
		+ 'Po 15\u00a0latach będziesz mieć o\u00a05\u00a0320\u00a0zł więcej w\u00a0Fondee niż w\u00a0funduszu inwestycyjnym!</0>'
		+ '<0>Jeśli jesteś zainteresowany bardziej szczegółowym wpływem opłat na aprecjację, możesz zajrzeć <1>tutaj</1>.</0>',
	chart: {
		currencySuffix: '\u00a0zł',
		currencyThousandsSuffix: '\u00a0tys.\u00a0zł',
		years_one: '{{ count }} rok',
		years_few: '{{ count }} lata',
		years_many: '{{ count }} lat',
		curveLabel: 'fundusze inwestycyjne',
	},
};

const feesComparison = {
	title: 'Porównanie opłat dla portfela akcji',
	footerNote: '',
	footerTextNote1: 'Porównanie za marzec 2024 r.',
	footerTextNote2: 'Wartości zaczerpnięte z\u00a0Kluczowych Informacji dla Inwestorów dostępnych na stronie internetowej '
		+ 'każdego funduszu. Opłaty za wejście i\u00a0wyjście mogą być niższe lub zerowe, jeśli aktualny cennik dystrybutora jest '
		+ 'niższy niż opłaty zadeklarowane w\u00a0Kluczowych Informacjach dla Inwestorów.',
	footerTextNotePart2: 'Uściślając, średni roczny wskaźnik kosztów oblicza się w\u00a0następujący sposób: całkowity roczny '
		+ 'wskaźnik kosztów za ostatni rok zgodnie z\u00a0Kluczowymi Informacjami dla iInwestorów + (opłata za wejście + opłata '
		+ 'za wyjście odpowiadająca okresowi) / liczba lat inwestowania.',
	footerTextNote3: 'Obliczenia oparte są na wartości 10 000 zł i\u00a0zakładają, że klient utrzyma tę wartość portfela '
		+ 'przez cały okres trwania inwestycji. w\u00a0rzeczywistości wartość ta może wzrosnąć lub spaść, a opłaty odpowiednio '
		+ 'wzrosną lub spadną w\u00a0wartościach względnych.',
	footerTextNotePart3: 'Uściślając, opłaty za fundusze inwestycyjne są obliczane w\u00a0następujący '
		+ 'sposób: 10 000 zł * średni roczny wskaźnik kosztów * liczba lat inwestowania.',
	footerTextNote4: 'Czy ty też uważasz, że jest tu stanowczo za dużo gwiazdek? Zwykle tak jest w\u00a0przypadku funduszy '
		+ 'inwestycyjnych. Zawsze sprawdzaj wszystkie warunki korzystania z\u00a0usługi!',
};

const feesHero = {
	title: 'Opłaty? Niższe, niż się spodziewasz…',
	description: '…i\u00a0niż znasz z\u00a0innych miejsc. Więcej pieniędzy dla Ciebie! Z\u00a0Fondee dokładnie wiesz, ile płacisz.',
	info: 'Roczne opłaty pobierane przez <0>ETF</0> różnią się w\u00a0zależności od struktury portfela.\u00a0Przeciętnie wynoszą 0,15\u00a0%.',
	anchor: 'Porównanie z\u00a0funduszem inwestycyjnym',
	period: 'rocznie',
	list: [
		{
			title: 'Opłaty Fondee',
			value: '0,9\u00a0%',
		},
		{
			title: 'Opłaty za <0>ETF</0> średnio',
			value: '0,15\u00a0%',
		},
		{
			title: 'Łączne opłaty średnio',
			value: '1,05\u00a0%',
		},
	],
	benefitsList: [
		'Brak opłat wstępnych',
		'Brak ukrytych opłat',
		'Brak opłat bankowych',
		'Brak prowizji dla doradców',
	],
};

const feesProducts = {
	title: 'Ile kosztuje inwestowanie za pośrednictwem Fondee?',
	toggleOptions: [
		{
			label: 'Klasyczny',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
		{
			label: 'Dla dzieci',
			value: 'child',
		},
		{
			label: 'Emerytalny',
			value: 'pension',
		},
	],
	products: {
		child: {
			title: 'Konta dla dzieci',
			text: 'Jak zapewnić dzieciom bogatszą przyszłość? Nie pozwól, aby Twoje oszczędności leżały w banku, '
				+ 'gdzie tracą na wartości. Otwórz konto inwestycyjne dla dziecka <0>z\u00a0obniżoną opłatą</0>.',
			button: 'Chcę konto dla dziecka',
			transparent: {
				fondee: '0,2\u00a0%',
				etf: '0,15\u00a0%',
				total: '0,35\u00a0%',
				description: '<0>Opłata Fondee <0>0,2%</0> rocznie</0>'
					+ '<1>Ograniczyliśmy koszty inwestowania dla dzieci do minimum. Opłata obejmuje kompleksowe zarządzanie portfelem dziecka.</1>'
					+ '<0>Koszty ETF 0,15% rocznie</0>'
					+ '<1>Same ETF-y pobierają niewielkie opłaty - niezależnie od tego, czy inwestujesz w\u00a0nie przez Fondee, '
					+ 'czy gdzie indziej. Opłaty te nie są pobierane bezpośrednio z\u00a0wartości portfela, ale są uwzględnione w\u00a0cenie ETF-ów.</1>'
					+ '<0>Całkowity koszt 0,35% rocznie</0>'
					+ '<1>Utrzymując inwestycję do 18. urodzin dziecka, nie poniesiesz żadnych dodatkowych opłat.</1>',
			},
		},
		classic: {
			title: 'Szyte na miarę',
			text: 'Inwestuj <0>w\u00a0setki akcji i\u00a0obligacji z\u00a0całego świata</0> za pośrednictwem funduszy '
				+ 'ETF\u00a0- exchange-traded funds. W\u00a0sposób prosty, zautomatyzowany i\u00a0zrozumiały.',
			button: 'Chcę klasyczny portfel',
			transparent: {
				fondee: '0,9\u00a0%',
				etf: '0,15\u00a0%',
				total: '1,05\u00a0%',
				description: '<0>Opłata Fondee <0>0,9%</0> rocznie</0>'
					+ '<1>Z Fondee nic Cię nie zaskoczy. Pobieramy tylko jedną opłatę, która obejmuje pełne zarządzanie '
					+ 'Twoimi inwestycjami. Inwestuj taniej niż poprzez fundusze inwestycyjne!</1>'
					+ '<0>Koszty ETF 0,15% rocznie</0>'
					+ '<1>Same ETF-y pobierają niewielkie opłaty - niezależnie od tego, czy inwestujesz w\u00a0nie przez Fondee, czy '
					+ 'gdzie indziej. Opłaty te nie są pobierane bezpośrednio z\u00a0wartości portfela, ale są uwzględnione w\u00a0cenie ETF-ów.</1>'
					+ '<0>Całkowity koszt 1,05% rocznie</0>'
					+ '<1>Nie płacisz nic więcej. Nigdy.</1>',
			},
		},
		esg: {
			title: 'ESG',
			text: 'Inwestuj w\u00a0sposób zrównoważony dzięki portfelom ESG, które w\u00a0ostatnich latach pobiły klasyczne '
				+ 'inwestycje pod względem zysków. Przekonasz się, że <0>odpowiedzialność i\u00a0zysk idą w\u00a0parze</0>.',
			button: 'Chcę portfel ESG',
			transparent: {
				fondee: '0,9\u00a0%',
				etf: '0,15\u00a0%',
				total: '1,05\u00a0%',
				description: '<0>Opłata Fondee <0>0,9%</0> rocznie</0>'
					+ '<1>Z Fondee nic Cię nie zaskoczy. Pobieramy tylko jedną opłatę, która obejmuje pełne zarządzanie Twoimi '
					+ 'inwestycjami. Inwestuj taniej niż poprzez fundusze inwestycyjne!</1>'
					+ '<0>Koszty ETF 0,15% rocznie</0>'
					+ '<1>Same ETF-y pobierają niewielkie opłaty - niezależnie od tego, czy inwestujesz w\u00a0nie przez Fondee, czy '
					+ 'gdzie indziej. Opłaty te nie są pobierane bezpośrednio z\u00a0wartości portfela, ale są uwzględnione w\u00a0cenie ETF-ów.</1>'
					+ '<0>Całkowity koszt 1,05% rocznie</0>'
					+ '<1>Nie płacisz nic więcej. Nigdy.</1>',
			},
		},
		pension: {
			title: 'Portfele emerytalne',
			text: 'Przejmij kontrolę nad swoją przyszłością i\u00a0pożegnaj się z\u00a0drogimi funduszami emerytalnymi o\u00a0niskich zwrotach. Otwórz portfel emerytalny <0>z\u00a0obniżonymi opłatami</0>.',
			button: 'Chcę portfel emerytalny',
			transparent: {
				fondee: '0,5%',
				etf: '0,15%',
				total: '0,65%',
				description: '<0>Opłata Fondee 0,5%\u00a0rocznie</0>'
					+ '<1>Inwestycje na emeryturę tańsze niż w\u00a0banku? Warto skorzystać! Dotyczą kompleksowego zarządzania portfelem emerytalnym.</1>'
					+ '<0>Koszty ETF 0,15%\u00a0rocznie</0>'
					+ '<1>Same ETF pobierają niewielkie opłaty - niezależnie od tego, czy inwestujesz za pośrednictwem Fondee, czy gdzie indziej. Te opłaty nie są pobierane od wartości portfela, ale są zawarte w\u00a0cenie ETF.</1>'
					+ '<0>Całkowite koszty 0,65%\u00a0rocznie</0>'
					+ '<1>Jeśli wykorzystasz środki z\u00a0portfela emerytalnego po ukończeniu 60.\u00a0roku życia, ale nie wcześniej niż 10\u00a0lat po podpisaniu umowy, nie zapłacisz nic więcej.</1>',
			},
		},
	},
	transparent: {
		title: 'Transparentne opłaty:',
		fondee: 'Opłata Fondee',
		etf: 'Koszty ETF',
		total: 'Całkowity koszt',
	},
};

const findOutInfoBox = {
	text: 'Dowiedz się, który profil inwestycyjny pasuje do Twojego podejścia do\u00a0ryzyka. '
		+ 'Wynik znajdziesz w\u00a0kwestionariuszu inwestycyjnym w\u00a0pierwszym kroku rejestracji.',
	button: 'Chcę spróbować',
};

const footer = {
	primary: {
		nav: {
			title: 'Informacje',
			homepage: 'Wstęp',
			howItWorks: 'Jak to działa',
			ourApproach: 'Nasze podejście',
			fees: 'Opłaty',
			faq: 'Często zadawane pytania',
			contact: 'Zapytaj',
			documents: 'Dokumenty',
			cookies: 'Pliki cookies',
			blog: 'Blog',
			privacyPolicy: 'Ochrona danych osobowych',
		},
		products: {
			title: 'Produkty',
			classic: 'Klasyczne portfele',
			esg: 'Portfele ESG',
			children: 'Konta dla dzieci',
			pension: 'Portfele emerytalne',
		},
		contact: {
			title: 'Chcesz o coś zapytać?',
		},
		app: 'Pobierz aplikację mobilną',
		googlePlay: 'Teraz w\u00a0Google Play',
		appStore: 'Pobierz w\u00a0App Store',
		socialUrl: {
			facebook: 'https://www.facebook.com/FondeePolska',
			instagram: 'https://www.instagram.com/fondee_pl/',
			linkedin: 'https://www.linkedin.com/company/fondee-polska',
		},
	},
	secondary: {
		title: 'Fondee\u00a0a.s.',
		info: 'Wartość inwestycji może ulegać zmianom w\u00a0czasie, a zwrot z\u00a0zainwestowanej kwoty nie jest gwarantowany. '
			+ 'Inwestowanie wiążą się z\u00a0ryzykiem. Inwestowanie w\u00a0Fondee naraża inwestora przede wszystkim na ryzyko rynkowe '
			+ 'polegające na możliwości niekorzystnych zmian cen aktywów (akcji, obligacji itp.), ryzyko kredytowe, ryzyko '
			+ 'inflacji i\u00a0ryzyko niekorzystnych zmian kursów walutowych.',
		address: 'ID\u00a006691862<0></0>'
			+ 'Rumunská\u00a022/28,<0></0>'
			+ 'Vinohrady\u00a0-\u00a0Praga\u00a02, 120\u00a000<0></0>'
			+ 'Republika Czeska',
		legal: 'Fondee\u00a0a.s. została wpisana do\u00a0Rejestru Handlowego prowadzonego przez\u00a0Sąd Miejski '
			+ 'w\u00a0Pradze w\u00a0sekcji B pod pozycją 27322',
		love: 'Made with\u00a0<0></0>\u00a0Fondee',
	},
};

const footerCta = {
	title: 'Przyszłość? Stwórz własną!',
	text: 'Niech Twoje pieniądze pracują dla Ciebie.',
	button: 'Chcę inwestować',
};

const forgotPassword = {
	title: 'Resetowanie zapomnianego hasła',
	form: {
		text: 'Po wprowadzeniu adresu e-mail wyślemy Ci link umożliwiający zresetowanie hasła.',
		button: 'Wyślij link',
		success: 'Link do zresetowania starego hasła został właśnie wysłany na Twój adres e-mail.',
	},
	reset: 'Zmień hasło',
};

const forms = {
	error: 'Coś poszło nie tak. Prosimy spróbuj ponownie.',
	success: 'Zapisano',
	save: 'Zapisz',
	fields: {
		email: {
			label: 'E-mail',
			helper: 'Obiecujemy nie wysyłać zbędnego spamu',
			error: 'Zapomniałeś podać swój adres e-mail.',
			invalid: 'Wprowadzony adres e-mail jest nieaktualny. Sprawdź, czy nie zawiera literówek, nie brakuje znaku @, itp.',
			unique: 'Ten adres e-mail jest już zarejestrowany w\u00a0naszej bazie danych. Prosimy użyj innego adresu e-mail.',
			closed: 'Twój rachunek został zamknięty. Aby odzyskać swój rachunek, prosimy skontaktuj się z\u00a0<0>$t(email.address)</0>.',
		},
		esgPreferenceDeclaration: {
			error: 'Prosimy zaznaczyć to pole',
		},
		password: {
			label: 'Hasło',
			helper: 'Minimum 8 znaków, jedna wielka i\u00a0mała litera oraz cyfra',
			error: 'Zapomniałeś wypełnić swój adres e-mail.',
			incorrect: 'Niestety, to hasło jest nieprawidłowe.',
			invalid: 'Wpisane hasło nie spełnia warunków.',
		},
		passwordConfirm: {
			label: 'Potwierdź hasło',
			error: 'Zapomniałeś wypełnić potwierdzenie hasła.',
			invalid: 'Wpisane hasła nie są zgodne.',
		},
		oldPassword: {
			label: 'Pierwotne hasło',
			error: 'Zapomniałeś wypełnić pierwotne hasło.',
		},
		newPassword: {
			label: 'Nowe hasło',
			error: 'Zapomniałeś wypełnić nowe hasło.',
		},
		firstName: {
			label: 'Imię',
			error: 'Prosimy podaj swoje imię.',
			warning: 'Upewnij się, że wpisane imię jest poprawne. Jeżeli tak, kontynuuj.',
		},
		lastName: {
			label: 'Nazwisko',
			error: 'Prosimy podaj swoje nazwisko.',
			warning: 'Upewnij się, że wpisane nazwisko jest poprawne. Jeżeli tak, kontynuuj.',
		},
		fullName: {
			label: 'Imię',
			error: 'Prosimy podaj swoje imię i\u00a0nazwisko.',
		},
		phone: {
			label: 'Telefon',
			error: 'Prosimy nie zapomnij wpisać swój numer telefonu.',
			invalid: 'Podany numer telefonu ma nieprawidłowy format.',
		},
		phoneNumber: {
			label: 'Telefon',
			helper: 'Wpisz bez spacji',
			error: 'Zapomniałeś wypełnić numer telefonu.',
			invalid: 'Wpisany numer telefonu jest nieprawidłowy. Sprawdź, czy napisałeś go poprawnie i\u00a0bez spacji.',
			warning: 'Prosimy upewnij się, że wpisany numer jest poprawny.',
		},
		hasReferenceCode: {
			label: 'Mam kod referencyjny',
		},
		referenceCode: {
			label: 'Kod referencyjny',
			error: 'Zapomniałeś/aś wpisać kod referencyjny.',
			invalid: 'Nie znaleźliśmy tego kodu referencyjnego w\u00a0naszej bazie danych. Prosimy sprawdzić, czy kod referencyjny został wpisany poprawnie.',
		},
		personalInfoAgreed: {
			label: 'Wyrażam zgodę na przetwarzanie moich <0>danych osobowych</0>',
			error: 'Aby kontynuować, należy zaznaczyć, że wyrażasz zgodę na przetwarzanie danych osobowych.',
		},
		conditionsAgreed: {
			label: 'Zgadzam się z\u00a0<0>zasadami i\u00a0warunkami</0> Fondee',
			error: 'Aby kontynuować, należy zaznaczyć, że zapoznałeś się z\u00a0warunkami działalności Fondee.',
		},
		streetName: {
			label: 'Ulica',
			error: 'Nie wypełniono nazwy ulicy.',
			warning: 'Proszę sprawdź czy ulica jest poprawnie wpisana.',
		},
		streetNumber: {
			label: 'Nr\u00a0domu\u00a0/Nr\u00a0lok.',
			error: 'Nie wpisano numeru budynku.',
			warning: 'Sprawdź czy numer domu się zgadza.',
		},
		city: {
			label: 'Miasto',
			error: 'Nie wpisano nazwy miasta.',
		},
		postalCode: {
			label: 'kod pocztowy',
			nonNumeric: 'Nieprawidłowy format. Wpisz tylko cyfry.',
		},
		personalIdentificationNumber: {
			label: 'PESEL',
			error: 'Nieprawidłowy format. Wpisz tylko cyfry, bez ukośnika.',
			warning: 'Prosimy sprawdź, czy wpisany PESEL jest prawidłowy.',
		},
		birthDate: {
			label: 'Data urodzenia',
			nonNumeric: 'Zły format. Podaj tylko cyfry.',
			minority: 'Dziecko miało już 18 lat, konieczne jest utworzenie osobnej rejestracji',
			majority: 'Nie masz jeszcze 18 lat, przedstawiciel prawny musi dokonać rejestracji za ciebie',
			day: {
				label: 'Dzień',
				invalid: 'Wprowadź poprawny numer dnia.',
			},
			month: {
				label: 'Miesiąc',
				invalid: 'Wpisz poprawny numer miesiąca.',
			},
			year: {
				label: 'Rok',
				invalid: 'Wprowadź prawidłowy numer roku.',
			},
		},
		placeOfBirth: {
			label: 'Miejsce urodzenia',
			error: 'Nieprawidłowy format. Wpisz tylko litery lub cyfry.',
			warning: 'Sprawdź, czy wprowadzone miejsce urodzenia jest prawidłowe.',
		},
		bankAccount: {
			accountMask: '## #### #### #### #### #### ####',
			label: 'Rachunek bankowy',
			labelPrefix: 'Prefiks',
			labelAccount: 'Numer rachunku',
			labelCode: 'Kod banku',
			errorAccount: 'Zapomniałeś wypełnić numer rachunku.',
			errorCode: 'Zapomniałeś wypełnić kod banku.',
			invalidCode: 'Nieprawidłowy kod banku.',
			length: 'Prosimy sprawdzić, czy numer rachunku ma 26\u00a0znaków.',
			nonNumeric: 'Nieprawidłowy format. Wpisz tylko cyfry.',
		},
		bankAccountIBAN: {
			label: 'IBAN',
			error: 'Zapomniałeś wypełnić numer IBAN rachunku.',
			invalid: 'Numer IBAN rachunku powinien zawierać wyłącznie cyfry i\u00a0litery. Długość nie powinna przekroczyć 34\u00a0znaków.',
			warning: 'Prosimy sprawdzić, czy numer IBAN rachunku ma 28\u00a0znaków.',
		},
		statementPeriodicity: {
			monthly: 'Miesięcznie',
			quarterly: 'Kwartalnie',
			yearly: 'Rocznie',
			none: 'Nigdy',
		},
		survey: {
			label: 'Wyświetlać kwestionariusze w\u00a0strefie klienta',
		},
		marketingEmailsUnsubscribe: {
			label: 'Rezygnacja z\u00a0subskrypcji e-mailowych wiadomości marketingowych',
		},
		nationality: {
			label: 'Narodowość',
			error: 'Zapomniałeś podać narodowość.',
		},
		rebalancingActive: {
			label: 'Włączenie możliwości automatycznego rebalansowania portfela',
		},
		toggle: {
			local: 'Rachunek lokalny',
			iban: 'IBAN',
		},
		address: {
			change: 'Adres stałego zamieszkania',
		},
		contactAddress: {
			label: 'Adres kontaktowy',
		},
		addressChange: {
			change: 'Chcę zmienić swój stały adres zamieszkania',
			confirm: 'Oświadczam, że podany przeze mnie adres jest prawidłowy',
		},
		businessId: {
			warning: 'Wpisany numer REGON jest nieprawidłowy.',
		},
		incomeExpense: {
			warning: 'Sprawdź, czy podana kwota jest poprawna i podaj ją jako pełną kwotę w\u00a0polskich złotych (PLN).',
		},
		child: {
			firstName: {
				label: 'Imię dziecka',
				error: 'Proszę wpisać imię dziecka.',
				warning: 'Proszę sprawdzić, czy wprowadzone imię dziecka jest poprawne.',
			},
			lastName: {
				label: 'Nazwisko dziecka',
				error: 'Proszę wpisać nazwisko dziecka.',
				warning: 'Proszę sprawdzić poprawność wpisanego nazwiska dziecka.',
			},
			email: {
				label: 'E-mail (opcjonalnie)',
				unique: 'Podany e-mail jest już zarejestrowany w naszej bazie danych. Jeśli dziecko nie posiada własnego adresu e-mail, możesz pozostawić to pole puste.',
				warning: 'Sprawdź, czy wprowadzony adres e-mail jest poprawny.',
			},
			phone: {
				label: 'Telefon (opcjonalnie)',
				warning: 'Proszę sprawdzić, czy wprowadzony numer telefonu jest poprawny.',
			},
			referenceCode: {
				label: 'Kod referencyjny (opcjonalnie)',
				invalid: 'Nie znaleźliśmy tego kodu referencyjnego w\u00a0naszej bazie danych. Prosimy sprawdzić, czy kod referencyjny został wpisany poprawnie.',
				tooltip: 'Informujemy, że możliwe jest wprowadzenie jedynie kodów jednorazowych bonusów finansowych. Bonusy zwalniające z\u00a0opłat są uwzględniane poprzez konto rodzica i\u00a0nie mogą być wprowadzane tutaj.',
			},
		},
		file: {
			deleteAttachment: 'Usuń załącznik',
			sizeError: 'Maksymalny rozmiar pliku to {{ value }} MB.',
		},

	},
	twoFactorAuthentication: {
		title: 'Uwierzytelnianie dwuskładnikowe',
		text: 'Wpisz kod weryfikacyjny z\u00a0Twojej aplikacji',
		button: 'Wpisz kod',
		error: 'Nieprawidłowy kod',
	},
};

const general = {
	copied: 'Skopiowano',
	esgWarningModal: {
		title: 'Ostrzeżenie',
		text: 'Oferowane portfele ESG mogą nie odpowiadać Twoim preferencjom.',
		button: 'Przyjmuję do wiadomości',
	},
};

const header = {
	blog: 'Blog',
	clientCheck: 'Regularne kontrole',
	contact: 'Zapytaj',
	dashboard: 'Panel sterowania',
	fees: 'Opłaty',
	history: 'Historia',
	howItWorks: 'Jak to działa',
	login: 'Zaloguj się',
	logout: 'Wyloguj',
	menu: 'Menu',
	myAccount: 'Moje konto',
	offers: 'Coś więcej',
	onboarding: 'Dokończenie rejestracji',
	ourApproach: 'O nas',
	ownPortfolio: 'Portfel',
	portfolio: 'Portfel',
	products: 'Produkty',
	productsList: {
		classicProduct: {
			title: 'Inwestycje szyte na miarę',
			subTitle: 'Odpowiednie dla każdego inwestora',
		},
		esgProduct: {
			title: 'Zrównoważone inwestycje',
			subTitle: 'Natura ma najwyższy priorytet',
		},
		childProduct: {
			title: 'Konta inwestycyjne dla dzieci',
			subTitle: 'Z myślą o przyszłości moich dzieci',
		},
		pensionProduct: {
			title: 'Inwestycje na emeryturę',
			subTitle: 'Zabezpiecz się na jesień życia',
		},
		button: 'Wróć',
	},
	referrals: 'Polecenia',
	register: 'Chcę inwestować',
	signAgreement: 'Podpisanie umowy',
	signNewAgreement: 'Podpisanie umowy',
	taxes: 'Podatki 2023',
};

const historyChart = {
	loading: 'Pobieram dane dla tej strony',
	legend: {
		title: 'Legenda',
		evaluation: 'Aprecjacja',
		devaluation: 'Deprecjacja',
		value: 'Łączna wartość portfela',
		increase: 'Wpłata / Bonus / Dywidenda',
		decrease: 'Wypłata / Opłata',
	},
	tooltip: {
		value: 'Wartość',
		evaluation: 'Zmiany wartości portfela',
		nonGrowthPlus: 'Wpłaty/bonusy',
		nonGrowthMinus: 'Wypłaty/przeniesienia między portfelami',
		growthPlus: 'Dywidendy/zwroty',
		growthMinus: 'Opłaty',
	},
	toggle: {
		value: 'Wartość',
		evaluation: 'Aprecjacja',
	},
};

const historyFilter = {
	aggregation: 'Po',
	fromDate: 'Od',
	toDate: 'Do',
	last: 'Ostatnich ',
	days: 'dni',
	beginning: 'Początek',
	collapse: 'Zmień zakres danych',
};

const historyOverview = {
	deposits: {
		title: 'Wpłaty',
		tooltip: 'Suma wszystkich wpłat i\u00a0ewentualnych premii za wybrany\u00a0okres.',
	},
	withdrawals: {
		title: 'Wypłaty',
		tooltip: 'Suma wszystkich wypłat za wybrany\u00a0okres.',
	},
	appreciation: {
		title: 'Aprecjacja',
		tooltip: 'Kształtowanie się wartości portfela w\u00a0wybranym okresie skorygowane o\u00a0wpłaty, wypłaty i\u00a0premie.',
	},
};

const historyProgress = {
	title: 'Kształtowanie się wartości portfela',
	downloadPdf: 'Pobierz w\u00a0PDF',
	noResult: 'Nic do\u00a0wyświetlenia',
	table: {
		columns: {
			date: 'Data',
			externalTransactionTotal: 'Wpłata/wypłata/premia',
			internalTransactionTotal: 'Bonus/opłata/dywidenda',
			value: 'Wartość portfela',
			valueTooltip: 'Wartość portfela stanowi łączną wartość posiadanych produktów i\u00a0gotówki '
				+ 'przeliczone na wybraną walutę po kursie dostępnym w\u00a0danej chwili. Wartość ETF jest obliczana '
				+ 'na podstawie cen ETF obowiązujących na koniec dnia obrotu, ewentualnie najbliższej dostępnej ceny.',
			valueProgress: 'Kształtowanie się wartości portfela od dnia poprzedniego',
			valueProgressTooltip: 'Kształtowanie się wartości portfela wyraża zmiany wartości zakupionych produktów '
				+ 'i\u00a0zmiany kursów walut. Obrót produktami odbywa się w\u00a0euro, na ich aktualną wartość '
				+ 'mogą więc mieć wpływ zmiany kursów walutowych. Zmiana wartości portfela jest obliczana jako różnica wartości '
				+ 'portfela w\u00a0stosunku do dnia poprzedniego bez zaliczenia wpłat, wypłat, bonusów i\u00a0i\u00a0opłat.',
		},
		rows: {
			cashOver: 'Udział gotówki skorygowany na',
			for: 'za',
			investorProfile: 'Profil inwestycyjny:',
			skipRebalancing: 'Wyłączone rebalansowanie',
			transfer: 'Przeniesienie',
			fromPortfolio: 'z portfela',
			toPortfolio: 'do portfela',
			rate: 'Kurs:',
		},
		loadMore: 'Pokaż inne ruchy',
	},
};

const historyTransactions = {
	title: 'Przegląd transakcji',
	downloadPdf: 'Pobierz w\u00a0PDF',
	text: 'Szczegóły dotyczące\u00a0sposobu wyświetlania transakcji',
	textTooltip: 'Data realizacji transakcji jest zgodna z\u00a0datą zaksięgowania u\u00a0brokera.',
	noResult: 'Brak transakcji do\u00a0wyświetlenia',
	filter: {
		placeHolder: 'Filtr transakcji',
		noOption: 'Brak filtrów',
	},
	table: {
		columns: {
			date: 'Data',
			type: 'Typ zmiany',
			change: 'Zmiana',
		},
		rows: {
			more: 'Więcej',
			hide: 'Ukryj',
			trading: 'Obrót',
			for: 'za',
			fromPortfolio: 'z portfela',
			toPortfolio: 'do portfela',
			rate: 'Kurs:',
		},
		loadMore: 'Pokaż inne ruchy',
	},
};

const homepageAbout = {
	title: 'Co to jest Fondee?',
	text: 'Chcesz pomnażać swoje wolne pieniądze w\u00a0akcjach i\u00a0obligacjach? '
		+ 'Możesz wybierać spośród 7\u00a0modelowych portfeli, które łączą akcje i\u00a0obligacje o różnym ukierunkowaniu. '
		+ 'Następnie po prostu wysłać wpłatę, którą chcesz zainwestować, a\u00a0my zajmiemy się wszystkimi\u00a0zleceniami '
		+ 'i\u00a0utrzymywaniem portfela zgodnie z\u00a0wybranym przez Ciebie modelem\u00a0portfela.',
	button: 'Chcę dowiedzieć się więcej',
};

const homepageHero = {
	title: 'Nie zaoszczędzisz na przyszłość.',
	subtitle: 'Inwestuj!',
	text: 'Wypełnij kwestionariusz inwestycyjny, a Fondee zaproponuje Ci spersonalizowany portfel. Inwestuj automatycznie '
		+ '<0>już od 100 zł</0>. Z\u00a0niskimi opłatami i\u00a0całkowicie online.',
	aboutButton: 'Dowiedz się więcej',
	productsButton: 'Nasze produkty',
	future: {
		title: 'Nikt nie zna przyszłości.',
		subtitle: 'Każdy może się zabezpieczyć',
		text: 'Pomnażaj swoje pieniądze długoterminowo. Prosto, przejrzyście i\u00a0z\u00a0niskimi opłatami.',
	},
};

const homepageJT = {
	text: '<0>Zyskaliśmy zaufanie J&T Banku, który wsparł finansowo Fondee i\u00a0przekazał nam cenne doświadczenia branżowe. '
		+ 'Dziękujemy!</0>'
		+ '<0>Dzięki temu silnemu partnerstwu finansowemu możemy nieustannie doskonalić naszą platformę inwestycyjną i\u00a0'
		+ 'pracować nad wprowadzaniem nowych funkcji dla naszych inwestorów.</0>',
};

const homepageProducts = {
	title: 'Jak pomnożysz swoje pieniądze?',
	button: 'Więcej informacji',
	list: [
		{
			title: 'Inwestycje szyte na miarę',
			text: '<0>Nie wystarczy oszczędzać na przyszłość. Wystarczy jednak jeden przelew bankowy do Fondee i\u00a0możesz '
				+ 'inwestować <1>w setki akcji i\u00a0obligacji z\u00a0całego świata</1> - jednorazowo lub regularnie i\u00a0już od 100 zł.\n</0>'
				+ '<0>Bez zbędnych opłat i\u00a0skomplikowanych warunków. W\u00a0zależności od Twoich celów i\u00a0nastawienia do ryzyka, '
				+ 'zaproponujemy Ci portfel złożony z\u00a0ETF. Twoje depozyty <1>automatycznie zainwestujemy</1>, więc nie musisz '
				+ 'się o nic martwić.</0>',
			button: 'Chcę klasyczny portfel',
		},
		{
			title: 'Zrównoważone inwestycje',
			text: '<0>Inwestuj odpowiedzialnie i\u00a0zbuduj zrównoważony portfel złożony z\u00a0funduszy ETF, <1>które wyróżniają się '
				+ 'w ratingach ESG</1>. Oceniają one wyniki inwestycji w\u00a0zakresie ochrony środowiska (E), spraw społecznych (S) '
				+ 'i ładu korporacyjnego (G).</0>'
				+ '<0>Wybierz spośród <1>7 portfeli ESG</1>, które w\u00a0ostatnich latach pokonały tradycyjne inwestycje. Przekonasz się, że '
				+ '<1>nie musisz rezygnować ze swoich wartości, stawiając na zysk</1>.</0>',
			button: 'Chcę portfel ESG',
		},
		{
			title: 'Inwestowanie dla dzieci',
			text: '<0>Jak zabezpieczyć finansowo najmłodszych? Dobrze, że pytasz, <1>podziękują Ci w\u00a0przyszłości</1>! '
				+ 'Kilkaset złotych inwestycji dla dzieci teraz = niezła sumka w\u00a0dorosłym życiu.</0>'
				+ '<0>Załóż swoim dzieciom konto inwestycyjne z\u00a0<1>obniżoną opłatą.</1></0>',
			button: 'Chcę konto dla dziecka',
		},
		{
			title: 'Emerytalne Konto Inwestycyjne',
			text: '<0>Weź przyszłość <1>w\u00a0swoje ręce</1>! Nie polegaj na państwie i\u00a0pożegnaj się '
				+ 'z\u00a0drogimi funduszami emerytalnymi o\u00a0niskich zwrotach.</0>'
				+ '<0>ałóż Emerytalne Konto Inwestycyjne i\u00a0odkładaj na emeryturę łatwo, <1>z\u00a0obniżoną opłatą</1> '
				+ 'i\u00a0rozsądniejszym zyskiem w\u00a0porównaniu do\u00a0tradycyjnych produktów bankowych.</0>',
			button: ' Chcę portfel emerytalny',
		},
	],
};

const homepageServices = {
	list: [
		{
			title: 'Łatwo',
			text: 'Konto otworzysz <0>online za pomocą kilku kliknięć</0> i\u00a0w\u00a0ciągu kilku minut możesz zacząć pomnażać swoje '
				+ 'pieniądze. Obserwuj na stronach lub w\u00a0aplikacji, jak radzi sobie Twoja inwestycja.',
		},
		{
			title: 'Mądrze',
			text: 'Krótki kwestionariusz podpowie Ci, jakim jesteś inwestorem - mamy ofertę zarówno dla <0>odważnych, jak '
				+ 'i ostrożnych</0>. Masz więcej celów finansowych? Otwórz nawet 5 portfeli jednocześnie!',
		},
		{
			title: 'Bezpiecznie',
			text: 'Podczas gdy my dbamy o Twoje inwestycje, <0>Czeski Bank Narodowy</0> dba o nas, a Fundusz Gwarancyjny '
				+ 'Dealerów Papierów Wartościowych chroni Twoje pieniądze.',
		},
		{
			title: 'Bez gwiazdek*',
			text: 'Inwestuj <0>tyle, ile chcesz</0>. Możesz rozstać się z\u00a0nami w\u00a0dowolnym momencie - załatwisz to online, a '
				+ 'pieniądze w\u00a0ciągu tygodnia znajdą się na Twoim koncie. Nie stosujemy gwiazdek ani wyjątków.'
				+ '*Naprawdę!',
		},
	],
	button: 'Chcę wiedzieć, jak to działa',
};

const howItWorksHero = {
	title: 'Jak to działa',
	description: 'Wszystko, co musisz wiedzieć o inwestowaniu z\u00a0nami',
	list: [
		{
			name: 'Wypełnij kwestionariusz',
			description: 'Poznajmy się na początek. My jesteśmy Fondee, a Ty? Dzięki <0>krótkiej ankiecie</0> dowiemy się o Twoich '
				+ 'celach inwestycyjnych i\u00a0Twoim podejściu do ryzyka.',
		},
		{
			name: 'Wybierz portoflio',
			description: 'Nie martw się, nie będziesz zdany na siebie. Zaproponujemy Ci <0>odpowiedni rodzaj strategii</0> na '
				+ 'podstawie odpowiedzi udzielonych w\u00a0kwestionariuszu inwestycyjnym.',
		},
		{
			name: 'Wyślij depozyt',
			description: 'Aby zakończyć rejestrację, wystarczy wysłać pierwszą płatność. Możesz zainwestować już <0>od 100 zł</0> '
				+ 'jednorazowo lub regularnie.',
		},
		{
			name: 'Inwestuj bez obaw',
			description: 'Fondee <0>kupuje fundusze <0>ETF</0></0> za Twoje depozyty i\u00a0zapewnia, że portfel zawsze odpowiada '
				+ 'wybranej przez Ciebie alokacji.',
		},
		{
			name: 'Wypłać swoje pieniądze',
			description: 'Możesz sprzedać swoją inwestycję w\u00a0dowolnym momencie i\u00a0<0>mieć pieniądze na koncie w\u00a0ciągu tygodnia</0>. '
				+ 'Opłaty za wyjście? A co to takiego?',
		},
	],
	anchor: 'Przejrzeć portfele',
};

const howItWorksInvestment = {
	title: 'Inwestuj ze spokojem ducha',
	list: [
		{
			title: 'Bezpiecznie',
			description: 'Inwestycje są przechowywane u brokera J&T Bank na tak zwanym <0>rachunku klienta</0>, który nie jest '
				+ 'częścią masy upadłościowej. Są <0>łatwo dostępne</0> w\u00a0przypadku bankructwa, więc są bezpieczne, '
				+ 'nawet jeśli my lub broker zbankrutujemy.',
		},
		{
			title: 'W sposób kontrolowany',
			description: 'Fondee jest regulowane przez <0>Czeski Bank Narodowy</0>. Ponadto Twoje inwestycje są prawnie chronione '
				+ 'przez Fundusz Gwarancyjny Dealerów Papierów Wartościowych <0>do 90% wartości</0> (maksymalnie do 20 000 EUR).',
		},
		{
			title: 'Profesjonalnie',
			description: 'Portfele są obsługiwane przez <1>zespół ekspertów</1> z\u00a0wieloletnim doświadczeniem zdobytym w\u00a0trakcie '
				+ 'studiów ekonomicznych na prestiżowych uniwersytetach oraz podczas pracy w\u00a0światowych instytucjach finansowych. '
				+ 'Krótko mówiąc, jesteś <0>w dobrych rękach</0>.',
		},
	],
};

const howItWorksPrinciples = {
	title: '4 zasady inteligentnego inwestowania',
	list: [
		{
			title: 'Inwestowanie jest jak kolejka górska',
			text: 'Raz na górze, raz na dole. Inwestowanie wiąże się z\u00a0ryzykiem i\u00a0zwrotów nie można zagwarantować. '
				+ 'Fondee nie nadaje się do krótkoterminowych spekulacji. Inwestujemy dla zysku i\u00a0wierzymy, że wartość '
				+ 'inwestycji będzie rosła w\u00a0długim okresie, ale może również spaść z\u00a0powodu pogorszenia koniunktury '
				+ 'na rynkach akcji i\u00a0obligacji. Inwestuj tylko wtedy, gdy jesteś gotowa/y <0>podjąć ryzyko</0>.',
		},
		{
			title: 'To, co było wczoraj, nie musi być dziś',
			text: 'A historyczne zwroty nie są gwarancją przyszłych zwrotów. Nasze portfele inwestycyjne osiągnęły pewną '
				+ 'aprecjację w\u00a0przeszłości, ale <0>nie ma gwarancji, że poradzą sobie równie dobrze w\u00a0przyszłości</0>. Zwroty '
				+ 'mogą być wyższe lub niższe. Ani my, ani nikt inny nie ma kryształowej kuli - a kiedy ktoś obiecuje Ci '
				+ 'gwarantowany zysk, wodzi Cię za nos.',
		},
		{
			title: 'Im niższe opłaty, tym więcej pieniędzy dla Ciebie',
			text: 'Pamiętaj, aby zawsze sprawdzać opłaty, <0>ponieważ obniżają one wartość inwestycji</0>. Nawet niewielka różnica '
				+ 'ma w\u00a0długim horyzoncie czasu duże znaczenie. Fondee działa w\u00a0sposób zautomatyzowany, online, bez oddziałów, '
				+ 'doradców i\u00a0bankierów. Oszczędzamy, obniżając koszty i\u00a0dajemy Ci <0>jedne z\u00a0najniższych opłat na rynku</0>.',
		},
		{
			title: 'Kurs interesuje podróżników i\u00a0inwestorów.',
			text: 'Na zysk z\u00a0Twojej inwestycji wpływa również <0>kurs walutowy</0>. Większość ETF-ów w\u00a0naszych portfelach '
				+ 'jest notowana w\u00a0euro, a niektóre zawierają również pozycje w\u00a0dolarach. Kursy walutowe <0>stale się '
				+ 'zmieniają, co ma wpływ na wartość Twojego portfela</0> - dlatego inwestycja w\u00a0Fondee może zarówno wzrastać, '
				+ 'jak i\u00a0spadać z\u00a0powodu zmian kursów walutowych.',
		},
	],
};

const howItWorksRebalancing = {
	title: 'Czym jest rebalansowanie portfela?',
	description: '<0>Każdy portfel Fondee ma określony udział procentowy akcji i\u00a0obligacji. Ceny akcji i\u00a0obligacji '
		+ 'zmieniają się codziennie i\u00a0dlatego z\u00a0czasem struktura Twojego portfela może <0>różnić się od pierwotnej, '
		+ 'wybranej przez Ciebie struktury</0>. Na przykład nie będzie Ci odpowiadać, że inwestycje zawierają więcej akcji, niż chcesz.</0>'
		+ '<0>Rebalansowanie zapewnia co miesiąc <0>automatyczną modyfikację portfela, aby jego struktura nadal odpowiadała '
		+ 'wybranym proporcjom</0>. Usługę rebalansowania możesz wyłączyć - wtedy nowe depozyty będą wykorzystywane do zakupu '
		+ 'funduszy ETF w\u00a0takich proporcjach, aby odpowiadały docelowemu rozkładowi portfela.</0>',
	chart: {
		portfolioValueLabel: 'Wartość portfela',
		portfolioToday: 'Dzisiaj',
		portfolioAfterMonth: 'Po miesiącu',
		portfolioAfterRebalancing: 'Po Rebalansowaniu',
		portfolioValueToday: '10 000,-',
		portfolioValueAfterMonth: '11 000,-',
		portfolioValueAfterRebalancing: '11 000,-',
		bond: 'Obligacje',
		share: 'Akcje',
		etf: 'ETF',
		why: 'Dlaczego?',
		how: 'Jak?',
		tooltipWhy: 'Ceny akcji i\u00a0obligacji zmieniają się codziennie. W\u00a0tym przypadku ceny ETF akcyjnych wzrosły o\u00a020\u00a0% i\u00a0ceny ETF obligacyjnych pozostały bez zmian.',
		tooltipHow: 'Sprzedaż 5\u00a0% portfela posiadanego w\u00a0ETF akcyjnych i\u00a0zakup ETF obligacyjnych.',
	},
};

const howItWorksSchema = {
	title: 'Jak to dokładnie\u00a0działa?',
	chart: {
		broker: 'Broker',
		client: 'Klient',
		money: {
			title: 'Pieniądze',
			tooltip: 'Peniądze na\u00a0inwestycje są wysyłane z\u00a0rachunku bieżącego klienta na rachunek zbiorczy'
				+ 'u\u00a0brokera. Wypłata pieniędzy odbywa się również bezpośrednio z\u00a0rachunku u\u00a0brokera z\u00a0powrotem '
				+ 'do\u00a0Ciebie',
		},
		portfolio: {
			title: 'Wybór portfela',
			tooltip: 'Wybierasz jeden z\u00a0modelowych portfeli. Fondee o\u00a0ten portfel '
				+ 'dba i\u00a0przekazuje zlecenia\u00a0zakupu lub sprzedaży ETF\u00a0do brokera.',
		},
		trading: {
			title: 'Realizacja zlecenia',
			tooltip: 'Fondee przekazuje brokerowi zlecenia do\u00a0realizacji. Pieniądze są inwestowane '
				+ 'zawsze drugiego dnia handlowego po ich\u00a0przyjęciu.',
		},
	},
	broker: {
		title: 'Jaka jest rola brokera?',
		text: 'Fondee posiada licencję Narodowego Banku Czeskiego na\u00a0prowadzenie działalności jako dom '
			+ 'maklerski, ale sam nie uczestniczy w\u00a0obrocie na\u00a0giełdzie papierów wartościowych, gdzie '
			+ 'odbywa się obrót. Dlatego połączyliśmy siły z\u00a0brokerem, za którego pośrednictwem kupujemy '
			+ 'i\u00a0sprzedajemy papiery wartościowe dla naszych\u00a0klientów.',
	},
};

const investorProfileAvatar = {
	modal: {
		title: 'Zmień grafikę portfela',
		submit: 'Zmień grafikę',
		cancel: 'Zostaw istniejącą grafikę',
	},
};

const investorProfileChoice = {
	title: 'Jaki profil inwestycyjny do mnie pasuje?',
	text: 'Dany profil inwestycyjny jest oparty na wynikach kwestionariusza inwestycyjnego. Swój profil inwestycyjny możesz '
		+ 'w każdej chwili w\u00a0przyszłości zmienić w\u00a0Twojej strefie klienta.',
	riskTitle: 'Twoja tolerancja ryzyka',
	submit: 'Potwierdź wybór',
	redo: 'Chcę sprawdzić odpowiedzi zawarte w\u00a0kwestionariuszu inwestycyjnym',
	tooltip: {
		classic: 'Jest to\u00a0nasza tradycyjna oferta, która nie uwzględnia kryteriów ESG.',
		esg: 'Inwestycje ESG uwzględniają wpływ na środowisko, aspekty polityki społecznej i\u00a0odpowiedzialne zarządzanie firmą.',
	},
	category: {
		classic: 'Jedna lokata = setki akcji i obligacji z całego świata. Inwestuj w fundusze ETF online, prosto i przejrzyście.',
		esg: 'Wartości i zyski rozumieją się nawzajem. Inwestuj w sposób zrównoważony w portfele, które w ostatnich latach '
			+ 'pokonują tradycyjne inwestycje.',
	},
	littleExperience: {
		title: 'Inwestowanie z\u00a0Fondee nie jest dla Ciebie odpowiednie, ponieważ masz niewielką wiedzę i\u00a0doświadczenie '
			+ 'w\u00a0inwestowaniu.',
		message: 'Jeżeli chcesz przeczytać więcej o\u00a0tym, czym jest ETF i\u00a0jak działa Fondee, '
			+ 'zajrzyj <0>tutaj</0>.',
		abort: 'Zakończyć rejestrację',
		reset: 'Chcę zmienić niektóre odpowiedzi',
	},
	notSuitable: {
		title: 'Uwaga',
		message: 'Przykro nam, ale na podstawie Twoich odpowiedzi nie możemy w\u00a0tej chwili zaoferować Ci naszych usług.',
		text: 'Inwestowanie w\u00a0Fondee nie jest odpowiednie dla krótkoterminowych spekulacji lub innych krótkoterminowych '
			+ 'celów. Każda inwestycja niesie ze sobą ryzyko utraty części lub całości zainwestowanego kapitału, a\u00a0produkty '
			+ 'przeznaczone do inwestowania krótkoterminowego są zwykle bardziej ryzykowne, aby zapewnić pożądany zysk. '
			+ 'Nasze usługi są przeznaczone dla średnio- i\u00a0długoterminowego inwestowania w\u00a0zdywersyfikowane produkty.',
		back: 'Powrót do kwestionariusza',
		abort: 'Zakończyć rejestrację',
	},
	notRecommended: {
		title: 'Ostrzeżenie',
		message: '<0>Ten profil inwestycyjny nie jest dla Ciebie odpowiedni zgodnie z\u00a0oceną kwestionariusza inwestycyjnego. '
				+ 'Z\u00a0inwestowaniem na\u00a0rynkach kapitałowych wiąże się ryzyko, które jak wynika z\u00a0Twoich '
				+ 'odpowiedzi, najwyraźniej nie możesz lub nie chcesz podjąć.</0><0>Jeżeli w\u00a0trakcie wypełniania kwestionariusza '
				+ 'inwestycyjnego, podałeś informacje, które nie są zgodne z\u00a0rzeczywistością, lub omyłkowo zaznaczyłeś nieprawidłową odpowiedź, '
				+ 'możesz ponownie wypełnić kwestionariusz inwestycyjny.</0>',
		clientMessage: '<0>Ten profil inwestycyjny nie jest dla Ciebie odpowiedni zgodnie z\u00a0oceną kwestionariusza inwestycyjnego. '
				+ 'Z\u00a0inwestowaniem na\u00a0rynkach kapitałowych wiąże się ryzyko, które jak wynika z\u00a0Twoich '
				+ 'odpowiedzi, najwyraźniej nie możesz lub nie chcesz podjąć.</0>',
		close: 'Chcę ponownie wypełnić kwestionariusz',
		clientClose: 'Powrót wstecz',
		submitAbort: 'Nie chcę kontynuować rejestracji',
		tooltip: 'Przepraszamy, ale wykorzystałaś/eś limit wypełnień kwestionariusza inwestycyjnego. '
			+ 'Jeśli potrzebujesz dokonać dalszych zmian, skontaktuj się z\u00a0naszym działem obsługi klienta.',
	},
	notConsistent: {
		title: 'Ostrzeżenie',
		text: 'Przepraszamy, ale niektóre z\u00a0Twoich odpowiedzi dotyczących długości horyzontu inwestycyjnego '
			+ 'są sprzeczne. Możesz kliknąć przycisk, aby wrócić do kwestionariusza i\u00a0poprawić swoje odpowiedzi.',
	},
};

const investorProfileDetail = {
	title: 'Skonfiguruj swoją idealną inwestycję',
	subtitle1: 'Dla siebie czy dla dzieci? Więcej akcji, obligacji czy pół na pół? Klasycznie czy "zielono"? '
		+ '<0>Wszystko zależy od Ciebie!</0> Wybierz produkt, rodzaj inwestycji i\u00a0poziom ryzyka - my zaproponujemy odpowiedni portfel.',
	investorPostfix: 'inwestor',
	showProducts: 'Zobacz przybliżony rozkład funduszy ETF w\u00a0portfelu',
	productsTitle: 'Alokacja ETF w\u00a0portfelu',
	productToggle: 'Chcę mieć konto inwestycyjne:',
	portfolioToggle: 'Typ portfela:',
	selectionTitle: 'Użyj suwaka, aby dostosować poziom ryzyka',
	ranger: {
		info: 'Ogólnie obowiązuje, że gotowość do ponoszenia wyższego ryzyka i\u00a0dłuższy czas inwestowania, '
			+ 'który przezwycięży krótkoterminowe turbulencje, prowadzi również do\u00a0wyższych zysków',
		less1: 'Niższe\u00a0ryzyko',
		less2: 'Niższy\u00a0zwrot',
		more1: 'Wyższe\u00a0ryzyko',
		more2: 'Wyższy\u00a0zwrot',
		recommend: 'Na podstawie wypełnionego przez Ciebie kwestionariusza inwestycyjnego oceniliśmy profile w\u00a0<0>zielonym</0> przedziale '
				+ 'jako odpowiednie dla Ciebie.',
		recommendWarning: 'Według oceny zawartej w kwestionariuszu inwestycyjnym profile w\u00a0szarym przedziale nie są dla '
				+ 'Ciebie odpowiednie.',
	},
	profileAccountOptions: [
		{
			label: 'Dla siebie albo dla dziecka',
			value: 'both',
		},
	],
	profileTypeOptions: [
		{
			label: 'Klasyczny',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
	],
};

const investorProfilePreview = {
	toggleLabel: 'Wybierz typ portfela:',
	classicPortfolios: 'Klasyczne',
	esgPortfolios: 'ESG',
	walletTitle: 'Twoje oszczędności',
	sliderTitle: 'inwestor',
	chartOverlayTitle: 'Historyczne zwroty',
	chartOverlayPeriod: 'rocznie',
	chartTooltip: 'Średnia roczna aprecjacja portfela w\u00a0latach 2014-2024 w\u00a0koronach czeskich. Obliczenia oparte są na '
		+ 'symulowanym historycznym rozwoju opartym na cenach poszczególnych ETF lub ich indeksów bazowych. Chodzi o '
		+ 'o\u00a0zyski brutto przed zaliczeniem opłat.',
	disclaimer: {
		text: 'Historyczną stopę zwrotu obliczamy jako średni roczny wzrost wartości portfela w\u00a0latach 2014-2024 w\u00a0'
			+ 'złotych, a obliczenia bazują na symulowanych zmianach historycznych opartych na cenach '
			+ 'poszczególnych funduszy ETF lub ich indeksach bazowych. Powyższe zwroty z\u00a0naszych portfeli są obliczane '
			+ 'przed uwzględnieniem opłat Fondee (0,9% rocznie) i\u00a0wewnętrznych kosztów ETF (0,15%).',
	},
	aboutButton: 'Dowiedz się więcej o tym produkcie',
};

const login = {
	title: 'Zaloguj się',
	text: 'W ten sposób przejdziesz do strefy klienta',
	button: 'Zaloguj się',
	forgotPassword: 'Nie pamiętasz hasła',
	social: {
		twoFactorTitle: 'Logowanie za pośrednictwem sieci\u00a0społecznościowej',
		or: 'Lub',
		title: 'Zaloguj się przez:',
	},
};

const media = {
	title: 'Fondee w\u00a0mediach',
};

const meta = {
	title: 'Fondee',
	titleExtended: 'Fondee | Robimy z\u00a0pieniędzy więcej pieniędzy',
	titleSuffix: ' - Fondee',
	description: 'Dołącz do inwestorów nowej generacji. Nie pozwól, aby pieniądze leżały na kontach, na których '
		+ 'tracą na wartości. Zainwestuj je, pozwól im zarabiać na siebie.',
	og: {
		title: 'Fondee',
		description: 'Nie pozwól, aby pieniądze leżały na kontach, na których tracą na wartości. Zainwestuj je, pozwól im zarabiać na siebie.',
		referralTitle: 'Fondee – z\u00a0kodem bonusowym',
		referralDescription: 'Zacznij inwestować z\u00a0bonusem od znajomego.',
	},
	titles: {
		account: 'Moje konto',
		childProduct: 'Inwestowanie dla dzieci',
		classicProduct: 'Inwestycje szyte na miarę',
		clientCheck: 'Regularne kontrole',
		contact: 'Zapytaj',
		cookies: 'Cookies',
		dashboard: 'Panel sterowania',
		documents: 'Dokumenty',
		emailAlias: 'Łączenie kont',
		emailVerification: 'Weryfikacja e-maila',
		esgProduct: 'Inwestycje ESG',
		faq: 'Często zadawane pytania',
		fees: 'Opłaty',
		forgotPassword: 'Odzyskanie hasła',
		history: 'Historia',
		howItWorks: 'Jak to działa',
		login: 'Logowanie',
		offers: 'Coś więcej',
		onboarding: 'Dokończenie rejestracji',
		onboardingChild: 'Rejestracja dziecka',
		onboardingParent: 'Rejestracja rodzica',
		ourApproach: 'Nasze podejście',
		pensionProduct: 'Inwestycje na emeryturę',
		portfolio: 'Portfel',
		privacyPolicy: 'Ochrona danych osobowych',
		referrals: 'Polecenia',
		registration: 'Rejestracja',
		resetPassword: 'Zmiana hasła',
		signAgreement: 'Podpisanie umowy',
		signNewAgreement: 'Podpisanie umowy',
		taxes: 'Podatki 2023',
		unsubscribe: 'Wypisanie się z newslettera',
	},
};

const offers = {
	title: 'Co może ci się przydać?',
	text: 'Połączyliśmy się z\u00a0kilkoma czeskimi start-upami nie tylko z\u00a0branży finansowej i\u00a0przedstawiamy Ci wskazówki dotyczące ich usług.',
	code: 'Kod referencyjny',
};

const onboarding = {
	title: 'Witaj w\u00a0Fondee!',
	text: 'Teraz czeka Cię nudny, ale niezbędny proces rejestracji.\nO\u00a0tyłe łatwiejsze będzie później zarządzanie Twoimi inwestycjami.',
	info: 'Aby otworzyć dla Ciebie rachunek inwestycyjny, musimy Cię lepiej poznać. Obiecujemy, że będzie to szybsze, niż '
		+ 'w banku.\nPytamy o wszystko, ponieważ jesteśmy instytucją finansową regulowaną przez Czeski Bank Narodowy. '
		+ 'Szyfrujemy Twoje dane i\u00a0przechowujemy je bezpiecznie w\u00a0naszym systemie wewnętrznym.',
	steps: {
		product: {
			title: 'Wybór produktu',
			childInfo: 'Zanim utworzymy nowe konto dla Twojego dziecka, musimy najpierw utworzyć konto dla Ciebie. Zdajemy '
				+ 'sobie sprawę, że jest to trochę uciążliwe, ale musimy mieć pewność, że wszystko jest w\u00a0porządku.',
		},
		profileType: {
			title: 'Typ portfela',
		},
		questionnaires: {
			title: 'Kwestionariusze',
			childTitle: 'Kwestionariusze dziecka',
			AML: 'Kwestionariusz AML',
			ESG: 'Kwestionariusz ESG',
			investment: 'Kwestionariusz inwestycyjny',
		},
		amlQuestionnaire: {
			title: 'Kwestionariusz AML',
		},
		questionnaire: {
			title: 'Kwestionariusz',
			active: 'Kwestionariusz inwestycyjny pomoże nam (i\u00a0Tobie) dowiedzieć się, który portfel jest odpowiedni dla Ciebie. Rodzaj portfela możesz później '
				+ 'zmienić w\u00a0dowolnym momencie lub założyć inny.',
			activeChild: 'Wypełniasz kwestionariusz inwestycyjny w\u00a0imieniu dziecka. Jeśli to możliwe, uzupełnij odpowiedzi '
				+ 'zgodnie z\u00a0sytuacją Twojego dziecka. Może to na przykład dotyczyć celów inwestycyjnych lub sytuacji finansowej. '
				+ 'I\u00a0odwrotnie, pytania dotyczące doświadczenia i\u00a0wiedzy wypełnij tak, aby odnosiły się do Ciebie.',
			signAgreementActive: 'Kwestionariusz inwestycyjny jest nieco obszerniejszy i\u00a0zawiera kilka nowych pytań. To nie '
				+ 'dlatego, że chcemy uzyskać od Ciebie więcej informacji, ale dlatego, że jesteśmy do tego zobowiązani przez przepisy prawa i\u00a0poszerzone '
				+ 'przepisy bezpieczeństwa, które teraz powinniśmy spełniać. Wszystkie odpowiedzi są oczywiście poufne i\u00a0objęte '
				+ 'regulacjami w\u00a0zakresie ochrony danych osobowych.',
			signNewAgreementActive: {
				standard: 'Umowa reguluje kwestie związane z\u00a0inwestowaniem w\u00a0Fondee. W\u00a0załącznikach '
					+ 'znajdziesz Warunki umowy, Cennik oraz Regulamin reklamacyjny.',
				pension: 'Umowa reguluje kwestie związane z\u00a0inwestowaniem w\u00a0Fondee. W\u00a0załącznikach '
					+ 'znajdziesz Warunki umowy, Cennik oraz Regulamin reklamacyjny.',
			},
			done: 'Gotowe, kwestionariusz został pomyślnie wypełniony! Możesz zmienić swój profil inwestycyjny lub utworzyć inny w\u00a0dowolnym momencie po '
				+ 'dokończenie rejestracji.',
			continue: 'Przejdź do następnego kroku',
			select: 'Wybierz z możliwości',
			agree: 'Rozumiem',
			amlTitle: 'I\u00a0jeszcze mamy kilka pytań dotyczących pochodzenia pieniędzy, które chcesz zainwestować. Zaczynajmy.',
			years_one: '{{ count }} rok i\u00a0mniej',
			years_few: '{{ count }} lata',
			years_many: '{{ count }} lat i\u00a0więcej',
			ratio_min: 'Mniej niż 10\u00a0%',
			currency: 'zł',
			cancelModal: {
				title: 'Szanujemy Twoją decyzję.',
				text: 'Jeżeli chcesz powrócić do\u00a0inwestowania z\u00a0Fondee, skontaktuj się z nami bez wahania '
					+ 'za pośrednictwem <0>$t(email.address)</0> lub czatu na\u00a0tej stronie internetowej.',
				return: 'Anuluj zlecenie zamknięcia konta',
				logout: 'Wyloguj',
			},
			americanModal: {
				title: 'Ostrzeżenie',
				text: 'Przykro nam, ale w\u00a0chwili obecnej nie jesteśmy w\u00a0stanie zaoferować Ci naszych usług. Jeżeli w\u00a0przyszłości ta '
					+ 'sytuacja się zmieni, damy Ci znać. Jeżeli nie życzysz sobie, abyśmy się z Tobą kontaktowali, możesz anulować swoją rejestrację, korzystając z poniższego linka. '
					+ 'W\u00a0przypadku jakichkolwiek pytań, skontaktuj się z nami pod adresem info@fondee.pl.',
				link: 'Anulować rejestrację',
				button: 'Poinformujcie mnie',
				thanks: 'Dziękujemy za Twoje zainteresowanie\u00a0Fondee!',
			},
			esgRatioModal: {
				title: 'Ostrzeżenie',
				text: '<0>W Fondee mamy teraz w\u00a0ofercie zrównoważonego inwestowania tzw. „A8\u00a0produkt“ (udział w\u00a0portfelu 100\u00a0%).</0>'
					+ '<0>Co to znaczy? A8\u00a0zawiera fundusze wspierające środowisko, kwestie społeczne i\u00a0ład korporacyjny.</0>'
					+ '<0>Jeżeli jesteś zainteresowany/a\u00a0innym typem zrównoważonej inwestycji niż A8, nie możemy Ci niestety w\u00a0chwili obecnej niczego zaproponować.</0>'
					+ '<1>To trochę dziwne, ale pomimo\u00a0tego, że obecnie oferujemy tylko jedną kategorię inwestycji ESG (A8), '
					+ 'mamy obowiązek zapytać Cię o Twoje preferencje. Więcej na temat\u00a0uregulowań w\u00a0zakresie zrównoważonych inwestycji i\u00a0Ustawy o regulacyjnych normach technicznych '
					+ 'możesz przeczytać w\u00a0artykule <0>”Wszystko o europejskich regulacjach ESG”</0></1>'
					+ '<0>Swoje odpowiedzi możesz w\u00a0każdej chwili zmienić.</0>',
				button: 'Wstecz',
				continue: 'Rozumiem i\u00a0chcę kontynuować',
			},
			esgFinalQuestion: {
				title: 'Ostrzeżenie',
				text: '<0>Twoje preferencje inwestycyjne w\u00a0odniesieniu do inwestycji ESG nie są zgodne z portfelami oferowanymi obecnie przez Fondee '
					+ '(A8\u00a0produkt ze 100\u00a0% udziałem).</0>'
					+ '<0>Fondee ESG portfele wspierają zrównoważony rozwój w\u00a0kwestiach środowiska i\u00a0kwestiach społecznych pod warunkiem '
					+ 'prawidłowego działania firmy.</0>'
					+ '<0>Jeżeli preferujesz inny rodzaj inwestycji ESG niż A8\u00a0produkt, nie możemy zaoferować Ci żadnego produktu inwestycyjnego. '
					+ 'Możesz korzystać z usług Fondee w\u00a0przypadku, jeżeli zmienisz swoje preferencje dotyczące zrównoważonego rozwoju.</0>'
					+ '<0>Jeżeli zdecydujesz się to zrobić, prosimy podać powód podjęcia takiej\u00a0decyzji. Jeżeli chcesz wrócić '
					+ 'do kwestionariusza i\u00a0zmienić swoje odpowiedzi, kontynuuj używając przycisku niżej.</0>',
				button: {
					continue: 'Chcę zmienić moje preferencje ESG tak, aby moim wyborem był produkt A8 ze 100\u00a0% udziałem, i\u00a0inwestować '
						+ 'z\u00a0Fondee do ESG produktów.',
					change: 'Chcę wrócić do kwestionariusza ESG i\u00a0zmienić moje odpowiedzi',
					cancel: 'Zakończyć rejestrację w\u00a0Fondee',
				},
				declaration: '<0>Niniejszym oświadczam, że zostałem poinformowany przez spółkę Fondee, iż w\u00a0chwili obecnej nie może ona '
					+ 'świadczyć usługi inwestycyjnej w\u00a0sposób odpowiadający moim preferencjom w\u00a0zakresie zrównoważonych inwestycji.</0>'
					+ '<0>Na podstawie tych informacji postanowiłem/am zmienić swoje preferencje i\u00a0dokonać wyboru spośród\u00a0produktów Fondee.</0>',
			},
			logout: 'Dziękujemy za Twoje zainteresowanie\u00a0Fondee!',
		},
		documents: {
			title: 'Weryfikacja twojej tożsamości',
			childTitle: 'Weryfikacja tożsamości dziecka',
			error: 'Przepraszamy, wystąpił błąd podczas weryfikacji tożsamości.',
			redirect: {
				title: 'Weryfikuj przez Veriff',
				text: 'Veriff to nasz partner, za pośrednictwem którego przeprowadzisz weryfikację w\u00a0ciągu kilku '
					+ 'minut. Zalecamy, aby ten proces wykonać na telefonie komórkowym. Więcej wskazówek znajdziesz '
					+ 'pod poniższym znakiem zapytania.',
				listTitle: 'Można przesyłać następujące typy dokumentów:',
				list: '<0>Dowód osobisty</0>'
					+ '<0>Paszport</0>'
					+ '<0>Prawo jazdy</0>',
				listChild: '<0>Dowód osobisty</0>'
					+ '<0>Paszport</0>',
			},
			upload: {
				title: 'Weryfikacja ręczna',
				text: 'Ręczna weryfikacja jest przeprowadzana przez Fondee i może zająć kilka godzin.',
				frontSide: 'Przednia strona',
				backSide: 'Tylna strona',
				list: {
					formats: '<0>Obsługiwane formaty:</0>'
						+ '<1>PNG, JPG</1>',
					size: '<0>Wielkość:</0>'
						+ '<1>Maksymalna wielkość 4MB</1>',
				},
				fileTypes: {
					birthCertificate: 'Akt urodzenia',
					drivingLicense: 'Prawo jazdy',
					identification: 'Dowód osobisty',
					passport: 'Paszport',
				},
				submit: 'Potwierdź',
			},
			tooltip: '<0>Kilka wskazówek odnośnie wykonania udanego zdjęcia:</0>'
				+ '<1><0>zezwól na użycie kamery w\u00a0komputerze lub telefonie</0>'
				+ '<0>zapewnij dobre światło</0></1>'
				+ '<0>Czego należy unikać:</0>'
				+ '<1><0>odbijaniu światła na dokumencie</0>'
				+ '<0>złej ostrości</0>'
				+ '<0>fotografowania w\u00a0ciemnym pomieszczeniu</0></1>'
				+ '<0>Jeżeli wypróbowałeś wszystkie powyższe wskazówki i\u00a0nadal masz problemy z weryfikacją swojej tożsamości, poinformuj nas o tym '
				+ 'za pośrednictwem czatu lub e-maila na adres info@fondee.pl. Chętnie Ci pomożemy.</0>',
		},
		childData: {
			title: 'Rejestracja dziecka',
			form: {
				personalIdentificationNumber: {
					title: 'Numer PESEL dziecka',
					text: 'Proszę wpisać numer PESEL dziecka',
				},
				bankAccount: {
					title: 'Numer konta dziecka (opcjonalnie)',
				},
			},
		},
		personalData: {
			title: 'Kontrola danych osobowych',
			active: 'Tutaj sprawdź i\u00a0uzupełnij swoje dane.',
			form: {
				name: {
					title: 'Imię',
					text: 'Rachunek inwestycyjny zarejestrujemy na nazwisko:',
				},
				address: {
					title: 'Adres stałego zamieszkania',
				},
				contactAddress: {
					add: 'Chcę podać inny adres kontaktowy',
					title: 'Adres kontaktowy',
					confirm: 'Oświadczam, że podany przeze mnie adres jest prawidłowy',
				},
				birthDate: {
					title: 'Data urodzenia',
				},
				placeOfBirth: {
					title: 'Miejsce urodzenia',
				},
				nationality: {
					title: 'Narodowość',
				},
				personalIdentificationNumber: {
					title: 'PESEL',
					text: 'Prosimy podaj swój PESEL.',
				},
				bankAccount: {
					title: 'Numer rachunku',
					text: 'Aby sparować płatność z\u00a0Twoim rachunkiem inwestycyjnym, musimy znać numer Twojego rachunku bankowego, '
						+ 'z którego będziesz wysyłać pieniądze.',
					warning: 'Należy pamiętać, że rachunek powinien być prowadzony na Twoje <0>nazwisko</0>. Dlatego <0>nie akceptujemy usług płatności stron '
						+ 'trzecich,</0> takich jak np. Wise, Revolut czy\u00a0Monese.',
					childWarning: 'Jeśli Twoje dziecko posiada własny rachunek bankowy, możesz podać go tutaj. W\u00a0takiej sytuacji konto '
						+ 'powinno być zarejestrowane na nazwisko dziecka. W\u00a0związku z\u00a0tym, niemożliwe jest korzystanie z\u00a0usług '
						+ 'takich jak Wise, Revolut czy\u00a0Monese.',
					ibanText: 'Jeżeli chcesz dodać <0>rachunek zagraniczny</0>, należy go wprowadzić w\u00a0formacie IBAN',
				},
				confirm: {
					label: 'Potwierdzam, że informacje, które podałem/am wyżej, są prawdziwe i\u00a0w chwili obecnej aktualne.',
					error: 'Należy je zaznaczyć, aby kontynuować.',
				},
				submit: 'Potwierdzam',
			},
		},
		agreement: {
			title: 'Podpisanie umowy',
			active: 'Jesteśmy już prawie na na\u00a0końcu. Umowa o\u00a0świadczenie usług inwestycyjnych pozwoli nam zainwestować Twoje pieniądze zgodnie z '
				+ 'wybranym przez Ciebie profilem inwestycyjnym.',
			signAgreementActive: 'W związku z przejściem do\u00a0nowego brokera, w\u00a0umowie wprowadzono kilka pojęć '
				+ 'legislacyjnych i\u00a0drobnych zmian. w\u00a0rzeczywistośc dla Ciebie i\u00a0nic się nie zmienia, Fondee będzie nadal inwestować Twoje '
				+ 'pieniądze zgodnie z wybranym profilem. Nic\u00a0więcej.',
			download: 'Pobierz umowę w\u00a0formacie PDF',
			timeOutText: 'Po 20\u00a0minutach braku aktywności wylogujemy Cię ze względów\u00a0bezpieczeństwa. Jeżeli '
				+ 'potrzebujesz więcej czasu, aby zapoznać się z\u00a0umową, nie ma sprawy. Po ponownym zalogowaniu możesz '
				+ 'kontynuować\u00a0rejestrację od miejsca, w\u00a0którym skończyłeś/aś.',
			formAgreement: {
				infoText: 'Przygotowaliśmy dla Ciebie umowę. Pobierz ją, uważnie przeczytaj i\u00a0zaakceptuj '
					+ 'niżej. W\u00a0celu weryfikacji tożsamości wyślemy Ci kod\u00a0SMS.',
				signAgreementInfoText: 'Przygotowaliśmy dla Ciebie nową umowę. Pobierz ją, uważnie przeczytaj i\u00a0zaakceptuj '
					+ 'niżej. W\u00a0celu weryfikacji tożsamości wyślemy Ci kod\u00a0SMS.',
				readAgreement: {
					label: 'Przeczytałem/am Umowę',
					blocked: 'Najpierw prosimy o przeczytanie umowy.',
					required: 'Należy potwierdzić, że umowę przeczytałeś/aś.',
				},
				smsSend: 'Wyślij kod',
				smsBlocked: 'Wysłaliśmy kod weryfikacyjny. Jeżeli potrzebujesz nowy, prosimy zaczekaj minutę.',
				smsText: 'Na numer <0>{{ phoneNumber }}</0> wyślemy Ci SMS z\u00a0kodem weryfikacyjnym, za pomocą którego podpiszesz umowę.',
				phoneChange: {
					text: 'Jeżeli wpisałeś/aś nieprawidłowy numer telefonu, możesz go <0>tutaj</0> zmienić. Po zapisaniu nowego numeru ponownie '
						+ 'kliknij w\u00a0przycisk Wyślij kod. Nowy kod do podpisania umowy można wysłać po upływie 1\u00a0minuty.',
					modal: {
						title: 'Zmiana numeru telefonu',
						placeholder: 'Nowy numer telefonu',
						button: 'Zapisz',
					},
				},
				legalRepresentative: {
					label: 'Jestem przedstawicielem ustawowym',
					download: 'Pobierz oświadczenie przedstawiciela ustawowego',
				},
			},
			formCode: {
				code: {
					label: 'Wpisz kod weryfikacyjny',
					invalid: 'Kod weryfikacyjny ma 6 znaków',
					error: 'Zapomniałeś/aś wpisać kod weryfikacyjny',
				},
				error: 'Wpisany kod jest nieprawidłowy. Prosimy, sprawdź go lub poproś o nowy. Jeżeli masz problemy z podpisem, skontaktuj się pod <0>$t(email.address)</0>.',
				submit: 'Podpisz umowę',
			},
		},
		payment: {
			title: 'Wyślij pierwszą płatność',
			active: 'Pozostaje tylko wysłać pierwszą płatność i\u00a0cała rejestracja zostanie zakończona!',
			info: '<0>Ile?</0>'
				+ '<1><0>Ile będziesz chciał/a</0>'
				+ '<0>Od 100 zł wpłatę zainwestujemy</0>'
				+ '<0>Od 1000 zł stworzymy dla Ciebie dobrze zdywersyfikowany portfel</0></1>',
			sentMoney: {
				label: 'Potwierdzam, że wysłałem wstępną płatność zgodnie z powyższymi wskazówkami',
				error: 'Aby kontynuować, należy wysłać płatność.',
			},
			emailInfo: 'O\u00a0przyjęciu wpłaty i\u00a0jej zainwestowaniu poinformujemy Cię e-mailem.',
			submit: 'Dokończ rejestrację',
		},
	},
	final: {
		title: 'Rejestracja dokończona!',
		payment: {
			title: 'Oczekujemy na realizację płatności',
			text: 'Czekamy teraz na uznanie Twojej pierwszej wpłaty na rachunku inwestycyjnym\u00a0brokera. '
				+ 'To nastąpi prawdopodobnie w\u00a0następnym dniu roboczym. '
				+ 'Potem od razu zainwestujemy pieniądze zgodnie z wybranym przez Ciebie profilem inwestycyjnym. '
				+ 'Przed zainwestowaniem sprawdzimy jeszcze wszystkie wymogi dotyczące prania brudnych pieniędzy nałożone na nas przez prawo. '
				+ 'Jeśli będziemy potrzebować więcej informacji, skontaktujemy się z Tobą.',
		},
		profile: {
			title: 'Czy chcesz zmienić swój profil inwestycyjny?',
			text: 'Jeśli chcesz zmienić swój profil inwestycyjny jeszcze przed pierwszą inwestycją, możesz to zrobić po aktywacji '
				+ 'Twojego konta w\u00a0części \u201eMoje konto\u201c. Dalsze zmiany w\u00a0profilu przejawią się zawsze przy najbliższym rebalansowaniu portfela.',
		},
		topUp: {
			title: 'Nie jesteś pewien/pewna, czy pierwszą płatność wysłałeś/aś prawidłowo?',
			text: 'Jeżeli wszystkie kroki rejestracji zostały wykonane ponad dwa dni robocze temu, prosimy o sprawdzenie, czy '
				+ ' pierwsza płatność z\u00a0rachunku {{ account }} została wysłana w\u00a0porządku. Jeżeli nie, wyślij płatność ponownie postępując zgodnie ze wskazówkami <0>tutaj</0>.',
			newAccount: 'Jeżeli przypadkiem wysłałeś/aś płatność weryfikacyjną z\u00a0innego rachunku, możesz go dodać za pomocą poniższego przycisku. '
				+ 'Te same warunki mają zastosowanie do wszystkich innych rachunków, więc muszą być prowadzone na Twoje nazwisko w\u00a0instytucji bankowej z '
				+ 'siedzibą w\u00a0UE. Następnie znajdziemy wysłaną płatność i\u00a0sparujemy ją z\u00a0Twoim nowym rachunkiem.',
		},
		agreement: {
			title: 'Nie pamiętasz, gdzie pobrałeś umowę?',
			text: 'Twoja umowa jest gotowa do pobrania w\u00a0części <0>Dokumenty</0>.',
		},
		contact: {
			title: 'Czy coś jeszcze chcesz się dowiedzieć?',
			text: 'Napisz do nas na czat w\u00a0prawym dolnym rogu lub wyślij e-maila na adres <0>$t(email.address).</0>',
		},
		feedback: {
			title: 'Będziemy wdzięczni za informację zwrotną',
			done: 'Dziękujemy za informację zwrotną!',
			form: {
				intro: 'Daj nam znać, jak jesteś zadowolony/a z procesu rejestracji:',
				statusGood: 'Całkowicie zadowolony/a',
				statusBad: 'Całkowicie niezadowolony/a',
				message: 'I\u00a0możesz nam coś przekazać również pisemnie:',
				submit: 'Wyślij informację zwrotną',
			},
		},
	},
	profilePreview: {
		text: 'Wybrany profil inwestycyjny:',
		info: 'Profil inwestycyjny można kiedykolwiek w\u00a0przyszłości zmienić po dokończeniu rejestracji.',
	},
};

const ourApproachAccount = {
	title: 'Eva i\u00a0Honza również inwestują z Fondee',
	text: 'Tak wygląda ich rzeczywisty portfel',
};

const ourApproachHero = {
	title: 'O nas',
	text: 'Dowiedz się, kto stoi za Fondee',
	list: [
		{
			title: '15\u00a0000+ klientów, 3\u00a0kraje',
			text: '15\u00a0000+ klientów z\u00a03\u00a0krajów aktywnie z\u00a0nami inwestuje. Powierzyli Fondee swoje portfele i\u00a0pomnażają '
				+ 'pieniądze w\u00a0sposób prosty, przejrzysty i\u00a0z\u00a0niskimi opłatami. Dołączysz do nich?',
		},
		{
			title: 'Nadzór Czeskiego Banku Narodowego',
			text: 'Podobnie jak inne czeskie instytucje finansowe, jesteśmy również nadzorowani przez Czeski Narodowy Bank. Wraz z\u00a0Funduszem '
				+ 'Gwarancyjnym Maklerów Papierów Wartościowych zapewnia, że Twoje pieniądze są u\u00a0nas bezpieczne.',
		},
		{
			title: 'Współpraca z J&T Bankiem',
			text: 'J&T Bank widzi w\u00a0nas potencjał. Wspiera Fondee finansowo oraz dzieląc się swoim doświadczeniem, '
				+ 'przyczynia się do szybszego rozwoju nowoczesnej aplikacji inwestycyjnej, której u\u00a0nas brakowało.',
		},
	],
	anchor: 'Kogo wybrałbyś/wybrałabyś',
};

const ourApproachTeam = {
	title: 'Kogo wybierzesz, aby dbał o\u00a0Twoje finanse?',
	team: {
		eva: {
			name: 'Eva Hlavsová',
			text: '<0>Może kogoś, kto studiował <1>ekonomię w\u00a0Cambridge</1> i\u00a0Instytucie Eonomicznych Studiów Uniwersystetu '
				+ 'Karola w\u00a0Pradze i\u00a0ma wieloletnie doświadczenie jako <1>wiceprezes ds. analizy ryzyka inwestycyjnego</1> '
				+ 'w Morgan Stanley? Kogoś, kto pracował w\u00a0Ministerstwie Skarbu i\u00a0Europejskim Banku Odbudowy i\u00a0Rozwoju?</0>'
				+ '<0>Eva, założycielka Fondee, ma takie doświadczenia.</0>',
			link: 'Profil Evy na\u00a0LinkedIn',
		},
		honza: {
			name: 'Honza Hlavsa',
			text: '<0>Albo kogoś, kto studiował <1>ekonomię na LSE w\u00a0Londynie</1> i\u00a0Instytucie Ekonomicznych Studiów '
				+ 'Uniwersytetu Karola w\u00a0Pradze, przez wiele lat pracował w\u00a0finansach w\u00a0City of London i\u00a0opiekował się '
				+ 'portfelami <1>o wartości miliardów dolarów</1>?</0>'
				+ '<0>Takie jest doświadczenie Honzy, założyciela Fondee.</0>',
			link: 'Profil Honzy na LinkedIn',
		},
		pavel: {
			name: 'Pavel Štěpánek',
			text: '<0>A co Pavel? Pełnił funkcję członka zarządu <1>Czeskiego Banku Narodowego</1>, dyrektora <1>Czeskiego '
				+ 'Stowarzyszenia Bankowego</1>, członka zarządu Europejskiego Banku Odbudowy i\u00a0Rozwoju oraz wiceministra finansów. '
				+ 'Obecnie jest między innymi wykładowcą na Uniwersytecie Ekonomicznym.</0>'
				+ '<0>Wniósł do Fondee <1>ponad 30 lat doświadczenia</1> w\u00a0finansach i\u00a0pomaga nam podążać we właściwym kierunku.</0>',
			link: 'Profil Pavla na\u00a0LinkedIn',
		},
	},
};

const portfolio = {
	overview: {
		valueTitle_one: 'Łączna wartość portfela',
		valueTitle_few: 'Łączna wartość portfeli',
		valueTitle_many: 'Łączna wartość portfeli',
		netGrowthTitle: 'Zmiany wartości',
		netGrowthTitleTooltip: 'Zmiana wartości portfela bez wpłat, wypłat i\u00a0premii od Fondee.',
		netGrowthRatioTitle: 'Zmiany wartości w\u00a0%',
		netGrowthRatioTitleTooltip: 'Procentowa zmiana wartości portfela jest obliczana jako\u00a0stosunek wartości aktualnej '
			+ 'portfela do kwoty wkładu, po odliczeniu wypłat i\u00a0premii. Jeżeli w\u00a0tym okresie było kilka wpłat, wypłat lub '
			+ 'premii, zaliczana jest ich wartość ważona zgodnie z tym, kiedy miały one miejsce. Na przykład, jeżeli zaczynałeś/aś sześć miesięcy temu '
			+ 'z\u00a0inwestycją 10\u00a0tysięcy\u00a0zł, wczoraj wpłaciłeś/aś kolejnych 10\u00a0tysięcy\u00a0zł i\u00a0aktualna wartość '
			+ 'Twojego portfela wynosi 21\u00a0tysięcy zł, zmiana wartości będzie wynosić około 10\u00a0%, ponieważ nowe wpłacone '
			+ '10\u00a0tysięcy\u00a0zł jeszcze nie zdążyło pomnożyć.',
		noActivePortfolio: 'Nie masz aktywnego portfela',
	},
	select: {
		title: 'Mój portfel',
		button: 'Załóż portfel',
		disabledTooltip: 'Użytkownik zostanie aktywowany po sprawdzeniu danych i\u00a0dokonaniu pierwszej wpłaty.',
		back: 'Powrót',
		archive: {
			button: 'Wyświetl zarchiwizowane portfele',
			title: 'Lista archiwizowanych portfeli',
			name: 'Nazwa portfela',
			select: 'Wyświetl',
			back: 'Powrót do\u00a0portfela',
		},
	},
	new: {
		modal: {
			title: 'Wybierz typ portfela',
			subtitle: 'Możesz utworzyć portfolio emerytalne.',
			input: 'Nazwa portfela',
			nameTooltip: 'Nazwa portfela służy wyłącznie dla lepszej orientacji.',
			childInfo: 'Jeśli nie zakończysz konfiguracji portfela dla dziecka, możesz powrócić do tego procesu w\u00a0dowolnym '
				+ 'momencie, przechodząc do sekcji "Moje konto" -\u00a0"Moje dzieci".',
			add: 'Załóż portfel',
			close: 'Zamknij',
			currency: 'Waluta wyświetlenia',
			currencyTooltip: 'Jest to waluta, w\u00a0której chcesz zobaczyć wartość swojego portfela na\u00a0stronie Portfel i\u00a0Historia.'
				+ 'Możesz zmienić wyświetlaną walutę w\u00a0dowolnym momencie i\u00a0nie ma to wpływu na\u00a0strukturę portfela.',
			additional: 'Ustawienia dodatkowe',
			rebalancingActive: 'Włączenie możliwości automatycznego rebalansowania',
			rebalancingTooltip: 'Zaznacz to pole, jeżeli chcesz, abyśmy automatycznie rebalansowali Twój portfel raz w\u00a0miesiącu '
				+ 'zgodnie z wybranym profilem inwestycyjnym. Wybór można zmienić w\u00a0dowolnym momencie w\u00a0ustawieniach profilu. '
				+ 'Ta opcja nie ma wpływu na\u00a0zainwestowanie wpłat i\u00a0realizację wypłat.',
			newConsent: 'Akceptuję nowe <0>warunki</0> otwarcia konta emerytalnego.',
			errorClosure: 'Nie możesz utworzyć nowego portfela, ponieważ zleciłeś/aś zamknięcie konta. Możesz anulować '
				+ 'zamknięcie konta w\u00a0sekcji <0>Moje konto</0>.',
			warning: {
				limit: {
					title: 'Nie można utworzyć nowego portfela',
					text: 'Przepraszamy, ale maksymalna liczba portfeli na jednym koncie użytkownika wynosi 5.',
				},
				limitChild: {
					title: 'Nie można utworzyć nowego portfela',
					text: 'Przepraszamy, ale maksymalna liczba portfeli na konto dziecka wynosi 3.',
				},
				limitPension: {
					title: 'Nie można utworzyć nowego portfela',
					text: 'Obecnie możliwe jest posiadanie tylko jednego konta emerytalnego.',
				},
			},
			success: {
				title: 'Nowy portfel został utworzony',
				text: 'Masz nowy portfel! Aby stał się aktywny, musisz wysłać do niego wkład. Kliknij w\u00a0menu w\u00a0zakładkę '
					+ 'Portfel i\u00a0wybierz go. Na stronie Portfel wybierz opcję Chcę wpłacić pieniądze. Drugą opcją jest przelanie pieniędzy z '
					+ 'istniejącego portfela. W\u00a0menu wybierz portfel, z którego chcesz przenieść pieniądze lub część ETF, i\u00a0na stronie '
					+ 'Portfel wybierz Przenieść do innego portfela.',
			},
		},
		childList: {
			title: 'Imię dziecka',
			select: 'Wybrać',
			addChild: 'Zaproś inne dziecko',
		},
		form: {
			name: {
				empty: 'Zapomniałeś/aś wypełnić nazwę portfela',
				error: 'Nazwa portfela nie może zaczynać się od spacji',
				unique: 'Używasz już tej nazwy portfela',
			},
		},
		create: 'Dodaj portfel',
	},
	settings: {
		title: 'Ustawienia portfela',
		name: {
			label: 'Edytuj nazwę portfela',
			button: 'Zmień nazwę',
		},
		currency: {
			title: 'Waluta wyświetlenia portfela',
			success: 'Zmieniono walutę widoku portfela. Jeśli chcesz wysyłać depozyty w\u00a0innej walucie, sprawdź instrukcje <0>tutaj</0>.',
		},
		additional: {
			title: 'Ustawienia dodatkowe',
		},
		pensionConfirmation: {
			download: 'Potwierdzenie dla pracodawcy możesz pobrać <0>tutaj</0>',
		},
	},
};

const portfolioComposition = {
	title: 'Alokacja portfela',
	profile: 'Profil inwestycyjny:',
	plannedProfile: 'Planowany profil inwestycyjny:',
	profileTooltip: 'Możesz zmienić swój profil inwestycyjny w\u00a0<0>ustawieniach swojego rachunku</0>. Zmiana profilu '
		+ 'przejawi się dopiero po zrównoważeniu portfela (rebalansowaniu). Aktualne informacje można znaleźć w\u00a0bloku Obrót.',
	table: {
		columns: {
			name: 'Nazwa',
			isin: 'ISIN',
			amount: 'Sztuk',
			pricePerVolume: 'Cena za sztukę',
			totalValue: 'Łączna wartość',
			information: 'Kluczowe informacje dla\u00a0inwestora',
		},
		rows: {
			cash: 'Gotówka na rachunku',
			exchange: 'Kurs wymiany',
			total: 'Łączna wartość Twojego portfela',
			totalTooltip: 'Ze względu na zaokrąglenie w\u00a0wierszach łączna wartość portfela może nie odpowiadać dokładnie sumie poszczególnych wartości.',
		},
	},
};

const portfolioFeesEtfs = {
	title: 'Jak obliczane są opłaty ETF',
	text: 'Każdy ETF ma swoje własne opłaty, które wahają się od 0,05\u00a0% do 0,47\u00a0% od\u00a0wolumenu '
		+ 'zainwestowanych środków rocznie. Fondee tworzy portfele inwestycyjne z maksymalnie 14\u00a0różnymi ETF. Opłaty ogółem, '
		+ 'które zapłacisz za ETF, zależą więc od opłat poszczególnych ETF i\u00a0ich udziału w\u00a0Twoim portfelu. '
		+ 'Zazwyczaj opłaty wynoszą około 0,15\u00a0% od\u00a0łącznego wolumenu inwestycji za\u00a0rok i\u00a0są już '
		+ 'zawarte w\u00a0wartości ETF, tak więc nie zobaczysz ich w\u00a0zestawieniu zmian w\u00a0swoim portfelu inwestycyjnym.',
	userFee: 'Twoja aktualna łączna opłata ETF wynosi {{ fee }} rocznie.',
	link: 'Wyświetl tabelę z pełnym przeglądem.',
	modal: {
		title: 'Opłaty ETF',
		name: 'Nazwa',
		ter: 'Opłata roczna (TER) za ETF',
	},
};

const portfolioFeesOverview = {
	title: 'Opłaty za zarządzanie portfelem',
	table: {
		columns: {
			year: 'Rocznie',
			month: 'Obecny miesiąc *',
		},
		rows: {
			fondee: 'Opłaty Fondee',
			etf: 'Opłaty ETF',
			total: 'Opłaty łącznie',
		},
		discount: 'zniżka do {{ date }}',
	},
	note: '*opłaty miesięczne obliczane są od\u00a0wartości końcowej portfela w\u00a0ostatnim dniu handlowym '
		+ 'w\u00a0miesiącu i\u00a0są pobierane na\u00a0początku następnego miesiąca',
	text: 'Opłaty za ETF są już zawarte w\u00a0ich wartości, w\u00a0wyciągu z rachunku '
		+ 'inwestycyjnego dlatego ich nie widać. Podajemy je, dla Twojej orientacji, o\u00a0łącznych opłatach. Opłaty dla '
		+ 'każdego ETF można znaleźć w\u00a0tzw. Kluczowych informacjach\u00a0inwestora.',
};

const portfolioTable = {
	title_one: 'Portfel',
	title_few: 'Portfel',
	title_many: 'Portfel',
	select: {
		label: 'Aprecjacja za:',
	},
	table: {
		columns: {
			name: 'Nazwa portfela',
			value: 'Wartość',
			appreciation: 'Aprecjacja',
		},
		more: 'Więcej',
		add: 'Dodaj portfel',
	},
};

const privacyPolicy = {
	title: 'Ochrona danych osobowych',
	intro: '<0>Spółka Fondee a.s., z\u00a0siedzibą Rumunská 22/28, Vinohrady, 120\u00a000 Praha\u00a02, Regon: '
		+ '06691862, wpisana do rejestru handlowego prowadzonego przez Sąd Miejski w\u00a0Pradze w\u00a0'
		+ 'sekcji B pod pozycją 27322, jako administrator danych osobowych (dalej zwany '
		+ '„<0>Administratorem</0>”),</0>'
		+ '<0>pragnie niniejszym, zgodnie z\u00a0postanowieniami art. 13 Rozporządzenia Parlamentu '
		+ 'Europejskiego i\u00a0Rady (UE) nr 2016/679 z\u00a0dnia 27\u00a0kwietnia 2016 r. w\u00a0sprawie ochrony osób '
		+ 'fizycznych w\u00a0związku z\u00a0przetwarzaniem danych osobowych oraz w\u00a0sprawie swobodnego '
		+ 'przepływu takich danych oraz uchylenia dyrektywy” 95/46/EC (ogólnego rozporządzenia o\u00a0'
		+ 'ochronie danych osobowych, zwanego: „<0>RODO</0>”), </0>'
		+ '<0>poinformować swoich aktualnych i\u00a0potencjalnych klientów (zwanych dalej „<0>Podmiotami '
		+ 'danych</0>”, indywidualnie „<0>Podmiotem danych</0>”) o\u00a0tym, co następuje:</0>',
	text: '<0>Zgodnie z\u00a0zezwoleniem Narodowego Banku Czeskiego, Administrator jest upoważniony do '
		+ 'oferowania i\u00a0świadczenia następujących głównych usług inwestycyjnych na rzecz swoich '
		+ 'klientów: (i) otrzymywanie i\u00a0przekazywanie dyspozycji dotyczących instrumentów finansowych '
		+ 'w rozumieniu §\u00a04\u00a0ust.\u00a02\u00a0lit.\u00a0a) czeskiej ustawy z\u00a0dnia 14\u00a0kwietnia 2004 r. o\u00a0prowadzeniu '
		+ 'działalności gospodarczej na rynku kapitałowym (Dz. U. z\u00a02004 r. poz. 256 z\u00a0późniejszymi '
		+ 'zmianami, zwanej dalej „<0>ZPKT</0>”), (ii) zarządzanie majątkiem klienta, w\u00a0skład którego wchodzi '
		+ 'instrument finansowy, według własnego uznania w\u00a0ramach ustaleń umownych w\u00a0rozumieniu '
		+ '§\u00a04\u00a0ust.\u00a02\u00a0lit.\u00a0d) ZPKT, oraz (iii) doradztwo inwestycyjne w\u00a0zakresie instrumentów finansowych '
		+ 'w rozumieniu §\u00a04\u00a0ust.\u00a02\u00a0lit.\u00a0e) ZPKT, w\u00a0odniesieniu do certyfikatów inwestycyjnych i\u00a0innych '
		+ 'instrumentów finansowych zgodnie z\u00a0§\u00a03\u00a0ust.\u00a01\u00a0lit.\u00a0d) ZPKT (wszystkie takie usługi '
		+ 'inwestycyjne zwane dalej „<0>Działalnością</0>”).</0>'
		+ '<0>Dane osobowe podane przez Podmiot danych, będą przetwarzane przez Administratora '
		+ 'wyłącznie w\u00a0zakresie niezbędnym do danego celu i\u00a0przez czas niezbędny do realizacji tego '
		+ 'celu.</0>'
		+ '<0>Administrator przetwarza dane osobowe Podmiotów danych w\u00a0celu</0>'
		+ '<1><0>jego Działania, tj. negocjowania zawarcia lub zmiany umowy i\u00a0realizacji umowy, w\u00a0'
		+ 'tym rozpatrywania ewentualnych roszczeń i\u00a0żądań Podmiotu danych;</0>'
		+ '<0>wypełniania obowiązków Administratora, np. wynikających z\u00a0przepisów prawa '
		+ 'regulujących działalność Administratora, a przede wszystkim obowiązków '
		+ 'związanych z\u00a0ochroną konsumentów, archiwizacją dokumentów, prowadzeniem '
		+ 'ksiąg rachunkowych i\u00a0administracją podatkową; </0>'
		+ '<0>prawnie uzasadnionego interesu Administratora, którym jest w\u00a0szczególności '
		+ 'przetwarzanie danych osobowych w\u00a0celu prowadzenia sporów prawnych, '
		+ 'postępowań administracyjnych lub podobnych czy dochodzenia roszczeń, ale pod '
		+ 'warunkiem, że interesy oraz podstawowe prawa i\u00a0wolności Podmiotu danych nie '
		+ 'przeważają nad prawnie uzasadnionym interesem Administratora. Ze względu na '
		+ 'prawnie uzasadniony interes Administrator przetwarza dalej dane osobowe dla '
		+ 'wewnętrznych celów administracyjnych (np. ewidencji wewnętrznej, raportowania '
		+ 'itp.), zarządzania jakością świadczonych usług i\u00a0relacji z\u00a0Podmiotem danych oraz '
		+ 'zarządzania ryzykiem; lub </0>'
		+ '<0>określonym w\u00a0zgodzie na przetwarzanie danych osobowych udzielonej przez '
		+ 'Podmiot danych. w\u00a0odniesieniu do plików cookies zgoda może być wyrażona dla '
		+ 'wszystkich grup plików cookies lub dla wybranej grupy plików cookies. Wyrażenie '
		+ 'zgody na przetwarzanie danych osobowych jest dobrowolne i\u00a0Podmiot danych '
		+ 'może ją odwołać w\u00a0dowolnym momencie. Za zgodą Administrator przetwarza '
		+ 'szczególne kategorie danych osobowych (stan zdrowia) oraz dane osobowe w\u00a0'
		+ 'celach marketingowych.</0></1>'
		+ '<0>Po zrealizowaniu pierwotnego celu (np. wykonanie umowy) Administrator może przetwarzać '
		+ 'dane osobowe w\u00a0innych celach (np. w\u00a0celu wypełnienia ustawowego okresu '
		+ 'archiwizacyjnego). </0>'
		+ '<0>W wyżej wymienionych celach Administrator przetwarza w\u00a0szczególności następujące dane '
		+ 'osobowe </0>'
		+ '<1><0>dane identyfikacyjne i\u00a0adresowe: stopień naukowy, imię, nazwisko, data urodzenia, '
		+ 'miejsce urodzenia, numer PESEL, informacje o\u00a0dokumentach tożsamości (w tym '
		+ 'ewentualne uzyskanie kserokopii dokumentu tożsamości przy nawiązaniu '
		+ 'stosunku umownego lub w\u00a0trakcie jego trwania, zgodnie z\u00a0odnośnymi przepisami '
		+ 'prawa), płeć, adres stałego zamieszkania, adres do doręczeń lub inny adres '
		+ 'kontaktowy; </0>'
		+ '<0>inne dane dotyczące Podmiotu danych lub powiązane z\u00a0Podmiotem danych, np. '
		+ 'stan cywilny lub kod referencyjny do rejestracji u Administratora; </0>'
		+ '<0>elektroniczne dane kontaktowe: numer telefonu (komórkowego), adres e-mail; </0>'
		+ '<0>inne dane elektroniczne: adres IP, dane o\u00a0lokalizacji używanego urządzenia; </0>'
		+ '<0> inne dane osobowe wymagane do realizacji umowy: numer rachunku bankowego, '
		+ 'wysokość płatności i\u00a0ich historia; </0>'
		+ '<0>informacje o\u00a0Podmiocie danych związku z\u00a0wypełnieniem obowiązków prawnych '
		+ '(np. wynikających z\u00a0przepisów czeskiej ustawy z\u00a0dnia 5 czerwca 2008 r. o\u00a0'
		+ 'niektórych środkach przeciwko legalizacji dochodów z\u00a0działalności przestępczej i\u00a0'
		+ 'finansowania terroryzmu, Dz.U. z\u00a02008 r. poz. 253 z\u00a0późniejszymi zmianami, dalej '
		+ 'jako: „<0>Ustawa AML</0>”), w\u00a0tym fotografię wykonaną przez Podmiot danych, '
		+ 'Administratora lub osobę trzecią związaną stosunkiem umownym z\u00a0'
		+ 'Administratorem, w\u00a0celu weryfikacji zgodności fotografii z\u00a0wizerunkiem zawartym '
		+ 'w dokumencie tożsamości w\u00a0rozumieniu przepisów Ustawy AML oraz informacje z\u00a0'
		+ 'publicznie dostępnych i\u00a0niepublicznych rejestrów i\u00a0ewidencji; </0>'
		+ '<0>inne dane osobowe podane przez Podmiot danych w\u00a0umowie lub w\u00a0innych '
		+ 'dokumentach, podczas komunikacji telefonicznej lub e-mailowej oraz</0>'
		+ '<0>rejestry rozmów telefonicznych i\u00a0informacje z\u00a0tych rejestrów.</0></1>'
		+ '<0>Administrator pozyskuje dane osobowe bezpośrednio od Podmiotów danych (zwłaszcza w\u00a0'
		+ 'przypadku negocjacji umów oraz podczas innej komunikacji z\u00a0Podmiotem danych), od osób '
		+ 'trzecich (w szczególności od organów administracji państwowej przy wypełnianiu obowiązków '
		+ 'prawnych lub na podstawie szczególnych przepisów prawa), z\u00a0publicznie dostępnych źródeł '
		+ '(zwłaszcza rejestrów publicznych) lub z\u00a0własnej działalności (w szczególności poprzez ocenę '
		+ 'i analizę danych osobowych pozyskanych z\u00a0innych, wcześniej wymienionych źródeł). '
		+ 'Administrator pozyskuje również dane osobowe w\u00a0ograniczonym zakresie automatycznie '
		+ 'poprzez wykorzystywanie plików cookies na stronie internetowej Administratora. </0>'
		+ '<0>Dane osobowe Podmiotu danych będą przetwarzane prawidłowo oraz w\u00a0sposób zgodny z\u00a0'
		+ 'prawem. Przetwarzane będą wyłącznie takie dane osobowe, które są adekwatne, stosowne i\u00a0'
		+ 'ograniczone do niezbędnego zakresu w\u00a0stosunku do celu, w\u00a0jakim są przetwarzane.</0>'
		+ '<0>Dane osobowe Podmiotu danych będą przetwarzane w\u00a0sposób zapewniający ich odpowiednie '
		+ 'bezpieczeństwo, w\u00a0tym ich ochronę za pomocą odpowiednich środków technicznych lub '
		+ 'organizacyjnych przed niedozwolonym lub niezgodnym z\u00a0prawem przetwarzaniem oraz '
		+ 'przypadkową utratą, zniszczeniem lub uszkodzeniem.</0>'
		+ '<0>Administrator przetwarza wyłącznie dokładne dane osobowe pozyskane zgodnie z\u00a0RODO i\u00a0'
		+ 'jest zobowiązany do aktualizacji przetwarzanych danych osobowych. w\u00a0tym celu Podmioty '
		+ 'danych, których dane osobowe są przetwarzane, zobowiązane są do niezwłocznego '
		+ 'zgłoszenia Administratorowi wszelkich zmian oraz przedstawienia Administratorowi '
		+ 'dokumentów potwierdzających zmianę. '
		+ 'Dane osobowe Podmiotu danych będą przetwarzane przez okres obowiązywania umowy lub '
		+ 'zarządzania nią przez Administratora, a następnie przez okres istnienia prawnie '
		+ 'uzasadnionego interesu (w szczególności przez okres przedawnienia roszczeń z\u00a0tytułu '
		+ 'wykonania umowy (maks. 10 lat) od jej ustania lub od dnia ustania zarządzania, a w\u00a0przypadku '
		+ 'wszczęcia postępowania sądowego, arbitrażowego, egzekucyjnego lub innego podobnego '
		+ 'postępowania na czas trwania tego postępowania a następnie przez czas trwania terminów '
		+ 'wniesienia nadzwyczajnych środków zaskarżenia oraz w\u00a0trakcie postępowania w\u00a0sprawie, w\u00a0'
		+ 'której wniesiono nadzwyczajne środki zaskarżenia), dalej przez okres udzielenia zgody na '
		+ 'przetwarzanie przez Podmiot danych, jeżeli zgoda została udzielona, jak również przez czas '
		+ 'istnienia obowiązku prawnego ciążącego na Administratorze (związanego np. z\u00a0wymogami '
		+ 'administracji finansowej, organu nadzorczego, regulaminu archiwizacji) lub do celów '
		+ 'wynikających z\u00a0prawnie uzasadnionych interesów Administratora (spór sądowy). '
		+ 'Administrator przetwarza dane osobowe wyłącznie w\u00a0określonych celach zgodnie z\u00a0'
		+ 'obowiązującym tytułem prawnym do przetwarzania. w\u00a0przypadku braku zawarcia umowy z\u00a0'
		+ 'Podmiotem danych, Administrator przetwarza dane osobowe pozyskane w\u00a0związku z\u00a0'
		+ 'zawarciem umowy przez okres pięciu lat. Ewidencja rozmów z\u00a0linią telefoniczną dla klientów '
		+ 'jest przechowywana przez Administratora przez okres pięciu lat. </0>'
		+ '<0>Podmiotowi danych przysługuje prawo do cofnięcia w\u00a0dowolnym momencie zgody na '
		+ 'przetwarzanie jego danych osobowych lub wyraźnej zgody na przetwarzanie szczególnej '
		+ 'kategorii danych osobowych, bez uszczerbku dla zgodności z\u00a0prawem przetwarzania na '
		+ 'podstawie zgody udzielonej przed jego cofnięciem. Bardziej szczegółowe informacje na temat '
		+ 'cofnięcia zgody na wykorzystywanie plików cookies znajdują się w\u00a0<0>Polityce dotyczącej '
		+ 'plików cookie</0>.</0>'
		+ '<0>Dane osobowe Podmiotu danych mogą być przekazywane w\u00a0celu i\u00a0w związku z\u00a0Działalnością '
		+ 'Administratora podmiotom świadczącym usługi inwestycyjne oraz innym instytucjom '
		+ 'finansowym pozostającym w\u00a0stosunku umownym z\u00a0Administratorem, upoważnionym '
		+ 'współpracownikom i\u00a0pracownikom Administratora, a także dostawcom usług pocztowych i\u00a0'
		+ 'archiwizacyjnych oraz osobom, które świadczą na rzecz Administratora usługi informatyczne, '
		+ 'programistyczne, prawne, księgowe, doradcze, a także innym osobom, jeżeli jest to w\u00a0'
		+ 'interesie zapewnienia należytego wykonania obowiązków przewidzianych powszechnie '
		+ 'obowiązującymi przepisami prawa /lub zobowiązań umownych, lub gdy podanie danych '
		+ 'osobowych jest wymagane przepisami prawa lub jest niezbędne do ochrony prawnie '
		+ 'uzasadnionych interesów Administratora. Ponadto dane osobowe mogą być również '
		+ 'przekazywane zgodnie z\u00a0prawem organom ścigania, sądom, Narodowemu Bankowi '
		+ 'Czeskiemu, organom podatkowym, organowi finansowemu, komornikom lub syndykom masy '
		+ 'upadłości, Czeskiemu Biuru Ubezpieczycieli i\u00a0innym organom publicznym.</0>'
		+ '<0>Jeżeli Podmiot danych w\u00a0trakcie wykonywania umowy przekaże Administratorowi dane '
		+ 'osobowe innych osób, które będą przetwarzane przez Administratora, to na Podmiocie tym '
		+ 'spoczywa odpowiedzialność za wypełnienie obowiązków w\u00a0zakresie ochrony danych '
		+ 'osobowych w\u00a0stosunku do tych osób (w szczególności informowanie o\u00a0przetwarzaniu danych '
		+ 'osobowych).</0>'
		+ '<0>Administrator nie zamierza przekazywać danych osobowych Podmiotu danych do państwa '
		+ 'trzeciego (tj. poza Unię Europejską lub Europejski Obszar Gospodarczy) lub organizacji '
		+ 'międzynarodowej. Jeżeli jednak Podmiot danych zaakceptował lub wybrał pliki cookie '
		+ 'udostępniane przez Google, Facebook, Twitter lub YouTube, to tym samym wyraził wyraźną '
		+ 'zgodę na przekazywanie danych osobowych do państw trzecich (głównie do USA).'
		+ 'Administrator zwraca jednak uwagę, że po przekazaniu danych osobowych do państw trzecich '
		+ 'Administrator nie posiada wystarczających narzędzi prawnych i\u00a0faktycznych zapewniających '
		+ 'bezpieczeństwo danych osobowych użytkowników.</0>'
		+ '<0>Administrator nie wyznaczył inspektora ochrony danych osobowych, ale wyznaczył osobę '
		+ 'odpowiedzialną za ten obszar i\u00a0sprawującą nadzór nad prawidłowym przetwarzaniem danych '
		+ 'osobowych Podmiotu danych. </0>'
		+ '<0>Podmiot danych ma prawo żądać od Administratora dostępu do swoich danych osobowych '
		+ 'oraz uzyskania kopii dotyczących jego danych osobowych, które Administrator przetwarza, '
		+ 'ich sprostowania lub uzupełnienia, usunięcia lub ograniczenia przetwarzania, a także prawo '
		+ 'do wniesienia sprzeciwu wobec przetwarzania oraz prawo do przenoszenia tych danych do '
		+ 'innego administratora. Podmiotowi danych przysługuje również prawo wniesienia skargi do '
		+ 'czeskiego Urzędu Ochrony Danych Osobowych (Pplk. Sochora 27, 170 00 Praha 7, strona '
		+ 'internetowa: <1>www.uoou.cz</1>) jeżeli uważa, Administrator przetwarzając dane osobowe działa z\u00a0'
		+ 'naruszeniem przepisów RODO. Podmiot danych może również skorzystać ze swojego prawa '
		+ 'do dochodzenia roszczeń we właściwym sądzie.</0>'
		+ '<0>Dane kontaktowe Administratora w\u00a0celu przetwarzania danych osobowych:</0>',
	contact: '<0>Jan Hlavsa</0>'
		+ '<0>Email: <0>honza@fondee.cz</0></0>',
	consent: {
		title: 'Aby cofnąć zgodę na przetwarzanie danych osobowych wyślij następujące dane na '
			+ 'adres <0>info@fondee.pl</0>:',
		text: '<0>Cofnięcie zgody na przetwarzanie danych osobowych</0>'
			+ '<1>Imię:</1>'
			+ '<1>Nazwisko:</1>'
			+ '<1>Email:</1>'
			+ '<1>Telefon:</1>',
		email: 'info@fondee.pl',
	},
};

const products = {
	child: {
		title: 'Inwestowanie dla dzieci',
		text: 'Nie zostawiaj oszczędności swoich dzieci na koncie w\u00a0banku, gdzie tracą na wartości. Załóż dla nich portfel '
			+ 'inwestycyjny online z\u00a0<0>obniżoną opłatą</0>. Wystarczy stałe zlecenie i\u00a0masz z\u00a0głowy!',
		button: 'Chcę inwestować dla dzieci',
		profileDetail: {
			title: 'Poznaj portfele dla dzieci',
		},
		fees: {
			title: 'Opłata',
			text: '<0>Opłata jest naliczana od wartości portfela i\u00a01/12 opłaty rocznej jest potrącana raz w\u00a0miesiącu. W\u00a0cenie otrzymujesz:</0>',
			list: '<0>nieograniczone przetwarzanie wpłat i\u00a0wypłat</0>'
				+ '<0>nieograniczone zakupy i\u00a0sprzedaż ETF</0>'
				+ '<0><1>rebalancing</1> (chyba że chcesz go dobrowolnie wyłączyć)</0>'
				+ '<0>przetwarzanie dywidend (dotyczy tradycyjnych portfeli)</0>'
				+ '<0>przewalutowanie EURO↔PLN po aktualnym kursie rynkowym</0>'
				+ '<0>dokumenty podatkowe pod kątem ewentualnego zeznania podatkowego</0>'
				+ '<0>wsparcie klienta przez czat, e-mail i\u00a0telefon</0>',
		},
		terms: {
			title: 'Warunki',
			text: '<0>Dostęp i\u00a0własność inwestycji</0>'
				+ '<1>Rodzic (lub inny opiekun prawny) zakłada konto inwestycyjne dla dziecka. Dziecko ma własne dane dostępu, aby '
				+ 'mogło obserwować i\u00a0uczyć się, jak zachowują się inwestycje. W\u00a0dniu 18. urodzin konto dziecka staje się '
				+ 'regularnym kontem inwestycyjnym z\u00a0regularnymi opłatami i\u00a0jest w\u00a0pełni zarządzane przez daną osobę pełnoletnią, '
				+ 'która zadecyduje, czy wypłacić całą kwotę, część pieniędzy lub pozwolić, aby ich wartość nadal rosła.</1>'
				+ '<0>Portfele i\u00a0limity depozytów</0>'
				+ '<1>Czy inwestując dla dziecka chcesz skupić się na różnych celach i\u00a0jeden portfel Ci nie wystarczy? Możesz '
				+ 'założyć nawet 3\u00a0portfele o różnym profilu inwestycyjnym. Do tych portfeli mogą wpłacać nie tylko rodzice, '
				+ 'ale także inni członkowie rodziny! W\u00a0polu uwagi do płatności wystarczy wpisać numer portfela, aby płatność '
				+ 'została prawidłowo przypisana. Założyciele konta dla dziecka oraz klienci Fondee ze zweryfikowanym rachunkiem '
				+ 'bankowym mogą bez ograniczeń wpłacać środki na konta dla dzieci. Osoby, które nie są klientami Fondee, mogą '
				+ 'przesłać każdemu dziecku maksymalnie 4\u00a0000\u00a0zł rocznie z\u00a0jednego rachunku bankowego.</1>'
				+ '<0>Wypłata pieniędzy przed 18.\u00a0urodzinami</0>'
				+ '<1>Jeśli znajdziesz się w\u00a0sytuacji, gdy musisz wypłacić środki, zanim Twój potomek zdmuchnie '
				+ '18\u00a0świeczek na torcie, nie ma problemu - dorównamy jedynie opłatę za zarządzanie do wysokości naszej '
				+ 'standardowej stawki 0,9% rocznie i\u00a0pieniądze będą dostępne na Twoim koncie w\u00a0ciągu tygodnia.</1>',
		},
		benefits: {
			title: 'Dlaczego inwestować dla dzieci w\u00a0Fondee?',
			list: [
				'<0>Niższe opłaty</0>'
				+ '<1>Chętnie wesprzemy Cię w\u00a0długoterminowym inwestowaniu dla dzieci, dlatego w\u00a0przypadku kont dla dzieci '
				+ 'oferujemy preferencyjną opłatę w\u00a0wysokości 0,2% (o 0,7% niższą niż w\u00a0przypadku innych inwestycji w\u00a0Fondee!).</1>',
				'<0>Zabezpieczenie przyszłości</0>'
				+ '<1>Przekaż dzieciom kwotę, która pomoże im w\u00a0przyszłości. Za kilka lat za dziesiejsze sto złotych nie kupisz '
				+ 'tego, co teraz. Inwestycje, w\u00a0przeciwieństwie do lokat czy kont oszczędnościowych, mają szansę pokonać <4>inflację.</4></1>',
				'<0>Edukacja dzieci</0>'
				+ '<1>Tu nie chodzi tylko o pieniądze na szkołę czy pierwsze mieszkanie. Dzieci mogą mieć swój dostęp do Fondee i\u00a0'
				+ 'śledzić, jak rozwijają się inwestycje. W\u00a0ten sposób zdobywają wiedzę o finansach i\u00a0cenne doświadczenia.</1>',
			],
		},
	},
	classic: {
		title: 'Inwestycje szyte na miarę',
		text: 'Inwestycje tak proste, że działają same. Pomnażaj swoje pieniądze online, z\u00a0niskimi opłatami i\u00a0<0>już '
			+ 'od 100\u00a0zł</0>. Wybieraj spośród 7\u00a0klasycznych portfeli i\u00a0inwestuj w\u00a0setki akcji '
			+ 'i\u00a0obligacji z\u00a0całego świata.',
		button: 'Chcę inwestować',
		profileDetail: {
			title: 'Poznaj tradycyjne portfele',
		},
		fees: {
			title: 'Opłata',
			text: '<0>Opłata jest naliczana od wartości portfela i\u00a01/12 opłaty rocznej jest potrącana raz w\u00a0miesiącu. W\u00a0cenie otrzymujesz:</0>',
			list: '<0>nieograniczone przetwarzanie wpłat i\u00a0wypłat</0>'
				+ '<0>nieograniczone zakupy i\u00a0sprzedaż ETF</0>'
				+ '<0><1>rebalancing</1> (chyba że chcesz go dobrowolnie wyłączyć)</0>'
				+ '<0>przetwarzanie dywidend (dotyczy tradycyjnych portfeli)</0>'
				+ '<0>przewalutowanie EURO↔PLN po aktualnym kursie rynkowym</0>'
				+ '<0>dokumenty podatkowe pod kątem ewentualnego zeznania podatkowego</0>'
				+ '<0>wsparcie klienta przez czat, e-mail i\u00a0telefon</0>',
		},
		benefits: {
			title: 'Dlaczego warto inwestować z\u00a0Fondee?',
			list: [
				'<0>Dywersyfikacja</0>'
				+ '<1>Nie stawiaj wszystkiego na jedną kartę. Portfele Fondee zawierają setki akcji i\u00a0obligacji '
				+ '<0>z różnych krajów i\u00a0branż</0>. Dzięki wysokiej jakości dywersyfikacji wartość Twojej inwestycji nie '
				+ 'jest zagrożona przez niepowodzenie jednej firmy lub branży.</1>',
				'<0>Elastyczność</0>'
				+ '<1>Fondee dostosowuje się do Ciebie. Inwestuj tyle, ile chcesz. Inwestuj, kiedy chcesz. Otwórz do '
				+ '<0>5 różnych portfeli</0> w\u00a0tym samym czasie. A kiedy zdecydujesz się wypłacić pieniądze, znajdą się one '
				+ 'na Twoim koncie <0>w\u00a0ciągu tygodnia</0>.</1>',
				'<0>Bezpieczeństwo</0>'
				+ '<1>Jesteśmy nadzorowani przez <0>Czeski Bank Narodowy</0>, a Twoje dane są chronione przez szyfrowaną komunikację. '
				+ 'W\u00a0przypadku wycieku hasła, <0>nie można przesłać pieniędzy na obce konto</0> ani zmienić danych bez '
				+ 'autoryzacji i\u00a0dowodu.</1>',
			],
		},
	},
	esg: {
		title: 'Zrównoważone inwestycje',
		text: 'Zyski czy odpowiedzialność i\u00a0wartości? Zdobądź jedno i\u00a0drugie! Inwestuj w\u00a0sposób zrównoważony w\u00a0portfele '
			+ 'ESG i\u00a0pomnażaj swoje pieniądze z\u00a0poczuciem, że przyczyniasz się do <0>lepszej przyszłości</0> - Twojej i\u00a0naszej.',
		button: 'Chcę inwestować w\u00a0sposób zrównoważony',
		profileDetail: {
			title: 'Poznaj portfele ESG',
		},
		fees: {
			title: 'Opłata',
			text: '<0>Opłata jest naliczana od wartości portfela i\u00a01/12 opłaty rocznej jest potrącana raz w\u00a0miesiącu. W\u00a0cenie otrzymujesz:</0>',
			list: '<0>nieograniczone przetwarzanie wpłat i\u00a0wypłat</0>'
				+ '<0>nieograniczone zakupy i\u00a0sprzedaż ETF</0>'
				+ '<0><1>rebalancing</1> (chyba że chcesz go dobrowolnie wyłączyć)</0>'
				+ '<0>przewalutowanie EURO↔PLN po aktualnym kursie rynkowym</0>'
				+ '<0>dokumenty podatkowe pod kątem ewentualnego zeznania podatkowego</0>'
				+ '<0>wsparcie klienta przez czat, e-mail i\u00a0telefon</0>',
		},
		benefits: {
			title: 'Dlaczego warto inwestować w\u00a0sposób zrównoważony?',
			list: [
				'<0>Właściwe wartości</0>'
				+ '<1>Chcesz inwestować w\u00a0spółki, które osiągają dobre wyniki finansowe, a jednocześnie przyczyniają się do '
				+ '<0>bardziej zrównoważonej przyszłości</0>? Wybierz odpowiedzialne portfele składające się z\u00a0funduszy ETF '
				+ 'z najlepszymi <1>ocenami ESG</1>.</1>',
				'<0>Zwroty</0>'
				+ '<1>Zrównoważone fundusze ETF <2>biją ostatnio na głowę te konwencjonalne</2>. ESG poprawia wyniki finansowe spółek w\u00a0'
				+ 'perspektywie długoterminowej. Co więcej, fundusze ETF w\u00a0naszych portfelach nie wypłacają dywidend, lecz je '
				+ '<0>reinwestują</0>, dzięki czemu rośnie ich wartość.</1>',
				'<0>Niższe ryzyko</0>'
				+ '<1>ESG bierze pod uwagą <0>ryzyka, których tradycyjna analiza finansowa nie uwzględnia</0>, a mogą one wpływać na '
				+ 'wyniki całej firmy i\u00a0wartość Twojego portfela. Ponadto okazuje się, że odpowiedzialne inwestycje są '
				+ 'długoterminowo mniej <3>podatne na wahania</3>.</1>',
			],
		},
	},
	pension: {
		title: 'Emerytalne Konto Inwestycyjne',
		text: 'Jeżeli nie zadbasz o\u00a0swoją przyszłość, nikt inny tego nie zrobi. Nie polegaj na państwie i\u00a0pożegnaj się '
			+ 'z\u00a0drogimi funduszami emerytalnymi o\u00a0niskich zwrotach. Odkładaj na emeryturę <0>łatwo i\u00a0z\u00a0niższymi '
			+ 'opłatami</0> w\u00a0porównaniu do naszych standardowych portfeli.',
		button: 'Chcę bogatszej emerytury',
		profileDetail: {
			title: 'Poznaj portfele emerytalne',
		},
		fees: {
			title: 'Opłaty',
			text: '<0>Wysokość opłaty jest obliczana na podstawie wartości portfela i\u00a0jest pobierana raz w\u00a0miesiącu '
				+ 'w\u00a0wysokości 1/12 opłaty rocznej. W\u00a0cenie otrzymujesz:</0>',
			list: '<0>przetwarzanie nieograniczonej ilości <0>wpłat i\u00a0wypłat</0></0>'
				+ '<0>nieograniczoną liczbę zakupów i\u00a0sprzedaży ETF</0>'
				+ '<0><1>rebalansowanie</1> (jeśli nie zdecydujesz się na jego wyłączenie)</0>'
				+ '<0>przetwarzanie <0>wypłacanych dywidend</0> (dotyczy klasycznych portfeli)</0>'
				+ '<0>konwersję waluty EURO↔PLN po aktualnym kursie rynkowym</0>'
				+ '<0><0>dokumenty podatkowe</0> do ewentualnego złożenia deklaracji podatkowej</0>'
				+ '<0>wsparcie klienta na czacie, drogą mailową i\u00a0telefonicznie</0>',
		},
		terms: {
			title: 'Warunki',
			text: '<0>Ograniczenia wiekowe i\u00a0liczba portfeli</0>'
				+ '<1>Czy już obchodziłaś/eś <0>18.\u00a0urodziny</0>? W\u00a0takim razie nic nie stoi na przeszkodzie, '
				+ 'aby otworzyć portfel emerytalny w Fondee. Nie wahaj się i\u00a0działaj! Możesz założyć <0>jeden portfel '
				+ 'emerytalny</0>, który umożliwi Ci inwestować na swoją przyszłość <0>z\u00a0jeszcze korzystniejszą opłatą</0> '
				+ 'w\u00a0porównaniu do naszych standardowych portfeli.</1>'
				+ '<0>Wypłaty i\u00a0transfer środków</0>'
				+ '<1>Emerytalne Konto Inwestycyjne jest przeznaczone do <0>inwestycji długoterminowych</0>. Możesz więc wypłacić '
				+ 'swoje pieniądze bez żadnych kosztów i\u00a0korzystać z\u00a0nich <0>od 60\u00a0roku życia</0>, '
				+ 'pod warunkiem, że minęło co najmniej <0>10\u00a0lat (120\u00a0miesięcy) od daty założenia portfela emerytalnego</0>. To od Ciebie '
				+ 'zależy, czy wypłacisz całą kwotę od razu, czy też będziesz wypłacać środki stopniowo, pozostawiając resztę, aby '
				+ 'nadal rosła. Nie ma jednak możliwości przenoszenia środków między portfelem emerytalnym a\u00a0innymi portfelami Fondee.</1>'
				+ '<0>Przedwczesna wypłata i\u00a0anulowanie portfela</0>'
				+ '<1>Jeśli konieczna jest wypłata środków z\u00a0Emerytalnego Konta Inwestycyjnego przed 60-tymi urodzinami lub '
				+ 'przed upływem 10\u00a0lat od daty podpisania umowy, istnieje możliwość <0>wcześniejszego anulowania portfela</0>. '
				+ 'Jedndakże taka decyzja spowoduje naruszenie warunków umowy i\u00a0naliczenie standardowej opłaty '
				+ 'w\u00a0<0>wysokości 0,9%\u00a0rocznie</0>.</1>',
		},
		benefits: {
			title: 'Dlaczego warto inwestować na emeryturę z\u00a0Fondee?',
			list: [
				'<0>Zabezpieczenie na jesień życia</0>'
				+ '<1>Nie wierzysz już w\u00a0bajki o\u00a0emeryturze państwowej i\u00a0wiesz, że oszczędzanie nie pokona inflacji? '
				+ 'Przyszedł czas, aby wziąć przyszłość w\u00a0swoje ręce. Zapewnij sobie spokojną emeryturę i zacznij '
				+ 'inwestować -\u00a0<0>z\u00a0nami dasz radę</0>.</1>',
				'<0>Niższe opłaty</0>'
				+ '<1>Cieszymy się, że chcesz zapewnić sobie bogatszą emeryturę. Aby cię wesprzeć, '
				+ '<0>oferujemy obniżoną opłatę w\u00a0wysokości 0,5% rocznie od naszych portfeli '
				+ 'emerytalnych</0> rocznie (o\u00a00,4% niższą niż w przypadku innych inwestycji Fondee!).</1>',
				'<0>Długoterminowy dochód</0>'
				+ '<1>Zamień drogie fundusze emerytalne o\u00a0niskich zwrotach na portfel emerytalny Fondee. Inwestuj pasywnie '
				+ 'i\u00a0osiągnij <0>rozsądne zyski bez zbędnych komplikacji</0>. Wystarczy jedno zlecenie stałe i\u00a0gotowe.</1>',
			],
		},
	},
	list: {
		title: 'Pozostałe produkty',
		classic: {
			title: 'Inwestycje szyte na miarę',
			text: 'Jeden depozyt = setki akcji i\u00a0obligacji z\u00a0całego świata. Inwestuj w\u00a0fundusze ETF online, łatwo i\u00a0przejrzyście.',
			button: 'Chcę klasyczny portfel',
		},
		esg: {
			title: 'Zrównoważone inwestycje',
			text: 'Wartości i\u00a0zwroty rozumieją się nawzajem. Inwestuj w\u00a0sposób zrównoważony w\u00a0portfele, które w\u00a0ostatnich '
				+ 'latach pobiły tradycyjne inwestycje.',
			button: 'Chcę portfel ESG',
		},
		child: {
			title: 'Inwestowanie dla dzieci',
			text: 'Nie pozwól, aby oszczędności Twoich dzieci leżały w\u00a0banku, gdzie tracą na wartości. Załóż portfel inwestycyjny z\u00a0obniżoną opłatą.',
			button: 'Chcę konto dla dziecka',
		},
		pension: {
			title: 'Emerytalne Konto Inwestycyjne',
			text: 'Zabezpiecz się na jesień życia dzięki inteligentnym inwestycjom z\u00a0niskimi opłatami. Nie polegaj na '
				+ 'państwie i\u00a0pożegnaj się z\u00a0drogimi funduszami emerytalnymi.',
			button: 'Chcę portfel emerytalny',
		},
	},
};

const productAccount = {
	title: 'Historyczne zwroty',
	toggleOptions: [
		{
			label: 'Wartość',
			value: 'value',
		},
		{
			label: 'Zwrot',
			value: 'evaluation',
		},
		{
			label: 'Zwrot w\u00a0%',
			value: 'evaluationPercent',
		},
	],
	note: 'Historyczny zwrot opiera się na średniej aprecjacji funduszy ETF wchodzących w\u00a0skład portfela '
		+ '(lub wynikach ich indeksów bazowych) w\u00a0wybranym okresie, w\u00a0euro i\u00a0przed uwzględnieniem opłat. '
		+ 'Należy pamiętać, że historyczne zwroty nie są gwarancją przyszłych zwrotów. Aby uzyskać więcej '
		+ 'informacji na temat ryzyka związanego z\u00a0inwestowaniem, zobacz <0>Jak to działa</0>.',
};

const productSelect = {
	standard: 'Dla mnie',
	child: 'Dla dziecka',
	pension: 'Emerytalne',
};

const profileAvatar = {
	faces: {
		esg: {
			0: 'Zdjęcie profilowe 1',
			1: 'Zdjęcie profilowe 2',
			2: 'Zdjęcie profilowe 3',
			3: 'Zdjęcie profilowe 4',
			4: 'Zdjęcie profilowe 5',
			5: 'Zdjęcie profilowe 6',
			6: 'Zdjęcie profilowe 7',
			7: 'Zdjęcie profilowe 8',
			8: 'Zdjęcie profilowe 9',
			9: 'Zdjęcie profilowe 10',
			10: 'Zdjęcie profilowe 11',
			11: 'Zdjęcie profilowe 12',
			12: 'Zdjęcie profilowe 13',
			13: 'Zdjęcie profilowe 14',
		},
		classic: {
			0: 'Zdjęcie profilowe 1',
			1: 'Zdjęcie profilowe 2',
			2: 'Zdjęcie profilowe 3',
			3: 'Zdjęcie profilowe 4',
			4: 'Zdjęcie profilowe 5',
			5: 'Zdjęcie profilowe 6',
			6: 'Zdjęcie profilowe 7',
			7: 'Zdjęcie profilowe 8',
			8: 'Zdjęcie profilowe 9',
			9: 'Zdjęcie profilowe 10',
			10: 'Zdjęcie profilowe 11',
			11: 'Zdjęcie profilowe 12',
			12: 'Zdjęcie profilowe 13',
			13: 'Zdjęcie profilowe 14',
		},
	},
};

const referrals = {
	title: '200\u00a0zł za polecenie',
	text: 'Jeśli uważasz, że Fondee jest dobrą usługą, będzie nam miło, jeśli nas polecisz! '
		+ 'Aby Ci dać dodatkową zachętę, za każdego nowego klienta, '
		+ 'który zarejestruje się z\u00a0Twoim kodem referencyjnym i\u00a0zainwestuje w\u00a0Fondee w\u00a0<0>październiku\u00a02024</0>, '
		+ 'zasilimy Twoje konto kwotą <0>200\u00a0zł</0>. A jej lub jemu damy <0>3 miesiące bez opłat</0>.',
	subtitle: 'Jakie są zasady?',
	rules: {
		1: 'Promocja dotyczy Ciebie, jeśli zarejestrowałaś/eś się do 30.\u00a09.\u00a02024 włącznie.',
		2: 'Maksymalny bonus za polecenie wynosi 2\u00a0000\u00a0zł na osobę, czyli za polecenie maksymalnie 10\u00a0nowych klientów.'
			+ 'Jeśli zatem z powodzeniem polecisz więcej niż 10\u00a0klientów, nie dostaniesz więcej niż 2\u00a0000\u00a0zł.',
		3: 'Za jedno skuteczne polecenie uważa się, kiedy jedna osoba, '
			+ 'która otworzy w październiku\u00a02024 przynajmniej jedno konto w\u00a0Fondee\u00a0używając Twojego kodu polecającego, '
			+ 'zakończy rejestrację (w tym podpisze umowę) i\u00a0wpłaci na konto co najmniej 100\u00a0zł.',
		4: 'Promocja nie dotyczy założenia konta dla własnego dziecka.',
		5: 'Podsumowanie skutecznych poleceń odbędzie się na początku listopada\u00a02024.',
		6: 'Bonusy zostaną przypisane do Twojego konta w\u00a0Fondee. Co oznacza również, '
			+ 'że ty też musisz mieć dokończoną rejestrację i\u00a0aktywowane konto.',
		7: 'Fondee zastrzega sobie prawo do zmiany zasad i\u00a0procesu oceny.',
	},
	code: 'Kod referencyjny',
	copyLink: 'Skopiuj link do strony Fondee z\u00a0wstępnie wypełnionym kodem referencyjnym.',
	form: {
		email: {
			label: 'E-mail, na który wyślemy rekomendację',
			error: 'Zapomniałeś/aś wypełnić adres e-mail.',
		},
		message: {
			label: 'Twoja wiadomość',
			error: 'Zapomniałeś/aś napisać wiadomość',
		},
		messageText: 'Gratulujemy, masz świetnych przyjaciół! Kto inny mógłby Ci polecić inwestowanie z\u00a0Fondee?\n'
			+ 'Dodatkowo, jeżeli wpiszesz kod referencyjny {{ code }} podczas rejestracji na www.fondee.pl i\u00a0założysz '
			+ 'konto inwestycyjne, zwolnimy Cię z opłat przez pierwsze 3 miesiące! To przecież świetna okazja, aby wypróbować usługę, prawda?\n'
			+ 'Jeśli jest coś, w\u00a0czym możemy Ci pomóc przy inwestowaniu z\u00a0Fondee, koniecznie skontaktuj się '
			+ 'z\u00a0nami, Kasią i\u00a0Irys, na info@fondee.pl!',
		send: 'Wyślij',
		success: 'Dziękujemy za udostępnienie kodu referencyjnego. Wyślij go do\u00a0innych znajomych.',
	},
	emailStats: {
		title: 'Wiadomość e-mail z\u00a0kodem referencyjnym została wysłana na:',
		used: 'Kod został wykorzystany!',
	},
	socialShare: {
		facebook: 'Fondee – z\u00a0kodem bonusowym. Zacznij inwestować z\u00a0bonusem od znajomego.',
		twitter: 'Oto mój kod referencyjny w\u00a0Fondee: {{ code }}. Dlaczego go udostępniam? Ponieważ, jeżeli założysz z\u00a0nim '
			+ 'konto inwestycyjne w\u00a0Fondee, nie poniesiesz żadnych opłat przez pierwsze 3\u00a0miesiące. A\u00a0to się opłaca!',
		sendVia: 'Wyślij przez',
	},
	stats: {
		1: 'Liczba osób, które zarejestrowały się z\u00a0Twoim kodem referencyjnym:',
		2: 'Liczba osób, które zarejestrowały się z\u00a0Twoim kodem referencyjnym we wrześniu:',
		3: 'Liczba klientów z\u00a0depozytem powyżej 100\u00a0zł, którzy zarejestrowali się w\u00a0październiku z\u00a0Twoim kodem referencyjnym:',
	},
};

const register = {
	title: 'Chcę zacząć inwestować',
	text: 'Wystarczy kilka prostych kroków, aby inwestować z\u00a0Fondee',
	button: 'Zarejestruj się',
	info: 'Wypełnij swoje dane osobowe. W\u00a0przypadku konta dziecka, dane dziecka wypełnisz w\u00a0kolejnym kroku rejestracji.',
	social: {
		or: 'albo',
		title: 'Zarejestruj się przez:',
	},
	emailAlias: {
		title: 'Zaloguj się, aby połączyć swoje konto',
		text: 'Jeśli masz już konto, możesz je połączyć tutaj.',
		button: 'Połącz konto',
	},
	privateModal: {
		title: 'Uwaga, logujesz się za pomocą anonimowego adresu e-mail od Apple',
		text: 'Czy na pewno chcesz utworzyć nowe konto z\u00a0anonimowym adresem e-mail od Apple?',
		button: 'Połącz konto',
		close: 'Nie mam konta, chcę je <0>założyć</0>.',
	},
};

const resetPassword = {
	title: 'Zmiana hasła',
	form: {
		button: 'Zmień hasło',
	},
};

const scrollToTop = {
	title: 'Powrót do góry',
};

const signAgreement = {
	title: 'Podpisanie nowej umowy i\u00a0uzupełnienie informacji',
	text: 'Ponieważ w\u00a0J&T\u00a0banku będziemy prowadzić obrót z\u00a0naszą nową licencją\u00a0domu maklerskiego, chcielibyśmy poprosić '
		+ 'o\u00a0wypełnienie zmienionego kwestionariusza i\u00a0podpisanie nowej umowy. Wiemy, że jest to uciążliwe, ale prawo nam niestety '
		+ 'nie daje możliwości\u00a0wyboru.',
	info: 'Jeżeli masz jakiekolwiek pytania, napisz do Karol i\u00a0Davida na\u00a0<0>$t(email.address)</0>',
	done: 'Dziękujemy za wypełnienie kwestionariusza i\u00a0podpisanie nowej umowy.\nNic więcej od Ciebie w\u00a0tej chwili '
		+ 'nie będziemy potrzebowali. Cieszymy się, że jesteś z\u00a0nami! Twoje\u00a0Fondee',
};

const signNewAgreement = {
	title: 'Podpis nowej umowy',
	text: {
		standard: 'Jeśli jesteś zainteresowana/y naszymi standardowymi produktami, podpisz nową umowę. Umowa '
			+ 'i\u00a0jej załączniki regulują podstawowe kwestie, takie jak otwarcie konta, proces inwestowania '
			+ 'i\u00a0opłaty za usługi.',
		pension: 'Jeśli jesteś zainteresowana/y naszymi standardowymi produktami, podpisz nową umowę. Umowa '
			+ 'i\u00a0jej załączniki regulują podstawowe kwestie, takie jak otwarcie konta, proces inwestowania '
			+ 'i\u00a0opłaty za usługi.',
	},
	info: 'Jeśli masz jakiekolwiek pytania, prosimy o kontakt pod adresem <0>$t(email.address)</0>.',
};

const survey = {
	close: 'Zamknij',
	other: 'Coś innego:',
	otherPlaceholder: 'Twoja odpowiedź…',
	validateError: 'Prosimy wypełnić przynajmniej jedną opcję.',
	submit: 'Wyślij',
	thanks: 'Dziękujemy!',
};

const taxes = {
	intro: '<0>Kwestie związane z\u00a0opodatkowaniem inwestycji mogą być trudne do zrozumienia, ale nie martw się! '
		+ '<0>Przygotowaliśmy dla Ciebie sekcję podatkową</0>, która pomoże Ci się z\u00a0tym uporać.</0>'
		+ '<0><0>Znajdziesz tu dane dotyczące Twoich transakcji z\u00a0poprzedniego roku</0>, które będą pomocne przy '
		+ 'składaniu zeznania podatkowego, jeśli masz taki obowiązek.</0>',
	link: 'Zestawienie podatkowe Fondee za rok 2023',
	title: '',
	text: '<0>Teraz zastanówmy się, czy musisz płacić podatek od swoich inwestycji w\u00a0Fondee?</0>'
		+ '<0>Dochód z\u00a0inwestycji w\u00a0Fondee podlega opodatkowaniu podatkiem dochodowym od osób fizycznych. '
		+ 'Nie zawsze jednak musisz płacić podatek.</0>'
		+ '<0>Twój dochód może być zwolniony z\u00a0podatku lub może nie osiągnąć limitu, który wymaga złożenia '
		+ 'zeznania podatkowego.</0>'
		+ '<0>Aby dowiedzieć się, czy dotyczy to również Ciebie, sprawdź na <3>Portalu Podatkowym Ministerstwa '
		+ 'Finansów.</3></0>',
	infoLink: 'https://www.podatki.gov.pl/pit/',
	modal: {
		title: '',
		text: '',
		list: '',
		listText: '',
		subText: '',
	},
};

const topUp = {
	currency: 'W\u00a0jakiej walucie chcesz przesłać wpłatę?',
	portfolio: 'Do\u00a0którego portfela chcesz wpłacić pieniądze?',
	modal: {
		title: 'Chcę wpłacić pieniądze',
		close: 'Zamknij',
	},
	qr: 'Zeskanuj QR code w\u00a0swojej aplikacji bankowej a dane do przelewu uzupełnią się same',
	payment: {
		title: 'Wyślij płatność na ten rachunek',
		account: {
			title: 'Numer rachunku',
			info: 'Rachunek w\u00a0BNP Paribas',
			iban: 'IBAN',
			swift: 'BIC',
		},
		note: 'Wpisz wiadomość dla odbiorcy',
		info: {
			owner: 'Nazwisko właściciela rachunku: Fondee\u00a0a.\u00a0s.',
			address: 'Adres: Rumunská\u00a022/28, 120\u00a000 Praha\u00a02, Republika Czeska',
		},
		warning: {
			account_one: 'Dokonaj płatności ze\u00a0swojego rachunku',
			account_few: 'Płatność musi być dokonana z\u00a0jednego z\u00a0tych rachunków',
			account_many: 'Płatność musi być dokonana z\u00a0jednego z\u00a0tych rachunków',
			transfer: '<0>Przelew w\u00a0EUR</0> powinien być wprowadzony jako płatność SEPA.',
			banks: 'Konto musi być na <0>Twoje nazwisko</0>, dlatego nie możemy przetwarzać płatności z\u00a0niektórych serwisów '
				+ 'transferowych, takich jak Wise, Revolut czy Monese, które wykorzystują konta zbiorcze do wysyłania płatności. '
				+ 'Jeśli chcesz korzystać z\u00a0tych usług, inwestując w\u00a0Fondee, zalecamy kontakt z\u00a0info@fondee.pl.',
			note: 'Jeżeli nie wpiszesz adnotacji dla odbiorcy, płatność zostanie do\u00a02\u00a0dni automatycznie alokowana w\u00a0portfelu '
				+ 'podstawowym. Ustawienia można zmienić w\u00a0części Moje konto.',
		},
	},
	triggerButton: 'Chcę wpłacić pieniądze',
	short: 'Wpłata',
};

const tradingStates = {
	title: 'Obrót',
	states: {
		initial: 'Czekamy na zainwestowanie wstępnej wpłaty',
		deposit: 'Wpłata {{ sum }} jest przygotowana do zainwestowania {{ scheduled }}.',
		withdrawalPart: 'Wypłata {{ sum }} jest przygotowana do realizacji {{ scheduled }}. Najpierw sprzedamy ETF, jeżeli będzie to '
			+ 'konieczne do\u00a0wypłaty, następnie wypłatę przeliczymy na wymaganą walutę, i\u00a0potem prześlemy na Twój rachunek.\n'
			+ 'Wypłaconą kwotę otrzymasz na swój rachunek bankowy w\u00a0ciągu tygodnia.\n'
			+ 'Wypłatę możesz anulować <0>niżej na tej stronie</0>.',
		withdrawalAll: 'Zlecenie\u00a0wypłaty wszystkich środków jest przygotowane do\u00a0realizacji {{ scheduled }}. Najpierw '
			+ 'sprzedamy ETF, następnie wypłatę przeliczymy na wymaganą walutę, i\u00a0potem prześlemy na Twój rachunek.\n'
			+ 'Wypłaconą kwotę otrzymasz na swój rachunek bankowy w\u00a0ciągu tygodnia.\n'
			+ 'Wypłatę możesz anulować <0>niżej na tej stronie</0>.',
		withdrawalCancel: 'Zlecenie\u00a0wypłaty wszystkich środków jest przygotowane do\u00a0realizacji {{ scheduled }}. Najpierw '
			+ 'sprzedamy ETF, następnie wypłatę przeliczymy na wymaganą walutę, i\u00a0potem prześlemy na Twój rachunek.\n'
			+ 'Wypłaconą kwotę otrzymasz na swój rachunek bankowy w\u00a0ciągu tygodnia. '
			+ 'Następnie zamkniemy Twój rachunek inwestycyjny.\n'
			+ 'Wypłatę możesz anulować <0>niżej na tej stronie</0>.',
		withdrawalTransferPart: 'Wprowadzono zlecenie\u00a0przeniesienia części portfela do\u00a0innego Twojego portfela. '
			+ 'Zlecenie możesz anulować <0>niżej na\u00a0tej stronie</0>.',
		withdrawalTransferAll: 'Wprowadzono zlecenie\u00a0sprzedaży ETF i\u00a0przeniesienia gotówki do\u00a0innego Twojego portfela. '
			+ 'Zlecenie możesz anulować <0>niżej na\u00a0tej stronie</0>.',
		withdrawalPartClosed: 'Trwa realizacja wypłaty {{ sum }}. Najpierw sprzedamy ETF, jeżeli będzie to konieczne '
			+ 'do\u00a0wypłaty, następnie wypłatę przeliczymy na\u00a0wymaganą walutę,\u00a0potem prześlemy na Twój rachunek.\n'
			+ 'Wypłaconą kwotę otrzymasz na swój rachunek bankowy w\u00a0ciągu tygodnia.',
		withdrawalAllClosed: 'Trwa wypłata wszystkich środków. Najpierw sprzedamy ETF, następnie wypłatę przeliczymy '
			+ 'na wymaganą walutę, i\u00a0potem prześlemy na Twój rachunek. Wypłaconą kwotę otrzymasz na swój rachunek '
			+ 'bankowy w\u00a0ciągu tygodnia.',
		withdrawalCancelClosed: 'Trwa wypłata wszystkich środków. Najpierw sprzedamy ETF, następnie wypłatę przeliczymy '
			+ 'na wymaganą walutę, i\u00a0potem prześlemy na Twój rachunek.\n'
			+ 'Wypłaconą kwotę otrzymasz na swój rachunek bankowy w\u00a0ciągu tygodnia. Następnie '
			+ 'zamkniemy Twój rachunek inwestycyjny.',
		withdrawalTransferPartClosed: 'Trwa realizacja przeniesienia części portfela do\u00a0innego Twojego portfela. '
			+ 'Więcej informacji można znaleźć <0>poniżej na tej stronie</0>.',
		withdrawalTransferAllClosed: 'Trwa sprzedaż ETF i\u00a0przeniesienie gotówki do\u00a0innego Twojego portfela. '
			+ 'Więcej informacji można znaleźć <0>poniżej na tej stronie</0>.',
		depositAndWithdrawal: 'Wpłaty i\u00a0wypłaty zaplanowane na ten sam dzień będą przetwarzane jednocześnie.',
		profileChange: 'Wprowadzono zmianę profilu inwestycyjnego z\u00a0{{ profile }} na {{ planned }}. '
			+ 'W\u00a0najbliższy dzień roboczy zrównoważymy portfel, aby dopasować go do nowego profilu.',
		rebalancing: 'Następne rebalansowanie (dostosowanie alokacji portfela do wybranego profilu inwestycyjnego) '
			+ 'odbędzie się {{ date }}.',
		rebalancingSkip: 'Masz wyłączone rebalansowanie. Oznacza to, że zainwestujemy otrzymane od Ciebie wpłaty '
			+ 'i\u00a0prześlemy wypłaty, ale nie dokonamy automatycznie rebalansowania Twojego portfela. Rebalancing możesz ponownie '
			+ 'włączyć <0>niżej na tej stronie</0>.',
		cash: 'Wprowadzono zmianę udziału posiadanej gotówki z\u00a0{{ cash }} na {{ planned }}. W\u00a0najbliższy '
			+ 'dzień handlowy dokonamy rebalansowania portfela, aby dopasować go do nowo ustalonego udziału gotówki.',
		cashOver: 'Skorygowałeś/aś udział posiadanej gotówki na {{ cash }}. Udział możesz ponownie zmienić <0>niżej na tej stronie</0>. '
			+ 'Zmiana będzie widoczna po następnym rebalansowaniu.',
		allocatedDeposit: 'Otrzymaliśmy depozyt w\u00a0wysokości {{ sum }}, który czeka na zainwestowanie. Nastąpi to następnego dnia '
			+ 'handlowego, czyli {{ date }}',
	},
};

const transparentAccount = {
	pretitle: 'Rzeczywista wartość portfela Evy i\u00a0Honzy',
	netGrowth: 'Zmiany wartości',
	netGrowthRatio: 'Zmiany wartości w\u00a0%',
	type: 'Typ inwestora',
	initialDeposit: 'Wpłata początkowa',
	monthlyDeposit: 'Wpłata miesięczna',
	note: null,
};

const unsubscribe = {
	default: {
		title: 'Wypisać się z\u00a0e-mailowych wiadomości marketingowych',
		text: 'Czy na pewno chcesz zrezygnować z\u00a0z\u00a0e-mailowych wiadomości marketingowych? Do\u00a0ponownej subskrypcji '
			+ 'możesz zapisać się w\u00a0<0>strefie\u00a0klienta</0>.',
		button: 'Wyloguj',
	},
	success: {
		title: 'Wylogowano',
		text: 'Jeżeli zdecydujesz się ponownie zapisać na e-maile marketingowe, możesz je aktywować '
			+ 'w\u00a0strefie\u00a0klienta w\u00a0części <0>Mój\u00a0rachunek</0>.',
		button: 'Zaloguj się',
	},
};

export {
	account,
	ageCheckBlock,
	allPortfoliosCheck,
	anchors,
	appBanner,
	blog,
	blogPost,
	childAgeCheck,
	clientCheck,
	clientHeader,
	contact,
	contactForm,
	contactUs,
	cookieConsent,
	cookies,
	countryCheck,
	dashboard,
	documents,
	documentUrls,
	email,
	emailUs,
	emailVerification,
	error,
	error404,
	faq,
	feesChart,
	feesComparison,
	feesHero,
	feesProducts,
	findOutInfoBox,
	footer,
	footerCta,
	forgotPassword,
	forms,
	general,
	header,
	historyChart,
	historyFilter,
	historyOverview,
	historyProgress,
	historyTransactions,
	homepageAbout,
	homepageHero,
	homepageJT,
	homepageProducts,
	homepageServices,
	howItWorksHero,
	howItWorksInvestment,
	howItWorksPrinciples,
	howItWorksRebalancing,
	howItWorksSchema,
	investorProfileAvatar,
	investorProfileChoice,
	investorProfileDetail,
	investorProfilePreview,
	languageSwitcher,
	login,
	media,
	meta,
	offers,
	onboarding,
	ourApproachAccount,
	ourApproachHero,
	ourApproachTeam,
	portfolio,
	portfolioComposition,
	portfolioFeesEtfs,
	portfolioFeesOverview,
	portfolioTable,
	privacyPolicy,
	products,
	productAccount,
	productSelect,
	profileAvatar,
	referrals,
	register,
	resetPassword,
	scrollToTop,
	signAgreement,
	signNewAgreement,
	survey,
	taxes,
	topUp,
	tradingStates,
	transparentAccount,
	unsubscribe,
};
